import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    AfterViewInit
} from '@angular/core';
import {
    DevOpsPipeline,
    FinalBlock, HandleHighLight,
    PipeLineConfig,
    ReturnData, TemplateList
} from '@app/shared/directives/common-devops-pipeline/DevOpsPipeline';
import { Toast, ToasterService } from 'angular2-toaster';
import { BsDropdownDirective, ModalDirective } from 'ngx-bootstrap';
import { PipelineService } from '@app/services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '@app/auth';

declare var $: any;

@Component({
    selector: 'app-common-devops-pipeline',
    templateUrl: './common-devops-pipeline.component.html',
    styleUrls: ['./common-devops-pipeline.component.scss']
})
export class CommonDevopsPipelineComponent implements OnInit, AfterViewInit {

    // Scroll content
    @ViewChild('scrollContent', { read: ElementRef }) scrollContent: ElementRef;

    // Event emitters

    /**
     * @Params: newAction, newBlock, afterAction all return data
     * @afterAction => emit data after each action complete
     * */
    @Output('newAction') newAction: EventEmitter<ReturnData> = new EventEmitter<ReturnData>();
    @Output('newBlock') newBlock: EventEmitter<ReturnData> = new EventEmitter<ReturnData>();
    @Output('afterAction') afterAction: EventEmitter<ReturnData> = new EventEmitter<ReturnData>();

    /**
     * @editCondition Condition-edit emit. This will return condition block value.
     * @actionEditEvent actionEditEvent emit. This will return action block value.
     * @observersEdit observersEdit emit. This will return observers block value,
     * @observers observers this will use to add new observers.
     * @triggerEditEvent triggerEditEvent emit. This will return trigger block value.
     * */
    @Output('editCondition') editCondition: EventEmitter<DevOpsPipeline> = new EventEmitter<DevOpsPipeline>();
    @Output('actionEditEvent') actionEditEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output('observers') observers: EventEmitter<any> = new EventEmitter<any>();
    @Output('observersEdit') observersEdit: EventEmitter<any> = new EventEmitter<any>();
    @Output('triggerEditEvent') triggerEditEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output('taskLogEvent') taskLogEvent: EventEmitter<any> = new EventEmitter<any>();

    /**
     * This method used to delete
     * */
    @Output('removeBasedOnIndexEvent') removeBasedOnIndexEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output('removeWholeBlockEvent') removeWholeBlockEvent: EventEmitter<any> = new EventEmitter<any>();

    // Store the values
    finalBlock: FinalBlock[] = [];
    tempArray: DevOpsPipeline[] = [];

    // Configuration
    pipeLineConfig: PipeLineConfig = {
        showNewAction: true,
        showNewBlock: true,
        conditionEdit: true,
        observersAddEdit: true,
        actionAddEdit: true,
        showStatus: false,
    };

    // Scroll width, left for disable button
    scrollWidth = 0;
    scrollLeftValue = 0;

    // whereToStore variable used to store new condition in which block and which position
    // disableAnother is used to disable another before and after buttons
    whereToStore: object = {};
    disableAnother = false;

    // This variable used to store observers to which block
    observersObject: object = {};

    // This variable used to store edited action to which block and which position
    whereToStoreAction = {};

    // This variable used to store trigger to which block and which position.
    whereToStoreTrigger = {};

    // result
    returnData: ReturnData;

    // Handle already edit mode
    alreadyEditMode: boolean;

    // Toaster
    toasterDetails: Toast = null;


    /**
     * This variable used to handle active or correctly clicked action to highlight.
     * New action - To high-light new action button.
     * New block - To high-light new block button.
     * Two type of arrays:-
     * 1. Final array - Need main and child array index number
     * 2. Temp array - Need only child array index number
     */
    handleHighLight: HandleHighLight = {
        final: false,
        temp: false,
        main: -1,
        child: -1,
        newAction: false,
        newBlock: false,
        observersAdd: false
    };

    // Template's for more option
    template_list: TemplateList = null;

    // Connector and condition image location
    img_location = 'assets/img/pipeline/';

    // Option disable handle variables
    optionsDisable: string[] = [];
    finalOptions: string[] = [];

    // Ask confirmation
    askConfirmation = { data: null, flag: null, whichFun: null };
    @ViewChild('askConfirmationModal') askConfirmationModal: ModalDirective;

    // Blocked section details
    blocked_data = {
        trigger: null,
        condition: null,
        action: null,
        block_name: null,
        additionalData: null,
        users: []
    };
    showBlockedData = false;
    selectedUsers = [];
    app_id: any;
    pipeline_run_id: any;
    isUseApproving = false;
    approveItem: any;
    conditionItem: any;
    actionItem: any;
    plusSign: boolean = false;
    rightplusSign: boolean = false;
    editItem = [];
    actionIndex = 0;
    editvalue: any;
    actionname: any;
    actiondata: any;
    editboolean: boolean = false;
    editindex: any;
    conditionindex = 0;
    plusindex = [];
    rightplusindex = [];
    tempboolean: boolean = false;
    finalindex = 0;
    plusDisable: string[] = [];
    rightfinalindex = 0;
    approvalplus: boolean = true;
    approvalcondition: boolean = true;
    approvecondition: boolean = true;
    deleteitem: any;
    blockindex: any;
    showboolean: boolean = false;
    booleanItem: any;
    // blockItem =[{value:'[]'}]
    blockItem = [];
    block = [];
    lastplusSign: boolean = false;
    lastplusboolean: boolean = false;
    approvalmain: boolean = false;
    editedCondition: any;
    editConditionBoolean: boolean = false;
    conditionapprovalboolean: boolean = true;
    scrollBarVal: any;
    notifyBoolean: boolean = false;
    taskBoolean: boolean = true;
    unfinishedBlock: any;
    currentUser: any;
    configureIndex : number = 0;
    PipelineData: DevOpsPipeline;

    constructor(
        private toasterService: ToasterService,
        private pipelineService: PipelineService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService
    ) {
        this.activeRoute.params.subscribe((params: Params) => {
            this.app_id = params.id;
            this.pipeline_run_id = params.runId;
        });
    }

    ngOnInit() {
        this.scrollBarVal = 0;
        this.disableButton();
        this.currentUser = this.authService.currentUser
    }

    ngAfterViewInit(): void {
        this.scrollLR('l');
    }


    /**
     * @Config: This contains some data.
     * Based on this config newAction,newBlock will show.
     * */
    @Input('config')
    set config(data: PipeLineConfig) {
        if (data) {
            Object.keys(data).forEach(key => {
                if (this.pipeLineConfig.hasOwnProperty(key)) {
                    this.pipeLineConfig[key] = data[key];
                }
            });
        }
    }

    /**
     * ScrollRight side.
     * */
    public scrollLR(side): void {
        if (side == 'l') {
            if (this.scrollBarVal >= 50) {
                this.scrollBarVal = parseInt(this.scrollBarVal) - 100;
            }
        }

        var stop: any = 1;

        var observer = new IntersectionObserver(onIntersection, {
            root: null,   // default is the viewport
            threshold: 1 // percentage of taregt's visible area. Triggers "onIntersection"
        })

        function onIntersection(entries) {
            entries.forEach(entry => stop = (entry.intersectionRect.x))

            var scrollRight = document.getElementById("scrollRight")
            if (scrollRight != null) {
                if (stop >= 1200 || stop == 0) {
                    scrollRight.style.cursor = "pointer";
                    scrollRight.style.pointerEvents = "auto";
                    scrollRight.style.opacity = "1";
                }
                else if (stop >= 1) {
                    scrollRight.style.cursor = "not-allowed";
                    scrollRight.style.pointerEvents = "none";
                    scrollRight.style.opacity = "0.2";
                }
            }

        }
        this.pipeLineConfig.showStatus ? observer.observe(document.querySelector('.new-actions-box1')) : observer.observe(document.querySelector('.new-actions-box'));

        if (side == 'r') {
            this.scrollBarVal = parseInt(this.scrollBarVal) + 100;
        }

        // console.log("this",this.scrollBarVal)
        // this.scrollContent.nativeElement.scrollTo({
        //     left: (this.scrollContent.nativeElement.scrollLeft + 150),
        //     behavior: 'smooth'
        // });
        // setTimeout(() => {
        //     this.disableButton();
        // }, 100);
    }

    toggleRightScrollButton() {
        this.lastplusSign = true
        //  this will enable the right scroll button if it reached above 9 blocks,
        //  so user can click the right scroll and configure
        this.scrollLR('no')
    }

    /**
     * ScrollLeft side
     * */
    // public scrollLeft(): void {

    // this.scrollContent.nativeElement.scrollTo({
    //     left: (this.scrollContent.nativeElement.scrollLeft - 150),
    //     behavior: 'smooth'
    // });
    // setTimeout(() => {
    //     this.disableButton();
    // }, 100);
    // }



    /**
     * This used for reconfiguration
     * */
    // re_config(event) {
    //     if (event) {
    //         Object.keys(event).forEach(key => {
    //             if (this.pipeLineConfig.hasOwnProperty(key)) {
    //                 this.pipeLineConfig[key] = event[key];
    //             }
    //         });
    //     }
    // }

    @Input()
    set templateData(data: TemplateList) {
        if (data) {
            this.template_list = data;
        }
    }

    // Common section

    /**
     * Disable left and right side buttons based on scroll width.
     * This is also disable buttons when reach end.
     * */
    disableButton() {
        this.scrollLeftValue = this.scrollContent.nativeElement.scrollLeft;
        this.scrollWidth = this.scrollContent.nativeElement.scrollWidth - this.scrollContent.nativeElement.clientWidth;
    }

    /**
     * Save data. This is common for all.
     * */
    saveData(data: DevOpsPipeline, disableEmit = false): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                if (data) {
                    this.addToTempOrFinal(data).then(res => {
                        if (!disableEmit) {
                            this.afterActionEmit();
                        }
                        resolve(res);
                    }).catch(error => {
                        reject(error);
                    });
                } else {
                    this.toasterService.pop(<Toast>{
                        type: 'info',
                        title: 'Wrong format'
                    });
                    reject(false);
                }
            } catch (e) {
                reject(false);
            }
        });
    }

    /**
     * Adding data to (temp block) or (final block) based on some conditions.
     * */
    addToTempOrFinal(data: DevOpsPipeline): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                this.booleanItem = data;
                if(this.blockindex == undefined){
                    this.blockindex = this.booleanItem.blockIndex;
                }
                if (this.tempArray.length != 0) {
                    let k = 0;
                    k = this.tempArray.findIndex(x => x['type'] == 3)
                    if (k != -1) {
                        this.tempArray.splice(k, 0, this.booleanItem)
                        this.editboolean = true;
                        this.tempboolean = false;
                    }
                    else {
                        this.tempArray.push(this.booleanItem)
                    }
                }
                else {
                    this.tempArray.push(this.booleanItem)
                }
                if (this.plusSign) {
                    if (this.booleanItem.type === 3) {
                        this.taskBoolean = true;
                        const temp: FinalBlock = { name: this.booleanItem.name, action_name: this.booleanItem['data']['environment']["data"]["inputs"][0]["value"], value: this.tempArray };
                        this.finalBlock.splice(this.finalindex, 0, temp)
                        if (this.tempArray.length != 1) {
                            this.finalBlock.splice(this.finalindex + 1, 1)
                        }
                        this.plusindex.splice(this.finalindex, 1)
                        this.tempArray = [];
                        this.plusSign = false;
                        this.plusindex = [];
                        this.approvalplus = true;
                        this.approvecondition = true;
                    }
                    else {
                        const temp: FinalBlock = { name: "", action_name: "", value: this.tempArray };
                        this.finalBlock.splice(this.finalindex, 0, temp)
                        this.plusindex.splice(this.finalindex, 1)
                        if (this.tempArray.length > 1) {
                            this.finalBlock.splice(this.finalindex + 1, 1)
                            if (this.booleanItem.type == 1) {
                            }
                            else {
                                this.approvalplus = true;
                                this.approvecondition = true;
                            }
                        }
                        else {
                            if (this.booleanItem.type == 1) {
                                this.approvalplus = true;
                                this.approvecondition = false;
                            }
                            else {
                                this.approvalplus = true;
                                this.approvecondition = true;
                            }
                        }
                    }
                    this.tempboolean = true;
                    this.plusDisable = this.optionsDisable;
                }
                if (this.rightplusSign == true) {
                    if (this.booleanItem.type === 3) {
                        this.taskBoolean = true;
                        const temp: FinalBlock = { name: this.booleanItem.name, action_name: this.booleanItem['data']['environment']["data"]["inputs"][0]["value"], value: this.tempArray };
                        this.finalBlock.splice(this.rightfinalindex + 1, 0, temp)
                        if (this.tempArray.length != 1) {
                            this.finalBlock.splice(this.rightfinalindex + 2, 1)
                        }
                        this.tempArray = [];
                        this.rightplusindex.splice(this.rightfinalindex, 1)
                        this.rightplusSign = false;
                        this.rightplusindex = [];
                        this.approvalplus = true;
                        this.approvalcondition = true;
                    }
                    else {
                        const temp: FinalBlock = { name: "", action_name: "", value: this.tempArray };
                        this.finalBlock.splice(this.rightfinalindex + 1, 0, temp);
                        this.rightplusindex.splice(this.rightfinalindex, 1);
                        if (this.tempArray.length > 1) {
                            this.finalBlock.splice(this.rightfinalindex + 1, 1)
                            if (this.booleanItem.type == 1) {
                            }
                            else {
                                this.approvalplus = true;
                                this.approvalcondition = true;
                            }
                        }
                        else {
                            if (this.booleanItem.type == 1) {
                                this.approvalplus = true;
                                this.approvalcondition = false;
                            }
                            else {
                                this.approvalplus = true;
                                this.approvalcondition = true;
                            }
                        }
                    }
                    this.tempboolean = true;
                    this.plusDisable = this.optionsDisable;
                }
                if (this.lastplusSign == true) {
                    if (this.booleanItem.type === 3) {
                        this.taskBoolean = true;
                        const temp: FinalBlock = { name: this.booleanItem.name, action_name: this.booleanItem['data']['environment']["data"]["inputs"][0]["value"], value: this.tempArray };

                        if (this.tempArray.length > 1) {
                            this.finalBlock.splice(this.finalBlock.length - 1, 0, temp)
                            this.finalBlock.splice(this.finalBlock.length - 1, 1)
                        }
                        else {
                            this.finalBlock.splice(this.finalBlock.length, 0, temp)
                        }
                        this.tempArray = [];
                        this.lastplusSign = false;
                        this.approvalplus = true;
                        this.approvalcondition = true;
                        this.lastplusboolean = false;
                    }
                    else {
                        const temp: FinalBlock = { name: "", action_name: "", value: this.tempArray };
                        this.finalBlock.splice(this.finalBlock.length, 0, temp)
                        if (this.tempArray.length > 1) {
                            this.finalBlock.splice(this.finalBlock.length - 1, 1)
                            if (this.booleanItem.type == 1) {
                                // this.lastplusboolean = true;
                            }
                            else {
                                this.approvalplus = true;
                                this.approvalcondition = true;
                                this.lastplusboolean = false;
                            }
                        }
                        else {
                            if (this.booleanItem.type == 1) {
                                this.approvalplus = true;
                                this.lastplusboolean = true;
                            }
                            else {
                                this.approvalplus = true;
                                this.approvalcondition = true;
                                this.lastplusboolean = false;
                            }
                        }
                    }
                    this.tempboolean = true;
                    this.plusDisable = this.optionsDisable;
                }
                // if(data)
                let k = this.tempArray.findIndex(x => x.type == 3)
                this.optionsDisable.push(data.type.toString());
                this.finalOptions = this.optionsDisable;
                if (k != -1 && this.editboolean && !this.tempboolean) {
                    const temp: FinalBlock = { name: this.actiondata, action_name: this.actionname, value: this.tempArray };
                    this.finalBlock.splice(this.editindex, 1, temp)
                    this.tempArray = [];
                    this.optionsDisable = [];
                    this.editboolean = false;
                    this.approvalplus = true;
                    this.approvalcondition = true;
                }
                if (data.type === 3 && !this.tempboolean) {
                    this.taskBoolean = true;
                    const temp: FinalBlock = { name: data.name, action_name: data['data']['environment']["data"]["inputs"][0]["value"], value: this.tempArray };
                    this.finalBlock.push(temp);
                    this.tempArray = [];
                    this.optionsDisable = [];
                    this.approvalplus = true;
                    this.approvalcondition = true;
                }
                setTimeout(() => {
                    this.disableButton();
                }, 100);
                this.returnData = {
                    finalBlock: this.finalBlock,
                    tempArray: this.tempArray,
                    blockIndex: this.finalBlock.length,
                    flag: null
                };
                resolve(this.returnData);
                this.showboolean = false;
            } catch (e) {
                reject(false);
            }
        });
    }

    /**
     * Return final block data when ask data. This will never return if not completed temp-block.
     * @setEmpty: This will used to reset final block data.
     * */
    returnAndReset(setEmpty): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.tempArray = [];
            if (this.tempArray.length === 0) {
                const data = this.finalBlock;
                if (setEmpty) {
                    this.scrollBarVal = 0;
                    this.finalBlock = [];
                }
                resolve(data);
            } else {
                const message = {
                    'temp_length': this.tempArray.length,
                    'msg': `Can't reset the value. Because the last block have without action.`
                };
                reject(message);
            }
        });
    }

    /**
     * Set final block value. This method use edit time.
     * */
    setFinalBlockValue(data: FinalBlock[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                this.finalBlock = data;
                setTimeout(() => {
                    this.disableButton();
                }, 500);
                this.afterActionEmit();
                resolve(true);
            } catch (e) {
                console.error(e);
                reject(false);
            }
        });
    }

    //Update status
    updateRunStatus(data: FinalBlock[]) {
        this.finalBlock = data;
        return true;
    }

    newActionClick(flag) {
        this.returnData = {
            finalBlock: this.finalBlock,
            tempArray: this.tempArray,
            blockIndex: this.finalBlock.length,
            flag: flag
        };
        this.newAction.emit(this.returnData);
        this.handleHighLight.newAction = true;
    }

    newBlockClick(flag) {
        this.configureIndex = this.blockindex
        this.returnData = {
            finalBlock: this.finalBlock,
            tempArray: this.tempArray,
            blockIndex: this.finalBlock.length,
            flag: flag
        };
        this.newBlock.emit(this.returnData);
        this.handleHighLight.newBlock = true;
    }

    afterActionEmit() {
        if (this.blockindex != undefined && this.editConditionBoolean) {
            let arr = [];
            arr = this.finalBlock[this.blockindex].value;
            this.tempArray = arr;
        }
        this.configureIndex = this.blockindex;
        if (this.blockindex != undefined && this.editConditionBoolean) {
            if (this.finalBlock[this.blockindex]["value"]) {
                for (let i = 0; i < this.tempArray.length; i++) {
                    if (this.tempArray[i].type == 2) {
                        if (this.editedCondition.data.conditions[0].type == null) {
                            if (this.finalBlock[this.blockindex]["value"].length == 1) {
                                this.finalBlock.splice(this.blockindex, 1)
                                this.tempArray = [];
                            }
                            else {
                                this.finalBlock[this.blockindex]["value"].splice(i, 1);
                                this.finalindex = this.blockindex;
                                if (this.tempArray.length == 1 && this.tempArray[0].type == 1) {
                                    if (this.blockindex == 0) {
                                        this.conditionapprovalboolean = false;
                                    }
                                    else {
                                        this.conditionapprovalboolean = true;
                                        this.approvalcondition = false;
                                    }
                                }
                                else {
                                    this.approvalcondition = true;
                                    this.conditionapprovalboolean = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (this.tempArray.length != 0) {
            for (let i = 0; i < this.tempArray.length; i++) {
                if (this.tempArray[i].type == 3) {
                    this.tempArray = [];
                    this.conditionapprovalboolean = true;
                }
            }
        }
        this.returnData = {
            finalBlock: this.finalBlock,
            tempArray: this.tempArray,
            blockIndex: this.finalBlock.length,
            flag: null
        };
        this.afterAction.emit(this.returnData);
        this.handleHighLightReset();
        this.editItem = [];
        for (let i = 0; i < this.finalBlock.length; i++) {
            if (this.finalBlock[i].value.length != 0) {
                /* if (this.finalBlock[i].value[0].type == 1) {
                    this.editItem.push(this.finalBlock[i].value[0]);
                }
                else {
                    this.editItem.push(this.finalBlock[i].value[0]);
                } */
                this.editItem.push(this.finalBlock[i].value[0]);
            }
        }
        for (let i = 0; i < this.finalBlock.length; i++) {
            var blockvalue;
            blockvalue = this.finalBlock[i].value;
            for (let j = 0; j < blockvalue.length; j++) {
                if (blockvalue[j].type != 1) {
                    this.block.push(blockvalue[j])
                }
            }
            this.blockItem[i] = { value: this.block };
            this.block = [];
        }
        // this.tempArray = [];
    }

    handleHighLightReset() {
        this.handleHighLight = {
            final: false,
            temp: false,
            main: -1,
            child: -1,
            newAction: false,
            newBlock: false,
            observersAdd: false
        };
    }

    /**
     * This method use to handle toaster message. This is common for all.
     * @type: This variable contain type of toaster e.g info.
     * @message: This is string variable.
     * */
    toasterHandle(type: 'info' | 'warning' | 'success' | 'error', message: string) {
        if (this.toasterDetails) {
            this.toasterService.clear(this.toasterDetails.toastId);
        }
        this.toasterDetails = this.toasterService.pop(<Toast>{
            type: type,
            title: message,
        });
    }

    resetData() {
        this.alreadyEditMode = false;
        this.handleHighLightReset();
    }

    removeWholeBlock(data, flag): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                if (flag === 'temp') {
                    this.tempArray = [];
                } else {
                    this.finalBlock.splice(data.BI, 1);
                }
                resolve('Deleted successful');
            } catch (e) {
                reject(e);
            }
        });
    }

    disableEdit() {
        this.alreadyEditMode = true;
    }

    // Trigger section
    approvalEdit(type: string, name: string, where: string, blockIndex: number) {
        const FB = this.finalBlock;
        const position = type === 'final' ? FB[blockIndex].value.findIndex(x => x.type === 1) : this.tempArray.findIndex(x => x.type === 1);
        const data = type === 'final' ? this.finalBlock[blockIndex].value[position] : this.tempArray[position];
        if (!this.alreadyEditMode) {
            this.handleHighLightReset();
            this.whereToStoreTrigger['type'] = type;
            this.whereToStoreTrigger['name'] = name;
            this.whereToStoreTrigger['where'] = where;
            this.whereToStoreTrigger['position'] = position;
            this.whereToStoreTrigger['blockIndex'] = blockIndex;
            const returnValue = {
                data,
                first: (type === 'final' && blockIndex === 0) || (type === 'temp' && this.finalBlock.length === 0)
            };
            this.triggerEditEvent.emit(returnValue);
            this.handleHighLight.temp = type === 'temp';
            this.handleHighLight.final = type === 'final';
            this.handleHighLight.main = blockIndex;
            this.handleHighLight.child = position;
        } else {
            this.toasterHandle('warning', 'Save or discard this details before continue.');
        }
    }

    saveEditedTrigger(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                if (this.whereToStoreTrigger['type'] === 'temp') {
                    this.tempArray[this.whereToStoreTrigger['position']] = data;
                } else if (this.whereToStoreTrigger['type'] === 'final') {
                    this.finalBlock[this.whereToStoreTrigger['blockIndex']].value[this.whereToStoreTrigger['position']] = data;
                }
                this.alreadyEditMode = false;
                this.afterActionEmit();
                resolve(true);
            } catch (e) {
                reject(false);
            }
        });
    }

    removeApproval(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const data = {
                index: this.whereToStoreTrigger['position'],
                BI: this.whereToStoreTrigger['blockIndex']
            };
            this.alreadyEditMode = false;
            this.handleHighLightReset();
            this.removeBasedOnIndex(data, this.whereToStoreTrigger['type']).then(Res => resolve(Res)).catch(Err => reject(Err));
            let k = this.finalBlock.length
            if (this.tempArray.length == 0 && this.finalBlock[k - 1].name == "") {
                this.approvalmain = true;
                this.finalBlock.splice(k - 1, 1);
                this.tempArray = [];
            }
            this.returnData = {
                finalBlock: this.finalBlock,
                tempArray: this.tempArray,
                blockIndex: this.finalBlock.length,
                flag: null
            };
            this.afterActionEmit();
            resolve(this.returnData);
            this.tempArray = [];
        });
    }

    removeBasedOnIndex(data, flag): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                if (flag === 'temp') {
                    this.tempArray.splice(data.index);
                } else {
                    this.finalBlock[data.BI].value.splice(data.index, 1);
                }
                resolve('Deleted successful');
            } catch (e) {
                reject(e);
            }
        });
    }

    // Condition section
    conditionEdit(type: string, name: string, where: string, position: number, data: DevOpsPipeline, blockIndex: number) {
        if (this.pipeLineConfig.conditionEdit) {
            if (!this.alreadyEditMode) {
                this.handleHighLightReset();
                this.whereToStore['type'] = type;
                this.whereToStore['name'] = name;
                this.whereToStore['where'] = where;
                this.whereToStore['position'] = position;
                this.whereToStore['blockIndex'] = blockIndex;
                this.editCondition.emit(data);
                this.disableAnother = true;
                this.handleHighLight.temp = type === 'temp';
                this.handleHighLight.final = type === 'final';
                this.handleHighLight.main = blockIndex;
                this.handleHighLight.child = position;
            } else {
                this.toasterHandle('warning', 'Save or discard this details before continue.');
            }
        }
    }

    saveEditedCondition(data: DevOpsPipeline): Promise<boolean> {
        this.editedCondition = data;
        this.editConditionBoolean = true;
        if (data['data'].conditions[0].type != null) {
            return new Promise<boolean>((resolve, reject) => {
                try {
                    if (this.whereToStore['type'] === 'temp') {
                        this.tempArray[this.whereToStore['position']] = data;
                    } else if (this.whereToStore['type'] === 'final') {
                        const index = this.whereToStore['blockIndex'];
                        this.finalBlock[index].value[this.whereToStore['position']] = data;
                    }
                    this.alreadyEditMode = false;
                    this.afterActionEmit();
                    this.editConditionBoolean = false;
                    resolve(true);
                } catch (e) {
                    reject(false);
                }
            });
        }
        else {
            this.afterActionEmit();
            this.editConditionBoolean = false;
            return new Promise<boolean>((resolve, reject) => {
                resolve(false);
            });
        }
    }

    // Observers section
    observersAdd(type: string, name: string, position: number, data: any, blockIndex: number): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            if (!this.alreadyEditMode) {
                this.handleHighLightReset();
                this.observersObject['type'] = type;
                this.observersObject['name'] = name;
                this.observersObject['position'] = position;
                this.observersObject['data'] = data;
                this.observersObject['blockIndex'] = blockIndex;
                this.handleHighLight.observersAdd = true;
                this.handleHighLight.main = blockIndex;
                this.handleHighLight.child = position;
                this.observers.emit(this.observersObject);
                resolve(true);
            } else {
                this.toasterHandle('warning', 'Save or discard this details before continue.');
                reject(false);
            }
        });
    }

    saveObservers(data, flag: 'slack' | 'email' | 'sms' | 'whatsapp', data_index): Promise<object> {
        let i = data_index;
        if (data == "remove") {
            return new Promise<object>((resolve, reject) => {
                const observersData = { index: this.blockindex };
                resolve(observersData);
            });
        }
        else {
            if (data.data[0] && data.data[0].url != null || data.data[0] && data.data[0].key != null || data.data[0] && data.data[0].user_id) {
                return new Promise<object>((resolve, reject) => {
                    try {
                        let index = 0;
                        if (this.blockindex>=0) {
                            index = this.blockindex;
                        }
                        else {
                            index = this.configureIndex;
                        }
                        if((this.blockindex==undefined) && (this.configureIndex==undefined)) {
                            index = data_index;
                        } 
                        if (data.data['0'].url == "" || data.data['0'].key == "") {
                            let k = this.finalBlock[index].value.findIndex(x => x.type === 4)
                            this.finalBlock[index].value.splice(k, 1);
                        }
                        else {
                            const observersIndex = this.finalBlock[index].value.findIndex(x => x.type === 4);
                            if (observersIndex === -1) {
                                const observers: DevOpsPipeline = {
                                    type: 4,
                                    name: null,
                                    blockName: data.blockName,
                                    data: { observers: { slack: [], email: [], sms: [], whatsapp: [] } }
                                };
                                if (flag === 'slack') {
                                    observers.data.observers.slack = data.data;
                                } else if (flag === 'email') {
                                    observers.data.observers.email = data.data;
                                }
                                else if (flag === 'sms') {
                                    observers.data.observers.sms = data.data;
                                }
                                else if (flag === 'whatsapp') {
                                    observers.data.observers.whatsapp = data.data;
                                }
                                this.finalBlock[index].value.push(observers);                                
                            } else {
                                this.finalBlock[index].value[observersIndex].blockName = data.blockName;
                                if (flag === 'slack') {
                                    this.finalBlock[index].value[observersIndex].data.observers.slack = data.data;
                                } else if (flag === 'email') {
                                    this.finalBlock[index].value[observersIndex].data.observers.email = data.data;
                                } else if (flag === 'sms') {
                                    this.finalBlock[index].value[observersIndex].data.observers.sms = data.data;
                                } else if (flag === 'whatsapp') {
                                    this.finalBlock[index].value[observersIndex].data.observers.whatsapp = data.data;
                                }
                            }
                        }
                        this.alreadyEditMode = false;
                        this.afterActionEmit();
                        const observersData = { value: true }
                        resolve(observersData);
                        // }
                    } catch (e) {
                        console.log(e);
                        reject(false);
                    }
                });
            }
            else {
                return new Promise<object>((resolve, reject) => {
                    // let stringToSplit = data.blockName;
                    // let x = stringToSplit.split(" ");
                    // const index = x[2];
                    const index = this.blockindex;
                    let i = 0;
                    i = this.finalBlock[index].value.length - 1;
                    if (flag === 'slack') {
                        this.finalBlock[index].value[i].data.observers.slack.splice(0, 1);
                        if (this.finalBlock[index].value[i].data.observers.email.length == 0) {
                            this.finalBlock[index].value.splice(i, 1);
                        }
                    }
                    else if (flag === 'email') {
                        this.finalBlock[index].value[i].data.observers.email.splice(0, 1);
                        if (this.finalBlock[index].value[i].data.observers.slack.length == 0) {
                            this.finalBlock[index].value.splice(i, 1);
                        }
                    }
                    else if (flag === 'sms') {
                        this.finalBlock[index].value[i].data.observers.sms.splice(0, 1);
                        //// to modify
                        if (this.finalBlock[index].value[i].data.observers.slack.length == 0) {
                            this.finalBlock[index].value.splice(i, 1);
                        }
                    }
                    else if (flag === 'whatsapp') {
                        this.finalBlock[index].value[i].data.observers.whatsapp.splice(0, 1);
                        if (this.finalBlock[index].value[i].data.observers.slack.length == 0) {
                            this.finalBlock[index].value.splice(i, 1);
                        }
                    }
                    this.afterActionEmit();
                    const observersData = { value: true }
                    resolve(observersData);
                });
            }
        }
    }

    removeObservers(flag: 'email' | 'slack' | 'sms' | 'whatsapp', blockIndex): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                let index = blockIndex;
                if (!index) {
                    index = this.blockindex;
                }
                const observersIndex = this.finalBlock[index].value.findIndex(x => x.type === 4);
                if (observersIndex !== -1) {
                    switch (flag) {
                        case 'email':
                            this.finalBlock[index].value[observersIndex].data.observers.email = [];
                            break;
                        case 'slack':
                            this.finalBlock[index].value[observersIndex].data.observers.slack = [];
                            break;
                        case 'sms':
                            this.finalBlock[index].value[observersIndex].data.observers.sms = [];
                            break;
                        case 'whatsapp':
                            this.finalBlock[index].value[observersIndex].data.observers.whatsapp = [];
                        default:
                            break;
                    }
                    const observers = this.finalBlock[index].value[observersIndex].data.observers;
                    if (observers.email.length === 0 && observers.slack.length === 0 && observers.sms.length === 0 && observers.whatsapp.length === 0) {
                        this.finalBlock[index].value.splice(observersIndex, 1);
                    }
                }
                resolve(true);
            } catch (error) {
                reject(false);
            }
        });

    }

    editObservers($event, item, type: 'final' | 'temp', name, position, blockIndex) {
        if ($event) {
            $event.stopPropagation();
        }
        if (this.pipeLineConfig.observersAddEdit) {
            if (!this.alreadyEditMode) {
                this.handleHighLightReset();
                this.observersObject['type'] = type;
                this.observersObject['name'] = name;
                this.observersObject['position'] = position;
                this.observersObject['blockIndex'] = blockIndex;
                this.observersEdit.emit(item);
                this.handleHighLight.temp = false;
                this.handleHighLight.final = true;
                this.handleHighLight.main = blockIndex;
                this.handleHighLight.child = position;
            } else {
                this.toasterHandle('warning', 'Save or discard this details before continue.');
            }
        }
    }

    // Action section
    actionEdit(item: DevOpsPipeline, block, position, blockIndex) {
        if (!this.alreadyEditMode) {
            this.handleHighLightReset();
            this.whereToStoreAction['block'] = block;
            this.whereToStoreAction['position'] = position;
            this.whereToStoreAction['blockIndex'] = blockIndex;
            this.actionEditEvent.emit({ data: item, index: blockIndex });
            this.handleHighLight.temp = false;
            this.handleHighLight.final = true;
            this.handleHighLight.main = blockIndex;
            this.handleHighLight.child = position;
        } else {
            this.toasterHandle('warning', 'Save or discard this details before continue.');
        }
    }

    saveEditedAction(data): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                const index = this.whereToStoreAction['blockIndex'];
                const saveTO = this.finalBlock[index];
                saveTO.value[this.whereToStoreAction['position']] = data;
                saveTO.name = data.name;
                if (data.type == 3) {
                    this.finalBlock[index]['action_name'] = (data['data']['environment']["data"]["inputs"][0]["value"])
                }
                this.afterActionEmit();
                this.returnData = {
                    finalBlock: this.finalBlock,
                    tempArray: this.tempArray,
                    blockIndex: this.finalBlock.length,
                    flag: null
                };
                this.alreadyEditMode = false;
                resolve(this.returnData);
            } catch (e) {
                reject(false);
            }
        });
    }

    enableObservers(k: number) {
        if (this.finalBlock[k]) {
            return this.finalBlock[k].value.findIndex(x => x.type === 4) > -1;
        }
    }

    observersIndex(k: number) {
        return this.finalBlock[k].value.findIndex(x => x.type === 4);
    }

    removeBg() {
        return this.tempArray.findIndex(x => x.type === 2) > -1 || this.tempArray.findIndex(x => x.type === 3) > -1;
    }

    removeBasedOnIndexTrigger($event, data, flag) {
        $event.stopPropagation();
        this.askConfirmation.data = data;
        this.askConfirmation.flag = flag;
        this.askConfirmation.whichFun = 'index';
        this.askConfirmationModal.show();
        // const length = data.block.value.length;
        // if (length < 2) {
        //     this.removeWholeBlockHere(data);
        // } else {
        //     data['type'] = flag;
        //     this.removeBasedOnIndexEvent.emit(data);
        // }
    }

    removeWholeBlockHere(data) {
        this.askConfirmation.data = data;
        this.askConfirmation.whichFun = 'final';
        this.askConfirmationModal.show();
        // this.removeWholeBlockEvent.emit(data);
    }

    deleteOptionShowTemp(block: FinalBlock = null) {
        if (!block) {
            return this.tempArray.findIndex(x => x.type === 2) > -1;
        } else {
            return block.value.findIndex(x => x.type === 3) > -1;
        }
    }

    showConnector(block: FinalBlock = null) {
        if (block) {
            return block.value.findIndex(x => x.type === 1) > -1;
        } else {
            return this.tempArray.findIndex(x => x.type === 1) > -1;
        }
    }

    deleteConfirmation() {
        if(this.tempArray.length !=0) {
            this.toasterService.pop(<Toast>{
                type: 'error',
                title: 'Delete the block after adding action in the new block',
            }); 
            this.askConfirmationModal.hide();
        } else {
        const data = this.askConfirmation.data;
        const flag = this.askConfirmation.flag;
        switch (this.askConfirmation.whichFun) {
            case 'index':
                const approval = data.block.value.filter(x => x.type === 1).length;
                const length = data.block.value.length - approval;
                if (length < 2) {
                    this.removeWholeBlockEvent.emit(data);
                } else {
                    data['type'] = flag;
                    this.removeBasedOnIndexEvent.emit(data);
                }
                this.askConfirmationModal.hide();
                this.tempArray = [];
                break;
            case 'final':
                this.removeWholeBlockEvent.emit(data);
                this.askConfirmationModal.hide();
                this.tempArray = [];
                break;
            default:
                console.error('Switch case failed');
        }
        this.deleteitem = this.editItem;
        this.plusSign = false;
        this.rightplusSign = false;
        this.plusindex = [];
        this.rightplusindex = [];
        this.editItem = [];
        this.taskBoolean = true;
        for (let i = 0; i < this.finalBlock.length; i++) {
            let k = 0;
            k = this.deleteitem.findIndex(x => x == this.finalBlock[i].value[0]);
            if (k != -1) {
                this.editItem.push(this.finalBlock[i].value[0])
            }
        }
        this.returnData = {
            finalBlock: this.finalBlock,
            tempArray: this.tempArray,
            blockIndex: this.finalBlock.length,
            flag: null
        };
        // if(this.tempArray.length ==0){
        //     this.taskBoolean = true;
        //     this.tempboolean = false;
        // }
        this.afterAction.emit(this.returnData);
        // this.unfinishedBlock = 'no'
    }
    }

    approveUser($event, values, k?: any) {
        this.showBlockedData = false;
        const value: DevOpsPipeline[] = values.value;
        const trigger = value.findIndex(x => x.type === 1);
        const condition = value.findIndex(x => x.type === 2);
        const action = value.findIndex(x => x.type === 3);
        this.blocked_data.trigger = trigger > -1 ? value[trigger].data : null;
        this.blocked_data.condition = condition > -1 ? value[condition].data : null;
        this.blocked_data.action = action > -1 ? value[action].data : null;
        // this.blocked_data.block_name = this.blocked_data.action.environment.data.inputs[0].value;
        this.blocked_data.block_name = values.action_name
        this.blocked_data.additionalData = $event;
        this.blocked_data.users = this.blocked_data.trigger.trigger.users;
        this.showBlockedData = true;
        this.selectedUsers = [];
        $('#approvalModal').modal('show');
    }

    selectUser(user) {
        const index = this.selectedUsers.findIndex(x => x.id === user.id);
        index === -1 ? this.selectedUsers.push(user) : this.selectedUsers.splice(index, 1);
    }

    checkBoxActive(id) {
        return this.selectedUsers.findIndex(x => x.id === id) > -1;
    }

    checkUserSelect() {
        if (this.selectedUsers.length == 0) {
            return false;
        }
        else {
            return true;
        }
    }

    usersFinal(usersDropDown: BsDropdownDirective) {
        this.isUseApproving = true;
        let data = {
            application_id: this.app_id,
            task: this.blocked_data['block_name'],
            pipeline_runs_id: this.pipeline_run_id
        }
        this.pipelineService.createPipelineTrigger(data).subscribe(resp => {
            if (resp == 'Trigger created') {
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "Task Approved"
                });
            }
            else if (resp == 'Trigger waiting') {
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "Waiting for another Approval"
                });
            }
            else {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: "Error Occured"
                });
            }
            this.isUseApproving = false;
            this.closemodal();
        },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error['detail']
                });
                this.isUseApproving = false;
                usersDropDown.hide();
            });
    }

    deleteCancel() {
        this.askConfirmationModal.hide();
    }

    show_log(block) {
        this.taskLogEvent.emit({ data: block.action_name });
    }

    getData(data, item) {
        // if(this.plusSign && this.tempArray.length!=0) {
        //     this.toasterService.pop(<Toast>{
        //         type: 'error',
        //         title: 'Add action before adding a new block',
        //     });
        // } else {
        this.blockindex = item;
        this.editvalue = data.value;
        this.finalOptions = [];
        for (let i = 0; i < data.value.length; i++) {
            this.finalOptions.push(data.value[i].type);
        }
        let arr = [];
        arr = data.value;
        let i = 0;
        i = arr.findIndex(x => x.type == 1);
        if (i != -1) {
            this.approveItem = data.value[i];
        }
        let j = 0;
        j = arr.findIndex(x => x.type == 2);
        this.conditionindex = j;
        if (j != -1) {
            this.conditionItem = data.value[j];
        }
        let k = 0;
        k = arr.findIndex(x => x.type == 3);
        this.actionIndex = k;
        if (k != -1) {
            this.actionItem = data.value[k];
            this.notifyBoolean = false;
        }
        else {
            this.notifyBoolean = true;
        }
    // }
    }

    leftplus(index) {
        for (let i = 0; i < this.blockItem.length; i++) {

            let arr = this.blockItem[i].value;
            if (arr.length != 0) {
                arr.forEach(item => {
                    if (item.type != 3) {
                        this.unfinishedBlock = i
                    }
                })
            }
            else {
                this.unfinishedBlock = i
            }
            setTimeout(() => {
                this.unfinishedBlock = 'no'
            }, 5000);
        }
        this.taskBoolean = false;
        if ((this.tempArray.length != 0) || (this.plusSign) || (this.rightplusSign)) {
            this.plusSign = false
            this.toasterService.pop(<Toast>{
                type: 'error',
                title: 'Add action before adding a new block',
            });
        }
        else {
            this.plusSign = true;
            this.finalindex = index;
            this.plusindex.push(index);
            this.plusDisable = [];
            this.showboolean = true;
            this.approvalplus = false;
        }
    }

    rightplus(index) {
        this.taskBoolean = false;
        if ((this.tempArray.length != 0) || (this.plusSign) || (this.rightplusSign)) {
            this.rightplusSign = false
            this.toasterService.pop(<Toast>{
                type: 'error',
                title: 'Add action before adding a new block',
            });
        }
        else {
            this.rightplusSign = true;
            this.rightfinalindex = index;
            this.rightplusindex.push(this.rightfinalindex);
            this.plusDisable = [];
            this.approvalplus = false;
        }
    }

    testnewBlockClick(flag, index) {
        this.editboolean = true;
        this.editindex = index;
        if (this.actionItem) {
            this.actiondata = this.actionItem.name;
            this.actionname = this.actionItem['data']['environment']["data"]["inputs"][0]["value"]
        }
        this.showboolean = true;
        this.tempArray = this.editvalue;
        for (let i = 0; i < this.editvalue.length; i++) {
            if (!this.optionsDisable.includes(this.editvalue[i].type)) {
                this.optionsDisable.push(String(this.editvalue[i].type));
            }
        }
        this.newBlockClick(flag);
    }

    lastplusClass() {
        for (let i = 0; i < this.blockItem.length; i++) {

            let arr = this.blockItem[i].value;
            if (arr.length != 0) {
                arr.forEach(item => {
                    if (item.type != 3) {
                        this.unfinishedBlock = i
                    }
                })
            }
            else {
                this.unfinishedBlock = i
            }
            setTimeout(() => {
                this.unfinishedBlock = 'no'
            }, 5000);
        }
        if ((this.tempArray.length != 0) || (this.plusSign) || (this.rightplusSign)) {
            this.toasterService.pop(<Toast>{
                type: 'error',
                title: 'Add action before adding a new block',
            });
        }
    }

    isDataEmpty(return_data = false) {
        if (return_data) {
            const state_maintain_data = [
                'finalBlock', 'tempArray', 'scrollWidth', 'scrollLeftValue', 'whereToStore', 'disableAnother', 'observersObject', 'whereToStoreAction',
                'whereToStoreTrigger', 'handleHighLight', 'template_list', 'optionsDisable', 'finalOptions', 'askConfirmation', 'blocked_data',
                'showBlockedData', 'selectedUsers', 'isUseApproving', 'plusSign', 'rightplusSign', 'editItem', 'actionIndex', 'editboolean',
                'conditionindex', 'plusindex', 'rightplusindex', 'tempboolean', 'finalindex', 'plusDisable', 'rightfinalindex', 'approvalplus',
                'approvalcondition', 'approvecondition', 'showboolean', 'blockItem', 'block', 'lastplusSign', 'lastplusboolean', 'approvalmain',
                'editConditionBoolean', 'conditionapprovalboolean', 'notifyBoolean', 'taskBoolean', 'scrollBarVal', 'currentUser', 'returnData',
                'booleanItem', 'alreadyEditMode', 'blockindex', 'editvalue', 'approveItem'];

            const store_data = {};
            state_maintain_data.forEach(x => { store_data[x] = this[x]; });
            return store_data;
        }
        return this.finalBlock.length === 0 && this.tempArray.length === 0;
    }

    setStateMaintainData(event: any) {
        Object.keys(event).forEach(x => {
            this[x] = event[x];
        });
        setTimeout(() => {
            this.disableButton();
        }, 500);
        this.afterActionEmit();
    }

    closemodal() {
        $('#approvalModal').modal('hide');
    }

    plusFunction(k) {
        if(this.deleteitem) {
            if(!this.plusindex.includes(k) && this.deleteitem.length>0) {
                this.plusSign = false;
                this.rightplusSign = false;
                this.showboolean = false;
                this.tempboolean = false;
                this.taskBoolean = true;
                this.deleteitem = [];
            }
        }
        
        
    }
}
