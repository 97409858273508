import {Component, OnInit,EventEmitter, Output,} from '@angular/core';


@Component({
  selector: 'app-helpbar',
  templateUrl: './helpbar.component.html',
  styleUrls: ['./helpbar.component.scss']
})
export class HelpbarComponent implements OnInit {

  pageName:string='Popular Topics';
  data:any;

  @Output('onHelpClose') onHelpClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    
    let temp=JSON.parse(window.localStorage.getItem("helpEnable"))       
    if(temp== null || temp.page==''){
      
        this.pageName='Popular Topics';
    }
    else{
        this.pageName=temp.page;
      }
              
        
    this.data=this.orgData;
  }


   

  orgData = [
  {'header':'Popular Topics','details':
  [{'subtitle':'Pipeline Setup','desp':"Pipeline setup flow end - end"},
  {'subtitle':'Jobs','desp':"How to run jobs"},
  {'subtitle':'Environment','desp':"How to set environment"},
  {'subtitle':'Actions','desp':"Creating action flow on pipeline"},
  {'subtitle':'Condition','desp':"Adding condition flow on pipeline"},
  {'subtitle':'Project','desp':"Project setup"},
  {'subtitle':'Reporting & Monitoring','desp':"Pipeline detail reporting"}
  ]},
  {'header':'Pipeline Setup','details':
  [{'subtitle':' Application Creation','desp':"it's the first step of your application's lifecycle in production"},
  {'subtitle':'Add Projects','desp':"Select Repo/ Non-repo based projects"},
  {'subtitle':'Set Environment','desp':"Add your deployment target"},
  {'subtitle':'Add Credentials','desp':"Only if need to work with (AWS.GCP, etc) Add your sponsored accounts into the credential vault"},
  {'subtitle':'Start Creating your Pipeline','desp':"Click pipeline tab at the top Happy Deployment"} 
  ]},
  {'header':'Jobs','details':
  [{'subtitle':' Application Creation','desp':"it's the first step of your application's lifecycle in production"},
  {'subtitle':'Add Projects','desp':"Select Repo/ Non-repo based projects"},
  {'subtitle':'Set Environment','desp':"Add your deployment target"},
  {'subtitle':'Add Credentials','desp':"Only if need to work with (AWS.GCP, etc) Add your sponsored accounts into the credential vault"},
  {'subtitle':'Start Creating your Pipeline','desp':"Click pipeline tab at the top Happy Deployment"} 
  ]},
  {'header':'Environment','details':
  [{'subtitle':' Application Creation','desp':"it's the first step of your application's lifecycle in production"},
  {'subtitle':'Add Projects','desp':"Select Repo/ Non-repo based projects"},
  {'subtitle':'Set Environment','desp':"Add your deployment target"},
  {'subtitle':'Add Credentials','desp':"Only if need to work with (AWS.GCP, etc) Add your sponsored accounts into the credential vault"},
  {'subtitle':'Start Creating your Pipeline','desp':"Click pipeline tab at the top Happy Deployment"} 
  ]},
  {'header':'Actions','details':
  [{'subtitle':' Application Creation','desp':"it's the first step of your application's lifecycle in production"},
  {'subtitle':'Add Projects','desp':"Select Repo/ Non-repo based projects"},
  {'subtitle':'Set Environment','desp':"Add your deployment target"},
  {'subtitle':'Add Credentials','desp':"Only if need to work with (AWS.GCP, etc) Add your sponsored accounts into the credential vault"},
  {'subtitle':'Start Creating your Pipeline','desp':"Click pipeline tab at the top Happy Deployment"} 
  ]},
  {'header':'Reporting & Monitoring','details':
  [{'subtitle':' Application Creation','desp':"it's the first step of your application's lifecycle in production"},
  {'subtitle':'Add Projects','desp':"Select Repo/ Non-repo based projects"},
  {'subtitle':'Set Environment','desp':"Add your deployment target"},
  {'subtitle':'Add Credentials','desp':"Only if need to work with (AWS.GCP, etc) Add your sponsored accounts into the credential vault"},
  {'subtitle':'Start Creating your Pipeline','desp':"Click pipeline tab at the top Happy Deployment"} 
  ]}
]




  movePage(subtitle:string){
    this.pageName=subtitle;
    this.data=this.orgData;
    this.memory(this.pageName)

  }

  goBack(){
    this.pageName='Popular Topics';
    this.memory(this.pageName)
  }


  public close(): void {
    this.pageName='Popular Topics';
    this.onHelpClose.emit(true);
    this.memory(this.pageName)
}

  memory(pageName){

  let temp=JSON.parse(window.localStorage.getItem("helpEnable"))
    temp.page=pageName;
    window.localStorage.setItem("helpEnable", JSON.stringify(temp));

}


search(text){
  if(text.length != 0){
    // this.data=this.orgData[0].details.filter(person => person.subtitle.includes(text));
    let newarray = this.orgData[0].details.filter(code => code.subtitle.toLowerCase().includes(text.toLowerCase()));
    this.data = [{ 'header': 'Popular Topics','details':newarray}]
  }
  else{
    this.data=this.orgData;
  }
}

clear(){
  this.data=this.orgData;
}




}















