import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

//--
import {
	InArrayPipe,
	InStringPipe,
	InCommaSeperatedPipe,
	RemoveFileExtension,
	ArrayNameSplit,
	EscapeHtmlPipe,
} from "./array.pipes";
import { ClickOutsideDirective } from "./click-outside.directive";
import { NormalColumn } from "./normal-column.pipe";
import { SafePipe } from "./safe.pipe";

@NgModule({
	imports: [CommonModule],
	declarations: [
		InStringPipe,
		InCommaSeperatedPipe,
		InArrayPipe,
		ClickOutsideDirective,
		RemoveFileExtension,
		ArrayNameSplit,
		NormalColumn,
		EscapeHtmlPipe,
		SafePipe,
	],
	exports: [
		InStringPipe,
		InCommaSeperatedPipe,
		InArrayPipe,
		ClickOutsideDirective,
		RemoveFileExtension,
		ArrayNameSplit,
		NormalColumn,
		EscapeHtmlPipe,
		SafePipe,
	],
})
export class PipesModule {}
