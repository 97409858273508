import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '@app/services/user.service';
import { GitLabService } from '@app/services/gitlab.service';
import { SvnService } from '@app/services/svn.service';
import { ApplicationService } from './application.service';
import { ProjectService } from './project.service';
import { ReviewService } from './review.service';
import { ProjectBranchService } from './project-branch.service';
import { NotificationService } from './notification.service';
import { GitHubService } from '@app/services/github.service';
import { MonitoringService } from '@app/services/monitoring.service';
import { ReportsService } from './reports.service';
import { DockerService } from './docker.service';
import { ActionsService, ActionPagenationService } from './actions.service';
import { PipelinePagenationService } from './pipeline.service';

//--

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    UserService,
    GitLabService,
    SvnService,
    ApplicationService,
    ProjectService,
    ReviewService,
    ProjectBranchService,
    NotificationService,
    GitHubService,
    MonitoringService,
    ReportsService,
    DockerService,
    ActionsService,
    ActionPagenationService,
    PipelinePagenationService
  ]
})
export class ServicesModule { }
