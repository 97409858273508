export { CoreModule } from './core.module';

// app settings
export * from './settings/config';

// services
export { MenuService } from './menu/menu.service';
export { SettingsService } from './settings/settings.service';
export { ThemesService } from './themes/themes.service';
export { RouterStorageService } from './router/router-storage.service';

//constants
export * from './constants/constants';
export * from './constants/plugins.constants';