import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { McBreadcrumbsService, McBreadcrumbsConfig } from 'ngx-breadcrumbs';

//--
import { InterfaceComponent } from '@app/interfaces';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends InterfaceComponent implements OnInit {
  crumbs: Array<{ text: string; path: string; droppable?: Array<Object> }> = [];
  @Input('droppable') droppableEnable: boolean = false;
  details: any;
  applicationList: Array<Object>;
  projectList: Array<Object>;
  branchList: Array<Object>;

  constructor(
    private mcBreadcrumbsService: McBreadcrumbsService,
    private mcBreadcrumbsConfig: McBreadcrumbsConfig,
    private route: ActivatedRoute
  ) {
    super();

    // Breadcrumbs modifications after construction
    this.mcBreadcrumbsConfig.postProcess = (crumbs) => {

      let modified = crumbs;
      
      let params = {};
      let getChildParams = (child) => {
        params = Object.assign(params, child.snapshot.params);
        if (child.firstChild) getChildParams(child.firstChild);
      };
      getChildParams(this.route);

      modified.map(c => {
        // Replace underscores with space
        c.text = c.text.replace(/[_]+(.)?/g, (match, text) => { return ` ${match[1].toLocaleUpperCase()}`; });

        // Replace params 
        c.text = c.text.replace(/:(\w+)/g, (match, text) => { return params[text]? params[text][0].toLocaleUpperCase() + params[text].slice(1):''; });

        return c;
      });

      this.details = route.snapshot.data['project'];
      if(route.snapshot.data['project'] !== undefined) {
        this.applicationList = [{text:'Home', path:'/applications/list', header: true}]
        this.applicationList.push({ 
          text: this.details['project']['application']['application_name'], 
          path: `/applications/${this.details['project']['application_id']}/projects/list`
        })

        this.projectList = [];
        this.projectList.push({
          text: this.details['project']['application']['application_name'],
          path: `/applications/${this.details['project']['application_id']}/projects/list`,
          header: true
        });
        this.projectList.push({ 
          text: this.details['project']['project_name'],
          path: `/applications/${this.details['project']['application_id']}/projects/${this.details['project']['id']}/branches/list`
        })
        // this.details['project_list'].forEach(element => {
          
        // });

        this.branchList = [];
        this.branchList.push({
          text: this.details['project']['project_name'],
          path: `/applications/${this.details['project']['application_id']}/projects/${this.details['project']['id']}/branches/list`,
          header: true
        });
        this.details['project']['branches'].forEach(element => {
          this.branchList.push({ 
            text: element['branch_name'],
            path: `/branch/${element['id']}/overview`
          })
        });
      }

      // Ensure that the first breadcrumb always points to home
      // if (crumbs.length && crumbs[0].text !== 'Home') {
      //   let crumbsConcat: any =crumbs.slice(1);
      //   modified = [
      //     {
      //       text: 'Home',
      //       path: `/applications/list`,
      //       // droppable: this.applicationList
      //     },
      //     {
      //       text: 'Project',
      //       path: `/applications/${this.route.snapshot.data.project.project.application_id}/projects/list`,
      //       // droppable: this.projectList
      //     },
      //     {
      //       text: 'Branch',
      //       path: `/applications/${this.route.snapshot.data.project.project.application_id}/projects/${this.route.snapshot.data.project.project.id}/branches/list`,
      //       // droppable: this.branchList
      //     }
      //   ].concat(crumbsConcat);
      // }
      return modified;
    };

  }

  ngOnInit() {
    this.subscriptions['mcBreadcrumbs'] = this.mcBreadcrumbsService.crumbs$.subscribe(crumbs => {
      this.crumbs = crumbs;
    });
  }

  isLastCrumb(index: number) {
    return this.crumbs.length > 0 ? index + 1 === this.crumbs.length : false;
  }

}
