import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SangliFlowDataRefresh {
    constructor() {
    }

    private refreshData = new BehaviorSubject<boolean>(false);
    getRefreshData: Observable<boolean> = this.refreshData.asObservable();

    private projectModal = new BehaviorSubject<boolean>(false);
    toggleProjectModal: Observable<boolean> = this.projectModal.asObservable();

    private environmetModal = new BehaviorSubject<boolean>(false);
    toggleEnvironmetModal: Observable<boolean> = this.environmetModal.asObservable();

    private pipelineModal = new BehaviorSubject<boolean>(false);
    togglePipelineModal: Observable<boolean> = this.pipelineModal.asObservable();

    setRefreshData(data: boolean) {
        this.refreshData.next(data);
    }

    openOrCloseProjectModal(data: boolean) {
        this.projectModal.next(data);
    }

    openOrCloseEnvironmetModal(data: boolean) {
        this.environmetModal.next(data);
    }

    openOrClosePipelineModal(data: boolean) {
        this.pipelineModal.next(data);
    }
}