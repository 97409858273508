import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from '@app/services';
import { ToasterService, Toast } from 'angular2-toaster';
import { TOASTERCONFIG, SettingsService } from '@app/core';
import { NavigationControlService } from './navigation-control.service';

declare var $: any;

@Component({
    selector: 'app-custom-devops-header',
    templateUrl: './custom-devops-header.component.html',
    styleUrls: ['./custom-devops-header.component.scss']
})
export class CustomDevopsHeaderComponent implements OnInit {

    @ViewChild('dropdown') list_dropdown: any;
    @ViewChild('application_dialog_header') application_dialog_header: any;

    application_id: number;
    branch_id: number;
    deleteForm: FormGroup;
    application_list: Array<any> = [];
    processStatus = false;
    application_name: string;
    loading = true;
    toasterConfig = TOASTERCONFIG;
    user = 'Welcome';
    urls: Array<any> = [];
    searchEnable = false;
    application_type = 0;
    default_navigation = true;
    first_time = true;
    menu: any;
    indicators: any;
    listEmit: string[] = [
        'configuration',
        'infrastructure',
        'deploy',
        'dependency',
        'docker-image',
        'kube-events',
        'credentials'
    ];

    @Input('navigation')
    set set_navigation(data) {
        this.default_navigation = data;
    }

    @HostListener('document:click', ['$event'])
    closeDrop(event) {
        const nameArray: Array<string> = [
            'form-control search-option-drop',
            'drop-down',
            'icon-click-show',
            'fa fa-angle-down'
        ];
        const className = nameArray.includes(event.target.className);
        if (this.list_dropdown.isOpen && !className) {
            this.list_dropdown.hide();
        }
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private applicationService: ApplicationService,
        private settingsService: SettingsService,
        private navigateService: NavigationControlService,
        private toasterService: ToasterService
    ) {
        this.settingsService.user$.subscribe(settings => {
            if (settings.firstName) {
                this.user = settings.firstName + ' ' + settings.lastName;
            }
        });

        this.paramsHandle();
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .do(event => event)
            .subscribe((event: NavigationEnd) => {
                this.paramsHandle(true, event);
            });

        this.navigateService.getApplicationList.subscribe(res => {
            if (res) {
                this.getApplicationsList(true);
                this.navigateService.applicationAdded(false);
            }
        });
    }

    ngOnInit() {
        this.deleteForm = this.fb.group({
            'delete': ['', [Validators.required, Validators.pattern(/^([dD](elete|ELETE))$/)]]
        });
        // this.getApplicationsList();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.animationHandle();
    }


    animationHandle() {
        const listEmit: string[] = this.listEmit;
        const check = listEmit.filter(e => this.urls.includes(e)).length > 0;
        if (!check) {
            setTimeout(() => {
                if (this.first_time || !this.menu) {
                    this.menu = $('#middle');
                    // tslint:disable-next-line:max-line-length
                    this.indicators = $('<span class="indicator"></span>');
                    this.menu.append(this.indicators);
                    this.position_indicator(this.indicators, this.menu.find('li.header-active'));
                    this.first_time = false;
                    // setTimeout(function() {this.indicators.css('opacity', 1); }, 500);
                } else {
                    this.position_indicator(this.indicators, this.menu.find('li.header-active'));
                    // setTimeout(function() {this.indicators.css('opacity', 1); }, 500);
                }
                // this.position_indicator(indicator, menu.find('li.header-active'));
            }, 50);
        }
    }

    settingActive() {
        const listEmit: string[] = this.listEmit;
        return listEmit.filter(e => this.urls.includes(e)).length > 0;
    }

    removeSpan() {
        $('.indicator').remove();
        this.menu = undefined;
    }

    position_indicator(indicator, ele) {
        try {
            const left = ele.offset().left + 15;
            const width = ele.width();
            indicator.stop().animate({
                left: left,
                width: width,
            });
        } catch (e) {
            console.log(e);
        }
    }

    paramsHandle(flag = null, event = null) {
        this.route.children.forEach(ele => {
            ele.params.subscribe((params: Params) => {
                this.application_id = params['id'];
            });
        });
        this.route.parent.params.subscribe(params => {
            if ('id' in params) {
                this.application_id = params['id'];
            }
            this.route.params.subscribe(subParams => {
                if ('id' in subParams) {
                    this.application_id = subParams['id'];
                }
                if (flag) {
                    this.urls = event.url.split('/');
                    this.urls.splice(1, 1);
                    this.animationHandle();
                    if (this.urls.includes('pipeline-runs')) {
                        const rmIndex = this.urls.indexOf('pipeline');
                        if (rmIndex > -1) {
                            this.urls.splice(rmIndex, 1);
                            this.animationHandle();
                        }
                    }
                } else {
                    this.urls = this.router.url.split('/');
                    this.urls.splice(1, 1);
                    this.animationHandle();
                    if (this.urls.includes('pipeline-runs')) {
                        const rmIndex = this.urls.indexOf('pipeline');
                        if (rmIndex > -1) {
                            this.urls.splice(rmIndex, 1);
                            this.animationHandle();
                        }
                    }
                }
                this.navigateService.setApplicationId(this.application_id);
                this.getApplicationsList();
            });
        });
    }

    deleteApplication(value: boolean) {
        if (value) {
            this.processStatus = true;
            this.applicationService.deleteApplication(this.application_id)
                .subscribe(resp => {
                    this.toasterService.pop(<Toast>{
                        type: 'success',
                        title: 'Application Deleted',
                    });
                    this.processStatus = false;

                    $('#del_app_new').modal('hide');
                    this.deleteForm.controls.delete.reset('');
                    this.navigateService.applicationAdded(true);
                    if (resp['id']) {
                        this.router.navigate([`/applications/${resp['id']}/dashboard`]).then(res => res);
                    } else {
                        this.router.navigate([`/applications/dashboard`]).then(res => res);
                    }
                },
                    error => {
                        console.error(error);
                        this.processStatus = false;
                        $('#del_app_new').modal('hide');
                        this.deleteForm.controls.delete.reset('');
                        this.toasterService.pop(<Toast>{
                            type: 'error',
                            title: 'Failed to Delete Application',
                        });
                    }
                );
        } else {
            $('#del_app_new').modal('hide');
            this.deleteForm.controls.delete.reset('');
        }
    }

    getApplicationsList(latest = null) {
        this.applicationService.listApplication({}).subscribe(resp => {
            this.application_list = resp['records'];
            if (!this.application_id || latest) {
                if (this.application_list.length) {
                    this.application_id = this.application_list[0]['id'];
                    this.application_name = this.application_list[0]['application_name'];
                    this.application_type = this.application_list[0]['application_type'];
                    this.animationHandle();
                } else {
                    this.application_name = ' Application ';
                    this.application_id = 0;
                    this.animationHandle();
                }
            } else {
                this.navigateService.navigateSet(true);
                const app = this.application_list.filter(x => x['id'] === this.application_id);
                if (app.length > 0) {
                    this.application_id = app[0]['id'];
                    this.application_name = app[0]['application_name'];
                    this.application_type = app[0]['application_type'];
                    this.animationHandle();
                } else {
                    this.applicationService.get_application_name(this.application_id).subscribe(
                        (res: any) => {
                            this.application_name = res ? res : ' Application ';
                            // this.application_name = res ? res : ' Application ';
                            this.animationHandle();
                        },
                        error => {
                            console.error(error);
                            this.application_name = ' Application ';
                        }
                    );
                }
            }
            this.navigateService.defaultNavigate.subscribe(data => {
                if (this.default_navigation && !data) {
                    this.urls = ['dashboard'];
                    this.animationHandle();
                    if (this.application_id !== 0) {
                        this.router.navigate([`/applications/${this.application_id}/dashboard`]).then(res => res);
                    } else {
                        this.router.navigate([`/applications/dashboard`]).then(res => res);
                    }
                    setTimeout(() => {
                        this.navigateService.navigateSet(true);
                    }, 1500);
                }
            });
            this.loading = false;
        });

    }

    navigate(key: any) {
        switch (key) {
            case 'dashboard':
                this.urls = ['dashboard'];
                this.navigation(`/applications/${this.application_id}/dashboard`);
                this.animationHandle();
                break;
            case 'list':
                this.urls = ['projects'];
                this.navigation(`/applications/${this.application_id}/projects/list`);
                this.animationHandle();
                break;
            case 'delete':
                $('#del_app_new').modal('show');
                break;
            case 'configuration':
                this.urls = ['configuration'];
                this.navigation(`/applications/${this.application_id}/configuration/type`);
                this.removeSpan();
                break;
            case 'imagecreation':
                this.urls = ['dependency'];
                this.navigation(`/applications/${this.application_id}/dependency`);
                this.removeSpan();
                break;
            case 'deploy':
                this.urls = ['deploy'];
                if (this.application_type === 1) {
                    this.navigation(`/applications/${this.application_id}/deploy`);
                    this.removeSpan();
                } else {
                    this.navigation(`/applications/${this.application_id}/deploy/deploy-baremetal`);
                    this.removeSpan();
                }
                break;
            case 'edit':
                this.urls = ['applications', 'edit'];
                this.navigation(`/applications/${this.application_id}/edit`);
                this.animationHandle();
                break;
            case 'create':
                this.urls = ['create'];
                // this.router.navigate(['/applications/create']);
                this.application_dialog_header.open_modal();
                this.animationHandle();
                break;
            case 'infrastructure':
                this.urls = ['infrastructure'];
                this.navigation(`/applications/${this.application_id}/infrastructure`);
                this.removeSpan();
                break;
            case 'pipeline':
                this.urls = ['pipeline'];
                this.navigation(`/applications/${this.application_id}/pipeline/list`);
                this.animationHandle();
                break;
            case 'pipeline-runs':
                this.urls = ['pipeline-runs'];
                this.navigation(`/applications/${this.application_id}/pipeline/pipeline-runs`);
                this.animationHandle();
                break;
            case 'monitoring':
                this.urls = ['monitoring'];
                this.navigation(`/applications/${this.application_id}/monitoring`);
                this.animationHandle();
                break;
            case 'reports':
                this.urls = ['reports'];
                this.navigation(`/applications/${this.application_id}/reports`);
                this.animationHandle();
                break;
            case 'credentials':
                console.log('credentials');
                this.urls = ['credentials'];
                this.navigation(`/applications/${this.application_id}/credentials`);
                this.removeSpan();
                break;
            default:
                this.urls = [];
                this.animationHandle();
                console.log('Default switch');
                break;
        }
    }

    navigation(flag) {
        this.router.navigate([flag]).then(res => res);
        // if (this.application_id != 0) {
        //   this.router.navigate([flag]);
        // }
        // else {
        //   this.toasterService.pop(<Toast>{
        //     type: 'error',
        //     title: 'Failed to delete application',
        //   });
        // }
    }

    change(item) {
        this.urls = ['dashboard'];
        this.animationHandle();
        this.list_dropdown.hide();
        this.application_id = item['id'];
        this.application_name = item['application_name'];
        this.application_type = item['application_type'];
        $('#inputSuccess2').val('');
        this.router.navigate([`/applications/${item['id']}/dashboard`]).then(res => res);
    }

    filterOption(event) {
        this.searchEnable = true;
        const data = { data: event.target.value };
        this.applicationService.listApplication(data).subscribe(
            (res: object[]) => {
                this.application_list = res['records'];
                this.searchEnable = false;
            });
    }

    // navigateToHome() {
    //     if (this.application_list.length > 0) {
    //         this.application_id = this.application_list[0]['id'];
    //         this.application_name = this.application_list[0]['application_name'];
    //         this.application_type = this.application_list[0]['application_type'];
    //         this.router.navigate([`/applications/${this.application_id}/dashboard`]).then(res => res);
    //     } else {
    //         this.application_name = ' Application ';
    //         this.application_id = 0;
    //         this.router.navigate([`/applications/dashboard`]).then(res => res);
    //     }
    // }
}
