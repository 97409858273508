import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/shared/directives';
import { InterfaceComponent } from '@app/interfaces';
import { Config } from './interfaces';
import { InterfaceService } from '@app/interfaces';
import { ProjectService } from '@app/services';

@Component({
  selector: 'app-pipelines',
  templateUrl: './pipelines.component.html',
  styleUrls: ['./pipelines.component.scss'],
  providers: [
    InterfaceService
  ]
})
export class PipelinesComponent extends InterfaceComponent implements OnInit {
  _config: Config = {
    pipelineSteps: [],
    navigateUrl: null,
    heading: null
  };
  pipelineSteps;
  step;
  branch_id;
  branch_details;
  navigateUrl: string;
  showPipeline: boolean = false;
  last_index;
  // Events
  @Output('linkClicked') linkClicked: EventEmitter<any> = new EventEmitter();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private projectService: ProjectService
  ) {
    super()
    this.route.params.subscribe((params: Params) => {
      this.branch_id = params['branch_id']
      this.branch_details = this.route.snapshot.data.project
    });
  }

  ngOnInit() {
  }


  @Input('config')
  set config(config: Config) {
    if (config instanceof Object) this._config = Object.assign(this._config, config);
    this.pipelineSteps = this._config.pipelineSteps;
    this.navigateUrl = this._config.navigateUrl;
    let status = this.branch_details['stage']
    let currentStatusIndex = this.pipelineSteps.indexOf(status);
    this.last_index = this.pipelineSteps.length - 1
    let check_stage_position = this.last_index - currentStatusIndex;
    this.showPipeline = true;
    this.step = currentStatusIndex < this.pipelineSteps.length - 1 ? this.pipelineSteps[currentStatusIndex + 1] : this.showPipeline = false;
    if (this.branch_details.is_skipped && check_stage_position == 0)
      this.showPipeline = false;
  }

  navigate() {

  }
  onClickEvent(stage) {
    this.linkClicked.emit({ "stage": stage })
  }

  isStepCompleted(step: string) {
    // let stage = (project_stage != null)?this.project_details.stage = project_stage:this.project_details.stage
    return this.pipelineSteps.indexOf(this.branch_details.stage) >= this.pipelineSteps.indexOf(step);
  }
  isStepSkipped(step: string) {
    // let stage = (project_stage != null)?this.project_details.stage = project_stage:this.project_details.stage
    if (step == this.branch_details.skipped_stage && this.branch_details.is_skipped == true) {
      return true;
    }
    else if(step !== 'Jenkins' && this.branch_details.skipped_stage == 'ReviewBoard-SonarQube') {
      return true;
    }
  }

}
