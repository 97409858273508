import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InterfaceService} from '@app/interfaces';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReviewService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    getProjectDependency(app_id) {
        return this.http.get(this.getApiUrl(`projects/${app_id}/get_project_builds/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getEnvironmentDependency() {
        return this.http.get(this.getApiUrl(``), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getSteps(app_id) {
        return this.http.get(this.getApiUrl(`application/${app_id}/get_steps/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getProjectsList(app_id) {
        return this.http.get(this.getApiUrl(`environment/${app_id}/get-projects-for-infrastructure/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getConfiguredBranches(app_id) {
        return this.http.get(this.getApiUrl(`environment/${app_id}/get-projects-for-infrastructure/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    savelist(env_id, data) {
        const url = this.getApiUrl(`environment/${env_id}/create-deploymentTargets/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getexistingenvironment(env_id) {
        return this.http.get(this.getApiUrl(`environment/${env_id}/get-deploymentTargets/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getenvironmentdetails(prj_id) {
        return this.http.get(this.getApiUrl(`environment/${prj_id}/environmentDetails/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deletePrjEnv(env_id,id) {

        const url = this.getApiUrl(`environment/${env_id}/delete-prjenv/`,{'id':id});
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }


    deleteenvironment(env_id) {
        const url = this.getApiUrl(`environment/${env_id}/delete-environment/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }

    // Aprove the project and environment dependencies

    reviewApprove(app_id, details) {
        return this.http.post(this.getApiUrl(`application/${app_id}/review_details/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
}

