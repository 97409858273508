import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/shared/directives';
import { InterfaceComponent } from '@app/interfaces';
import { Config } from './interfaces';
import { InterfaceService } from '@app/interfaces';
import { ProjectService } from '@app/services';

@Component({
  selector: 'app-application-wizard',
  templateUrl: './application-wizard.component.html',
  styleUrls: ['./application-wizard.component.scss'],
  providers: [
    InterfaceService
  ]
})
export class ApplicationWizardComponent extends InterfaceComponent implements OnInit {

  _config: Config = {
    remoteUrl: null,
    remoteParams: {},
    wizardSteps: [],
    wizardForms: {},
    navigateUrl: null
  };
  wizardSteps;
  step;
  branch_id;
  application_details: any;
  wizardForms;
  navigateUrl: string;
  activeForm: FormGroup;
  showWizard: boolean = false;
  // Events
  @Output('linkClicked') linkClicked: EventEmitter<any> = new EventEmitter();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
  ) {
    super()
    this.route.params.subscribe((params: Params) => {
        this.application_details = this.route.snapshot.data.info
        console.log(this.application_details)
    });
  }

  ngOnInit() {
  }


  @Input('config')
  set config(config: Config) {
    if (config instanceof Object) this._config = Object.assign(this._config, config);
    this.wizardSteps = this._config.wizardSteps;
    this.wizardForms = this._config.wizardForms;
    this.navigateUrl = this._config.navigateUrl;
    // this.step = this.wizardSteps[0]
    let status = this.application_details['stage']
    let currentStatusIndex = this.wizardSteps.indexOf(status);
    this.step = currentStatusIndex < this.wizardSteps.length - 1 ? this.wizardSteps[currentStatusIndex + 1] : this.navigate();
    this.showWizard = true;
    // this.getActiveForm();
  }


  // Wizard functions
  // ===============================================================================================================
  onClickEvent(stage) {
    if(this.wizardSteps.indexOf(this.step) > this.wizardSteps.indexOf(stage))
      this.linkClicked.emit({ "previousStep": stage, "currentStep": this.step });
    else
    this.linkClicked.emit({ "nextStep": stage, "currentStep": this.step });
  }

  navigate() {
    this.router.navigate([this.navigateUrl]);
  }

  jumpToStep(stage: string, form, details) {
    this.application_details = details;
    let currentStatusIndex: number = this.wizardSteps.indexOf(this.step);
    let targetStatusIndex: number = this.wizardSteps.indexOf(stage);
    if (currentStatusIndex == targetStatusIndex) return;
    this.activeForm = form
    this.moveStep('target', stage);
  }
  completeProcess() {
    this.moveStep('complete');
  }

  moveStep(direction: 'forward' | 'backward' | 'complete' | 'target', target?: string) {
    let setStep = (step) => {
      this.activeForm = null;
      this.step = step;
    };
    // Stage change action
    let move = () => {

      let currentStatusIndex: number = this.wizardSteps.indexOf(this.step);
      let initializtionFinalIndex: number = this.wizardSteps.length - 1;

      if (direction === 'target') {
        setStep(target);
      }
      else if (direction === 'complete') {
        this.router.navigate([this.navigateUrl]);
      }

    };

    // Checks form for any changes before changing stage
    if (direction !== 'complete' && this.activeForm.pristine && this.activeForm.valid) move();
    else if (!this.activeForm.pristine && this.activeForm.invalid) return;
    else if ((!this.activeForm.pristine && this.activeForm.valid) || (this.activeForm.valid && direction === 'complete'))
      move();

  }

  isStepCompleted(step: string) {
    let stage = this.application_details.stage
      return this.wizardSteps.indexOf(stage) >= this.wizardSteps.indexOf(step);
  }

  get self() {
    return this;
  }

  getActiveForm() {
    this.activeForm = this.wizardForms[this.step.toLowerCase()];
    this.activeForm.reset();
  }



}


