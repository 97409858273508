import { getToken } from '@app/auth/token';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { map, catchError, tap } from 'rxjs/operators';
import { jsonToFormData } from '@app/utils/utils';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService extends InterfaceService {

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    getMetrics(app_id, start_date, end_date, filter) {
        const url = this.getApiUrl(`elasticapm/${app_id}/get-metrics/?start_date=${start_date}&end_date=${end_date}&filter=${filter}`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // START Prometheus service methods
    savePrometheusDetails(appId: number, details: any) {
        const url = this.getApiUrl(`prometheus/${appId}/save-details/`);
        return this.http.post(url, JSON.stringify(details), this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getPrometheusDetails(appId: number) {
        const url = this.getApiUrl(`prometheus/${appId}/get-details/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            tap(Response => Response),
            catchError(this.handleError)
        );
    }

    deletejob(id) {
        const url = this.getApiUrl(`prometheus/${id}/delete/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getPrometheusAlerts(appId: number, status) {
        const url = this.getApiUrl(`prometheus-alert/${appId}/get-alerts/?status=${status}`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getPrometheusMetrics(appId: number, stDate: any, endDate: any, filter: any) {
        const url = this.getApiUrl(`prometheus/${appId}/get-metrics/?start_date=${stDate}&end_date=${endDate}&filter=${filter}`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    // END Prometheus service methods

    saveSlackDetails(appId: number, details: any) {
        const url = this.getApiUrl(`slack/${appId}/save-slack-details/`);
        return this.http.post(url, JSON.stringify(details), this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    getSlackDetails(appId: number) {
        const url = this.getApiUrl(`slack/${appId}/get-slack-details/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    get_monitoring_details(appId: number) {
        const url = this.getApiUrl(`prometheus-alert/${appId}/get-monitoring-details/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        );
    }

    createAwsResourceMonitoring(payload: any) {
        const url = `monitor-aws-resource/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    createGcpResourceMonitoring(payload: any) {
        const url = `monitor-gcp-resource/`
        const formData = jsonToFormData(payload)
        return this.http.post(this.getApiUrl(url), formData, this.getHttpOptions('multipart'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    uploadFile(file: File, key: any, url: string) {
        const formData = new FormData();
        formData.append(key, file);
        const token = getToken()
        return this.http.post(url, formData, { headers: { "Content-Type": "multipart/form-data;", "Authorization": `JWT ${token}` } });
    }

    deleteAwsResourceMonitoring(applicationId: number) {
        const url = `monitor-aws-resource/${applicationId}/`;
        return this.http.delete(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(response => response),
                catchError(this.handleError)
            )
    }

    deleteGcpResourceMonitoring(applicationId: number) {
        const url = `monitor-gcp-resource/${applicationId}/`;
        return this.http.delete(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(response => response),
                catchError(this.handleError)
            )
    }

    getAwsResourceMonitoringData(applicationId: number) {
        const url = `monitor-aws-resource/${applicationId}/get-aws-resource-monitoring-data`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getGcpResourceMonitoringData(applicationId: number) {
        const url = `monitor-gcp-resource/${applicationId}/get-gcp-resource-monitoring-data`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    createMonitoring(payload: any, id: any) {
        const url = `monitor-aws-resource/${id}/create-customer-dashboard/`;

        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    instanceStatus(envType: any, companyName: any) {
        const url = `monitor/targets-status/?company=${companyName}&env_type=${envType}`
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );

    }
    deleteMonitoring(payload: any, id: any) {
        const url = `monitor-aws-resource/${id}/delete-monitoring-instance/`;

        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    bulkDelete(payload: any, id: any) {
        const url = `monitor-aws-resource/${id}/delete-all-monitoring-instance/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );

    }

    createAwsClassicMonitoring(payload: any) {
        const url = `monitor-ibm-classic-resource/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getIbmClassicResourceMonitoringData(applicationId: number) {
        const url = `monitor-ibm-classic-resource/${applicationId}/get-ibm-classic-infra-monitoring-data`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }



    createIbmClassicMonitoring(payload: any, id: any) {
        const url = `monitor-ibm-classic-resource/${id}/create-customer-dashboard/`;

        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    deleteIbmClassicMonitoring(payload: any, id: any) {
        const url = `monitor-ibm-classic-resource/${id}/delete-monitoring-instance/`;

        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    ibmClassicbulkDelete(payload: any, id: any) {
        const url = `monitor-ibm-classic-resource/${id}/delete-all-monitoring-instance/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );

    }



    setAlert(payload: any) {
        const url = `alert`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );

    }

    editSetAlert(alertId: number) {
        const url = `alert/${alertId}`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    updataSetAlert(alertId: any, payload: any) {
        return this.http.put(this.getApiUrl(`alert/${alertId}/`), JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }
    removeAlert(alertId: any, appId: any) {
        const url = `alert/${alertId}/?application_id=${appId}`;
        return this.http.delete(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(response => response),
                catchError(this.handleError)
            )
    }

}
