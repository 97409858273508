import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SupportAuthGuard implements CanActivate {

  constructor(
    // router to navigate user
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate() {
    let user = this.authService.currentUser;
    // because if user not logged in it can be null, eventhough it wont be a trouble now
    // because we implemented first AuthGuard, then this guard 
    const { isAdmin, isSuperUser, isSupportUser } = this.authService.currentUser;
    console.log('isAdmin', isAdmin)
    if (user && !isAdmin && !isSuperUser && isSupportUser) return true

    // otherwise navigate to them permission denied page
    this.router.navigate(['/error/permission-denied']);
    return false;
  }
}
