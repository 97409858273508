import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

//--

// Check element in array pipe ......................................................................
@Pipe({ name: 'array_contains' })

export class InArrayPipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value instanceof Array) {
            if (value.indexOf(args) >= 0) return true;
        }
        return false;
    }
}

// Check char in string pipe ......................................................................
@Pipe({ name: 'str_contains' })

export class InStringPipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value && value != '') {
            if (value.indexOf(args) >= 0) return true;
        }
        return false;
    }
}

// Check value in comma seperated string pipe ......................................................
@Pipe({ name: 'in_comma_seperated' })

export class InCommaSeperatedPipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) {
            value = value.split(',');
            if (value.indexOf(args.toString()) >= 0) return true;
        }
        return false;
    }
}

// return file name without file extension ..........................................................
@Pipe({ name: 'Remove_File_Extension' })

export class RemoveFileExtension implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) {
            value = value.split('.');
            if (value.length >= 0) return value[0];
        }
        return value;
    }
}

// return array_name as string ..........................................................
@Pipe({ name: 'array_name_split' })

export class ArrayNameSplit implements PipeTransform {
    transform(value, args: string[]): any {
        if (Array.isArray(value)) {
            let temp_array = [];
            value.forEach(element => {
                temp_array.push(element['text'])
            });
            let temp = temp_array.join(', ');
            return temp
        }
        return value;
    }
}
// Replaces underscores into spaces pipe .....................................................................
@Pipe({ name: 'u_to_space' })

export class UnderscoreToSpacePipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) {
            return value.replace(/_/g, ' ');
        }
        else return value;
    }
}

// Capitalize pipe .....................................................................
@Pipe({ name: 'capitalize' })

export class CapitalizePipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) return value[0].toUpperCase() + value.slice(1);
        else return value;
    }
}


// Convert text to html
@Pipe({ name: 'keepHtml', pure: false })
export class EscapeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }
    transform(content) {
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}