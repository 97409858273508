import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute, Router, Params, NavigationEnd} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {InterfaceComponent} from '@app/interfaces';
import {Config} from './interfaces';
import {InterfaceService} from '@app/interfaces';
import {ProjectBranchService} from '@app/services';
import {NavigationControlService} from "@app/shared/directives/custom-devops-header/navigation-control.service";

@Component({
    selector: 'app-wizard-view',
    templateUrl: './wizard-view.component.html',
    styleUrls: ['./wizard-view.component.scss'],
    providers: [
        InterfaceService
    ]
})
export class WizardViewComponent extends InterfaceComponent implements OnInit {

    _config: Config = {
        remoteUrl: null,
        remoteParams: {},
        wizardSteps: [],
        wizardForms: {},
        navigateUrl: null
    };
    wizardSteps;
    step;
    branch_id;
    branch_details;
    wizardForms;
    navigateUrl: string;
    activeForm: FormGroup;
    showWizard = false;

    // Events
    @Output('linkClicked') linkClicked: EventEmitter<any> = new EventEmitter();


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private branchService: ProjectBranchService,
        private navigationControlService: NavigationControlService
    ) {
        super();
        this.route.params.subscribe((params: Params) => {
            if ('branch_id' in params) {
                this.branch_id = params['branch_id'];
                this.branch_details = this.route.snapshot.data.project;
            }
        });
        this.router.events.filter(event => event instanceof NavigationEnd)
            .do(event => {
                this.step = null;
                this.showWizard = false;
            })
            .subscribe(event => {
                this.route.params.subscribe((params: Params) => {
                    if ('branch_id' in params) {
                        this.branch_id = params['branch_id'];
                        this.branchService.getBranch(this.branch_id).subscribe(
                            res => {
                                this.branch_details = res;
                                const status = this.branch_details['stage'];
                                const currentStatusIndex = this.wizardSteps.indexOf(status);
                                // tslint:disable-next-line:max-line-length
                                this.step = currentStatusIndex < this.wizardSteps.length - 1 ? this.wizardSteps[currentStatusIndex + 1] : this.branchNavigate();
                                this.showWizard = true;
                            }
                        );
                    } else {
                        this.showWizard = true;
                    }
                });
            });
    }

    ngOnInit() {
    }


    @Input('config')
    set config(config: Config) {
        if (config instanceof Object) {
            this._config = Object.assign(this._config, config);
        }
        this.wizardSteps = this._config.wizardSteps;
        this.wizardForms = this._config.wizardForms;
        this.navigateUrl = this._config.navigateUrl;
        // this.step = this.wizardSteps[0]
        const status = this.branch_details['stage'];
        const currentStatusIndex = this.wizardSteps.indexOf(status);
        this.step = currentStatusIndex < this.wizardSteps.length - 1 ? this.wizardSteps[currentStatusIndex + 1] : this.navigate();
        this.showWizard = true;
        // this.getActiveForm();
    }


    // Wizard functions
    // ===============================================================================================================
    onClickEvent(stage) {
        if (this.wizardSteps.indexOf(this.step) > this.wizardSteps.indexOf(stage)) {
            this.linkClicked.emit({'previousStep': stage, 'currentStep': this.step});
        } else {
            this.linkClicked.emit({'nextStep': stage, 'currentStep': this.step});
        }
    }

    navigate() {
        this.router.navigate([this.navigateUrl]);
    }

    jumpToStep(stage: string, form, details) {
        this.branch_details = details;
        const currentStatusIndex: number = this.wizardSteps.indexOf(this.step);
        const targetStatusIndex: number = this.wizardSteps.indexOf(stage);
        if (currentStatusIndex === targetStatusIndex) {
            return;
        }
        this.activeForm = form;
        this.moveStep('target', stage);
    }

    completeProcess() {
        this.moveStep('complete');
    }

    moveStep(direction: 'forward' | 'backward' | 'complete' | 'target', target?: string) {
        const setStep = (step) => {
            this.activeForm = null;
            this.step = step;
        };
        // Stage change action
        const move = () => {

            const currentStatusIndex: number = this.wizardSteps.indexOf(this.step);
            const initializtionFinalIndex: number = this.wizardSteps.length - 1;

            if (direction === 'target') {
                setStep(target);
            } else if (direction === 'complete') {
                this.router.navigate([this.navigateUrl]);
            }

        };

        // Checks form for any changes before changing stage
        if (direction !== 'complete' && this.activeForm.pristine && this.activeForm.valid) {
            move();
        } else if (!this.activeForm.pristine && this.activeForm.invalid) {
            return;
        } else if ((!this.activeForm.pristine && this.activeForm.valid) || (this.activeForm.valid && direction === 'complete')) {
            move();
        }

    }

    isStepSkipped(step: string) {
        if (step === 'ReviewBoard') {
            return this.branch_details.stage_number > 1 && this.branch_details.rb_skipped;
        } else if (step === 'SonarQube') {
            return this.branch_details.stage_number > 2 && this.branch_details.sonar_skipped;
        }
    }

    isStepCompleted(step: string) {
        const stage = this.branch_details.stage;
        if (this.branch_details.is_reconfigured) {
            return this.wizardSteps.indexOf(stage) <= this.wizardSteps.indexOf(step);
        } else {
            return this.wizardSteps.indexOf(stage) >= this.wizardSteps.indexOf(step);
        }
    }

    get self() {
        return this;
    }

    getActiveForm() {
        this.activeForm = this.wizardForms[this.step.toLowerCase()];
        this.activeForm.reset();
    }

    branchNavigate() {
        this.navigationControlService.getBranchId.subscribe(
            res => {
                if (this.branch_details.stage === 'Jenkins' && res === this.branch_details.id) {
                    this.navigate();
                }
            }
        );
    }

}
