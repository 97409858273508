import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends InterfaceService {

  constructor(private http: HttpClient) {
    super();
  }

  get_reports_detail(app_id) {
    return this.http.get(this.getApiUrl(`reports/${app_id}/get-reports/`), this.getHttpOptions('json'))
      .pipe(
        tap(Res => Res),
        catchError(this.handleError)
      );
  }
  

}
