import { Component, Input, OnInit, OnDestroy, AfterViewInit, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, DoCheck, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { getCircularReplacer } from '@app/utils/utils';
import { createController } from './controllers/monaco.controller';
import { TreeNode } from './functions/TreeNode';
import { Tree } from './utils/monaco.utils';

@Component({
  selector: 'app-monaco-tree-view',
  templateUrl: './monaco-tree-view.component.html',
  styleUrls: ['./monaco-tree-view.component.scss']
})
export class MonacoTreeViewComponent implements OnInit,  OnDestroy, OnChanges {
@Input('directory') directory: TreeNode
@Input('treeConfig') treeConfiguration: any

// create get acitonhers
@Input('getActions') getActions:any
@Input('onClickFile') onClickFile:any

  tree: any;
  container: any;
  constructor() { }
  

  ngOnChanges(changes: SimpleChanges): void {       
    if (changes.directory && this.directory.children.length > 0) {
      if(this.tree)
        this.tree.model.setInput(this.directory);
    }
    else if (changes.directory && changes.directory.previousValue && changes.directory.previousValue.children.length > 0){
      this.tree.model.setInput(this.directory);
    }
    
  }


  ngOnInit() {
    console.log('directory', this.directory);
    const stringData = JSON.stringify(this.directory, getCircularReplacer());
    console.log('json representation',JSON.parse(stringData));
    
    
    console.log('tree config', this.treeConfiguration);
  }
  
  ngAfterViewInit(): void {
    this.container = document.querySelector('.tree-view-wrapper')
      this.ensureTree();
      console.log('before set input', this.directory);
      this.tree.model.setInput(this.directory);
      this.tree.model.onDidSelect((e) => {
        console.log('did selecr', e);
        if (e.selection.length) {
          this.onClickFile(e.selection[0]);
        }
      });
      document.addEventListener("layout", this.onLayout);
      
    //   if (this.directory) {  
    //     this.tree.model.setInput(this.directory);
    //   }else {
    //     this.tree.model.refresh();
    //     this.expandTree(this.tree);
    //   }
  }

  ngOnDestroy(): void {
    console.log('child ngOnDestroy called');
    
    document.removeEventListener("layout", this.onLayout);
  }

  ensureTree() {    
    if (this.container.lastChild) {
      this.container.removeChild(this.container.lastChild);
    }

    console.log('treeConfigssss', this.treeConfiguration);
    this.treeConfiguration.controller = createController(this, this.getActions, true),
    console.log('treeconfoig', this.treeConfiguration.controller);
    this.tree = new Tree(this.container, this.treeConfiguration );
  }

  expandTree(tree){

    const model = tree.model;
    const elements = [];

    let item;
    const nav = model.getNavigator();

    while (item = nav.next()) {
        elements.push(item);
    }

    for (let i = 0, len = elements.length; i < len; i++) {
        model.expand(elements[i]);
    }

  }
  onLayout(){
    this.tree.layout();
  }  
}
