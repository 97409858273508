import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";
import { timer } from "rxjs";

@Directive({
    selector: "[textEllipsisForText]"
})
export class TextEllipsis implements AfterViewInit {


    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }


    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        timer(100).subscribe({
            next: () => {
                this.checkAndRemoveTitle();
            }
        })
    }

    checkAndRemoveTitle() {
        const element = this.elementRef.nativeElement;
        if(element.offsetWidth >= element.scrollWidth) {
            this.renderer.removeAttribute(element, 'title');
        }
    }
}