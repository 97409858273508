import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

/** --- Interface --- */
import { Config, Column, RowLinkClickEvent } from './interface';
import { InterfaceService } from '@app/interfaces';

/** --- Http client --- */
import { HttpClient } from '@angular/common/http';

/** --- Api --- */
import { GetApiurl } from '@app/core';
import { catchError } from 'rxjs/operators';
// import { PaginationComponent } from 'ngx-bootstrap';
// import { PagenationService } from '@app/services';


@Component({
    selector: 'app-custom-datatable',
    templateUrl: './custom-datatable.component.html',
    styleUrls: ['./custom-datatable.component.scss'],
    providers: [
        InterfaceService
    ]
})
export class CustomDatatableComponent implements OnInit {

    /** --- EventEmitter --- */
    @Output('linkClicked') linkClicked: EventEmitter<RowLinkClickEvent> = new EventEmitter();
    @Output('tableButton') tableButton: EventEmitter<any> = new EventEmitter();
    @Output('apiResponse') apiResponse: EventEmitter<any> = new EventEmitter();


    /** --- Variables --- */
    tableConfig: Config;
    column: Column[];
    displayedColumn: string[] = [];
    subscriptions: object = {};
    rows: Array<any> = [];
    page: number;
    loading = true;
    pageSize = 10;
    sort: object = {};
    // currentPage=1;
    constructor(
        private http: HttpClient,
        private interfaceService: InterfaceService,
        // private pageState: PagenationService

    ) {
    }

    ngOnInit() {

        /** --- First time load --- */
        // this.tableConfig.remoteParams['offset'] = 0;
        // this.tableConfig.remoteParams['limit'] = this.pageSize;
        // this.recoverLastPage();
        // if (this.currentPage){
        //     this.tableConfig.remoteParams['offset'] = (this.currentPage - 1) * this.pageSize;
        //     this.tableConfig.remoteParams['limit'] = this.currentPage * this.pageSize;
        // }
        // else{
        this.tableConfig.remoteParams['offset'] = 0;
        this.tableConfig.remoteParams['limit'] = this.pageSize;
        // }

        this.fetchData();
    }

    /** --- Table configuration --- */
    @Input('config')
    set config(config: Config) {
        if (config instanceof Object) {
            this.tableConfig = config;
        }
        this.pageSize = this.tableConfig.pageSize || this.pageSize;
        // this.currentPage = this.tableConfig.pageNo || this.currentPage;
    }

    /** --- Columns configuration */
    @Input('columns')
    set columns(cols: Column[]) {
        if (cols instanceof Array) {
            this.displayedColumn = cols.map(x => x.name);
            this.column = cols;
        }
    }

    /** --- Fetching data */
    fetchData(type?: any) {
        if (type && type == 'interval') {
            this.loading = false;
        } else {
            this.loading = true;
        }
        if (!this.tableConfig.remoteUrl) {
            return;
        }

        const url = GetApiurl(this.tableConfig.remoteUrl, this.tableConfig.remoteParams);
        if (this.subscriptions['dataFetch']) {
            this.subscriptions['dataFetch'].unsubscribe();
        }

        this.subscriptions['dataFetch'] = this.http.get<Object[]>(url, this.interfaceService.getHttpOptions())
            .pipe(catchError(E => {
                console.log(E);
                return E;
            }))
            .subscribe(
                (data: Object[]) => {
                    this.rows = data['records'];
                    this.page = data['totalRecords']; // :ToDo: data.length;
                    delete this.subscriptions['dataFetch'];
                    this.loading = false;
                    this.apiResponse.emit({data})
                },
                Error => {
                    this.rows = [];
                    console.error(Error);
                    delete this.subscriptions['dataFetch'];
                    this.loading = false;
                }
            );
    }

    /** --- Action click emit --- */
    onRowAction(data) {
        this.linkClicked.emit(data);
    }

    /** --- Pagination change event --- */
    pageChanged(event) {
        // console.log("page change",event.page,event.itemsPerPage)
        // this.currentPage=event.page;
        this.rows = [];
        const offset = (event.page - 1) * event.itemsPerPage;
        const limit = event.page * event.itemsPerPage;
        this.tableConfig.remoteParams['offset'] = offset;
        this.tableConfig.remoteParams['limit'] = limit;
        this.fetchData();
    }

    /** --- Sorting --- */
    sorting(name) {
        this.rows = [];
        if (this.sort['name'] === name) {
            if (this.sort['asc']) {
                this.tableConfig.remoteParams['sort'] = `-${name}`;
            } else {
                this.tableConfig.remoteParams['sort'] = name;
            }
            this.sort['asc'] = !this.sort['asc'];
        } else {
            this.sort['name'] = name;
            this.tableConfig.remoteParams['sort'] = name;
            this.sort['asc'] = true;
        }
        this.fetchData();
    }

    /** --- Refresh table --- */
    refreshData(type?: any) {
        this.fetchData(type ? type : '');
    }

    /** --- Table button emit --- */
    tableButtonClick() {
        this.tableButton.emit(true);
    }

    /** --- Re-configure and refresh data */
    reConfigAndRefresh(config) {
        if (config instanceof Object) {
            this.tableConfig = config;
        }
        this.pageSize = this.tableConfig.pageSize || this.pageSize;
        this.tableConfig.remoteParams['offset'] = 0;
        this.tableConfig.remoteParams['limit'] = this.pageSize;
        this.refreshData();
    }

    /** --- Search --- */
    search(event) {
        this.tableConfig.remoteParams['searchText'] = event.target.value;
        this.fetchData();
    }

    /** Row Click Event */
    rowClickAction(data: { data: any, action: string }) {
        if (data.action) {
            this.onRowAction(data);
        }
    }

    // ngOnDestroy() {
    //     this.pageState.currentPageState = {
    //         pageNo: this.currentPage,
    //         show: this.pageSize
    //     };
    // }
    // recoverLastPage() {
    //     const lastPage = this.pageState.currentPageState;

    //     if (lastPage) {

    //         this.currentPage = lastPage.pageNo;


    //         // this.config['remoteParams']['offset']=(lastPage.pageNo*lastPage.show)-lastPage.show
    //         // this.config['remoteParams']['limit']=lastPage.pageNo*lastPage.show
    //         // console.log(lastPage,"pagegeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",this.config,(lastPage.pageNo*lastPage.show)-lastPage.show,lastPage.pageNo*lastPage.show)
    //     }
    // }
}
