import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { User } from '@app/models';
import { InterfaceService } from '@app/interfaces';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    getUsers(id: any) {
        return this.http.get(this.getApiUrl(`user/${id}/get-user/`), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getCompanyData(id: any) {
        return this.http.get(this.getApiUrl(`user/${id}/get-company-profile/`), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    addUser(user) {
        return this.http.post(this.getApiUrl('user/'), JSON.stringify(user), this.getHttpOptions('json', false))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    changePassword(details) {
        return this.http.post(this.getApiUrl(`api-password-change/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    resetPassword(details) {
        return this.http.post(this.getApiUrl(`api-password-change/change_password/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    updateProfile(details, id) {
        return this.http.post(this.getApiUrl(`user/${id}/update-profile/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    verifyUser(data) {
        return this.http.post(this.getApiUrl(`user/${data['username']}/user-verification/`), JSON.stringify({ password: data['password'] }), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    // Update user details
    updateUser(id, user) {
        let url: string = this.getApiUrl(`user/${id}/`);
        return this.http.patch(url, JSON.stringify(user), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    deleteUser(id) {
        let url: string = this.getApiUrl(`admin-users/${id}/delete/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteRoles(id) {
        let url: string = this.getApiUrl(`roles/${id}`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    deactivate(data) {
        let url: string = this.getApiUrl(`admin-users/${data.id}/deactivate/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    activate(data) {
        let url: string = this.getApiUrl(`admin-users/${data.id}/activate/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    usernameCheck(data: { username: string }) {
        return this.http.post(this.getApiUrl(`user/check-username/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    mailCheck(data) {
        return this.http.post(this.getApiUrl(`user/check-mail/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    addUserApplications(user, applications) {
        return this.http.post(this.getApiUrl(`admin-users/${user}/add-user-applications/`), applications, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getUserApplications(user) {
        return this.http.get(this.getApiUrl(`admin-users/${user}/get-user-applications/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // getAllApplications(user) {
    //     return this.http.get(this.getApiUrl(`admin-users/${user}/get-user-applications/`), this.getHttpOptions('json'))
    //     .pipe(
    //         tap(Res => Res),
    //         catchError(this.handleError)
    //     );   
    // }

    // add customer details
    addCustomer(data) {
        return this.http.post(this.getApiUrl(`admin-users/add-customer/`), data, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    // Update customer details
    editCustomer(id, customer) {
        let url: string = this.getApiUrl(`admin-users/${id}/edit-customer/`);
        return this.http.post(url, customer, this.getHttpOptions('xml'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    deleteCustomer(data) {
        let url: string = this.getApiUrl(`admin-users/${data.id}/delete-customer/`);
        return this.http.post(url, data, this.getHttpOptions('xml'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    isDomainExists(domain) {
        return this.http.get(this.getApiUrl(`admin-users/${domain}/is-domain-exists/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    actionSelection(id, index) {
        return this.http.post(this.getApiUrl(`admin-users/${id}/add-action/`), index, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }


    //   editCustomer(id, customer) {
    //     let url: string = this.getApiUrl(`admin-users/${id}/edit-customer/`);
    //     return this.http.post(url, customer, this.getHttpOptions('json'))
    //         .pipe(
    //             tap(Res => Res),
    //             catchError(this.handleError)
    //         );
    // }

    // add plan details
    addPlans(data) {
        return this.http.post(this.getApiUrl(`plans/add-plans/`), data, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    editPlans(data) {
        return this.http.post(this.getApiUrl(`plans/edit-plans/`), data, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    // deletePlans(data) {
    //     return this.http.post(this.getApiUrl(`plans/delete-plans/`), data, this.getHttpOptions('json'))
    //     .pipe(
    //         tap(Res => Res),
    //         catchError(this.handleError)
    //     );   
    // }

    deletePlans(id) {
        let url: string = this.getApiUrl(`plans/${id}/delete-plans/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getPlans() {
        return this.http.get(this.getApiUrl(`plans/`), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    getPlansAndSub() {
        return this.http.get(this.getApiUrl(`plans/get-plan-and-subscription`), this.getHttpOptions('json'))
        .pipe(
            tap(Res => Res),
            catchError(this.handleError)
        );   
    }

    // delete customer
    // deleteCustomer(id) {
    //     let url: string = this.getApiUrl(`admin-users/${id}/delete-customer`);
    //     return this.http.get(url, this.getHttpOptions())
    //         .pipe(
    //             map(Response => Response),
    //             catchError(this.handleError)
    //         );
    // }

    viewPlanForCustomer(id: any) {
        return this.http.get(this.getApiUrl(`plans/${id}/get-plan-details/`), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
}

