import { Component, OnInit, ViewChild } from '@angular/core';
import { ApplicationService } from '@app/services';
import { SangliFlowDataRefresh } from "@app/features/layout/sangli-flow/handle-data-refresh";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { Toast, ToasterService } from 'angular2-toaster';
import { timer } from 'rxjs';
import { AuthService } from '@app/auth';
interface tourData {
    show_tour: boolean;
    application_finish: boolean;
    projects_finish: boolean;
    environment_finish: boolean;
    credentials_finish: boolean;
    pipeline_finish: boolean;
    latest_finish: number;
    completed_percentage: string;
}

@Component({
    selector: 'app-sangli-flow',
    templateUrl: './sangli-flow.component.html',
    styleUrls: ['./sangli-flow.component.scss']
})
export class SangliFlowComponent implements OnInit {

    showFloating = false;
    pageLoading = true;
    apiFail = false;
    data: tourData = null;
    application_id = null;
    animationApply = 0;
    intervel = null;
    completedPercentage: any = null;
    completedStepPercentage = '0%';
    completedStepNumber = 0;
    started = false;
    initialLoad = false;

    @ViewChild('application_dialog_header') application_dialog_header: any;

    constructor(
        private applicationService: ApplicationService,
        private sangliFlowDataRefresh: SangliFlowDataRefresh,
        private authService: AuthService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private toasterService: ToasterService
    ) {

    }

    ngOnInit() {
        this.getInitialData();
        this.sangliFlowDataRefresh.getRefreshData.subscribe(res => {
            if (res) {
                this.paramsHandle(true)
            }
        });
        this.paramsHandle();
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .do(event => event)
            .subscribe((event: NavigationEnd) => {
                timer(1000).subscribe(res => {
                    this.paramsHandle(true);
                });
            });
    }

    refreshData() {
        this.animationApply = this.completedStepNumber;
        this.pageLoading = true;
        this.getInitialData(true);
    }

    paramsHandle(refreshData = false) {
        this.activatedRoute.children.forEach(ele => {
            ele.params.subscribe((params: Params) => {
                this.application_id = params['id'];
                if (refreshData) {
                    this.refreshData();
                }
            });
        });
        this.activatedRoute.parent.params.subscribe(params => {
            if ('id' in params) {
                this.application_id = params['id'];
                if (refreshData) {
                    this.refreshData();
                }
            }
            this.activatedRoute.params.subscribe(subParams => {
                if ('id' in subParams) {
                    this.application_id = subParams['id'];
                    if (refreshData) {
                        this.refreshData();
                    }
                }
            });
        });
    }

    getInitialData(animation = false) {
        if (!this.started && this.authService.authenticated()) {
            this.started = true;
            this.applicationService.getTourData({ "application_id": this.application_id }).subscribe(
                (res: tourData) => {
                    this.data = res;
                    if (!this.initialLoad && !this.data.application_finish) {
                        this.initialLoad = true;
                        this.showFloating = true;
                    }
                    this.completedStep(animation);
                    if (this.intervel) {
                        clearInterval(this.intervel);
                    }
                    this.intervel = setInterval(() => {
                        this.animationApply += 1;
                        if (this.animationApply === 5) {
                            clearInterval(this.intervel);
                        }
                    }, 500);
                    this.pageLoading = false;
                },
                error => {
                    this.apiFail = true;
                }
            );
        }
    }

    toggleFloating() {
        this.showFloating = !this.showFloating;
        this.animationApply = 0;
        this.getInitialData();
    }

    navigate(flag) {
        switch (flag) {
            case 'application':
                this.application_dialog_header.open_modal();
                break;
            case 'project':
                this.router.navigate([`/applications/${this.application_id}/projects/list`]).then(Res => {
                    setTimeout(() => {
                        this.sangliFlowDataRefresh.openOrCloseProjectModal(true);
                    }, 100);
                });
                break;
            case 'environment':
                this.router.navigate([`/applications/${this.application_id}/projects/list`]).then(Res => {
                    this.applicationService.getProjectLength({ 'type': 'all' }, this.application_id).subscribe(
                        (res: { projects_length: number, type: any }) => {
                            if (res.projects_length === 0) {
                                this.toasterService.pop(<Toast>{
                                    type: 'info',
                                    title: "",
                                    body: "Need to add project to set environment",
                                });
                            } else {
                                setTimeout(() => {
                                    this.sangliFlowDataRefresh.openOrCloseEnvironmetModal(true);
                                }, 100);
                            }
                        }
                    );
                });
                break;
            case 'credentials':
                this.router.navigate([`/applications/${this.application_id}/settings/credentials/image-config`]).then(Res => Res);
                break;
            case 'pipeline':
                this.router.navigate([`/applications/${this.application_id}/pipeline/list`]).then(Res => {
                    timer(100).subscribe(res => {
                        this.sangliFlowDataRefresh.openOrClosePipelineModal(true);
                    });
                });

                break;
            default:
                break;
        }
        // this.showFloating = false;
    }

    getModal(event) {
        this.getInitialData();
    }

    completedStep(animation) {
        if (animation) {
            const duration = (this.data.completed_percentage === '0%' ? '0.4s' : this.data.completed_percentage === '20%' ? '0.8s' : this.data.completed_percentage === '40%' ? '1.2s' : this.data.completed_percentage === '80%' ? '1.6s' : this.data.completed_percentage === '100%' ? '2.0s' : '1s');
            this.completedPercentage = this.sanitizer.bypassSecurityTrustStyle('--w:' + this.data.completed_percentage + ';--duration:' + duration + ';--startingFrom:' + this.completedStepPercentage);
            this.completedStepPercentage = this.data.completed_percentage;
            this.completedStepNumber = this.data.latest_finish;
            this.started = false;
        } else {
            this.completedStepUpdate();
        }
    }
    completedStepUpdate() {
        this.completedPercentage = this.sanitizer.bypassSecurityTrustStyle('--w:' + this.data.completed_percentage + ';--duration:' + (this.data.completed_percentage === '0%' ? '0.3s' : this.data.completed_percentage === '20%' ? '0.6s' : this.data.completed_percentage === '40%' ? '0.9s' : this.data.completed_percentage === '80%' ? '1.2s' : this.data.completed_percentage === '100%' ? '1.5s' : '1s'));
        this.completedStepPercentage = this.data.completed_percentage;
        this.completedStepNumber = this.data.latest_finish;
        this.started = false;
    }
}
