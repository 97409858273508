import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgSwitch } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
// ngx-datatable

//--
import { InterfaceComponent } from '@app/interfaces';
import { Column, Config, RowLinkClickEvent, RowHighlight } from './interfaces';
import { GetApiurl } from '@app/core';
import { InterfaceService } from '@app/interfaces';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApplicationService } from '@app/services';
import { ToasterService, Toast } from 'angular2-toaster';
import { DatatableService } from '@app/services/datatable.service';
declare var $: any;

@Component({
    selector: 'app-datatable',
    templateUrl: './app-datatable.component.html',
    styleUrls: ['./app-datatable.component.scss'],
    providers: [
        NgSwitch,
        InterfaceService
    ]
})
export class AppDatatableComponent extends InterfaceComponent implements OnInit {
    _config: Config = {
        externalPaging: true,
        externalSorting: true,
        remoteUrl: null,
        remoteParams: {},
        pageSize: 20,
        commonFilter: true,
        commonFilterPlaceholder: 'Search',
        columnMode: 'force',
        statusSteps: [],
        navigateUrl: null
    };
    _columns: Column[] = [];
    _rows: Object[] = [];
    _page: {
        pageIndex: number;
        size: number;
        totalElements: number;
    } = {
            pageIndex: 0,
            size: 20,
            totalElements: 0
        };

    _rowHighlight: RowHighlight = {
        className: null,
        propertyName: null,
        checkValue: null
    };
    // Filter
    commonFilterText: Subject<string> = new Subject<string>();

    loading: boolean = false;
    application_type;

    default_open: any = '';
    app_id: number;

    // Events
    @Output('linkClicked') linkClicked: EventEmitter<RowLinkClickEvent> = new EventEmitter();
    @Output('expandable') expandable: EventEmitter<RowLinkClickEvent> = new EventEmitter();

    remoteQueryParamsAliases = {
        offset: "offset",
        limit: "limit",
        sort: "sort",
        order: "_order",
        filter: 'data'
    };
    offset = 0

    @ViewChild('statusIcon') statusIcon: any;
    status_config;

    @ViewChild('dataTable') dataTable: any;
    _subTableColumns: Column[] = [];
    _subTableRows: Object[] = [];
    det;

    _subTableConfig: Config = {
        externalPaging: true,
        externalSorting: true,
        remoteUrl: null,
        remoteParams: {},
        pageSize: 20,
        commonFilter: true,
        commonFilterPlaceholder: 'Search',
        columnMode: 'force',
        statusSteps: [],
        navigateUrl: null
    };
    constructor(
        private http: HttpClient,
        private interfaceService: InterfaceService,
        private router: Router,
        private toasterService: ToasterService,
        private applicationService: ApplicationService,
        private dataTableService: DatatableService,
        private route: ActivatedRoute
    ) {
        super();
        this.route.params.subscribe((params: Params) => {
            this.app_id = params['id'];
        });
    }

    ngOnInit() {
        // Filter changes listener
        this.commonFilterText
            .map((event: any) => event.target.value)
            .debounceTime(1000)
            .distinctUntilChanged()
            .flatMap((search) => {
                return Observable.of(search);
            }).subscribe(text => {
                this.onCommonFilter(text);
            });
    }
    ngAfterViewInit() {
        this.openDefault();
    }

    openDefault() {
        if (this.expand === 'SUBTABLE' || this.expand === 'APPS' || this.expand === 'PROJECTS') {
            if (this.expand === 'APPS') {
                this.dataTableService.defaultOpenRowApp.subscribe(data => {
                    this.default_open = data;
                });
            }
            else if (this.expand === 'PROJECTS') {
                this.dataTableService.defaultOpenRowProject.subscribe(data => {
                    this.default_open = data;
                });
            }
            if (this.default_open) {
                this.rowExtend();
            }
            else {
                this.rowZeroExtend();
            }
        }
    }
    rowZeroExtend() {
        if (!this.dataTable._rows[0]) {
            setTimeout(() => {
                this.rowZeroExtend();
            }, 100);
        }
        else {
            this.application_type = this.dataTable._rows[0].application_type;
            this.dataTable.rowDetail.toggleExpandRow(this.dataTable._rows[0]);
        }
    }
    rowExtend() {
        if (!this.dataTable._rows[0]) {
            setTimeout(() => {
                this.rowExtend();
            }, 100);
        }
        else {
            if (this.default_open['app_id']) {
                if (this.default_open['app_id'] === this.app_id) {
                    this.application_type = this._rows[this.default_open['index']]['application_type'];
                    this.dataTable.rowDetail.toggleExpandRow(this.dataTable._rows[this.default_open['index']]);
                } else {
                    this.rowZeroExtend();
                }
            } else {
                this.application_type = this._rows[this.default_open]['application_type'];
                this.dataTable.rowDetail.toggleExpandRow(this.dataTable._rows[this.default_open]);
            }

        }
    }
    //Sets column definitions
    @Input('config')
    set config(config: Config) {
        if (config instanceof Object) this._config = Object.assign(this._config, config);

        this._page.size = this._config.pageSize;
        this.setPage({ offset: 0, limit: this._page.size });
    }

    //Sets column definitions
    @Input('columns')
    set columns(cols: Column[]) {
        if (cols instanceof Array) {
            let _cols = [];

            cols.forEach(col => {
                _cols.push(col);
            })

            this._columns = _cols;
        }
    }

    //Sets table data
    @Input('rows')
    set rows(rows: Object[]) {
        if (rows instanceof Array) {
            let _rows = [];
            this._rows = _rows;
        }
    }

    //Sets sub table column definitions
    @Input('subTableColumns')
    set subTableColumns(cols: Column[]) {
        if (cols instanceof Array) {
            let _cols = [];

            cols.forEach(col => {
                _cols.push(col);
            })

            this._subTableColumns = _cols;
        }
    }

    // Select ACTIONS or SUBTABLE as table dropdown
    @Input('expand') expand: String;

    // Set style for particular table root level
    @Input('ngx-custom-class') ngx_custom_class: String = "";

    @Input('deploy-process') deploy_process: boolean = false;

    @Input('image-process') image_process: boolean = false;

    selectOption(option = null) {
        if (option != null) {
            this._config.remoteParams['option'] = option;
            this.setPage({ offset: 0, limit: this._page.size });
            // this.fetchData();
        }
    }



    // getRowClass(row) {
    //   if(row[this._rowHighlight.propertyName] === this._rowHighlight.checkValue)
    //     return this._rowHighlight.className
    // }

    getRowClass(row) {
        if (row['status'] === 'InActive')
            return 'status-inactive'
    }

    // Publishes row link clicks 
    onLinkClick(action: string, row: Object) {
        this.linkClicked.emit({ action: action, data: row });
    }

    // Expandable row click
    onExpandRow(row, expanded) {
        this.expandable.emit({ action: expanded, data: row });
    }

    // Gets current page number
    getPageNumber(): number {
        return this._page.pageIndex;
    }

    // On page change
    setPage(pageInfo: {
        count?: number,
        limit: number,
        offset: number,
        pageSize?: number
    }) {
        this.loading = true;
        this._page.pageIndex = pageInfo.offset;
        var offset = pageInfo.offset * pageInfo.limit
        this._config.remoteParams[this.remoteQueryParamsAliases.offset] = offset;
        this._config.remoteParams[this.remoteQueryParamsAliases.limit] = pageInfo.limit + offset;
        this.fetchData();
    }

    // On sorting
    onSort(event: any) {
        this.loading = true;

        this._config.remoteParams[this.remoteQueryParamsAliases.sort] = event.sorts[0].prop;
        this._config.remoteParams[this.remoteQueryParamsAliases.order] = event.sorts[0].dir;

        this.fetchData();
    }

    // On global filtering
    onCommonFilter(text: string) {
        this._config.remoteParams[this.remoteQueryParamsAliases.filter] = text;

        this.fetchData();
    }
    onLoad() {
    }
    // Fetches data from API
    fetchData() {
        let status = [];
        if (!this._config.remoteUrl) return;

        const url = GetApiurl(this._config.remoteUrl, this._config.remoteParams);
        if (this.subscriptions['dataFetch']) { this.subscriptions['dataFetch'].unsubscribe(); }

        this.subscriptions['dataFetch'] = this.http.get<Object[]>(url, this.interfaceService.getHttpOptions())
            .pipe(catchError(E => { console.log(E); return E }))
            .subscribe(
                (data: Object[]) => {
                    this._rows = data['records'];
                    this._page.totalElements = data['totalRecords'];// :ToDo: data.length;

                    delete this.subscriptions['dataFetch'];
                    setTimeout(() => { this.loading = false; }, 1500);
                    // this.loading = false;
                },
                Error => {
                    this._rows = [];
                    delete this.subscriptions['dataFetch'];
                    this.loading = false;
                });
    }

    isStepCompleted(step, row) {
        return this._config.statusSteps.indexOf(row['stage']) >= this._config.statusSteps.indexOf(step);
    }
    isStepSkipped(step, row) {
        if (step == row['skipped_stage'] && row['is_skipped'] == true) {
            return true;
        }
        else if (step !== 'Jenkins' && row['skipped_stage'] == 'ReviewBoard-SonarQube') {
            return true;
        }
    }
    onNavigate(event) {
        if (event.stage != "Jenkins") {
            let url = this._config.navigateUrl.replace(":branch_id", event.id);

            url = url.replace(":app_id", event.project.application_id);
            this.router.navigate([url.replace(":project_id", event.project.id)]);
        }
        else
            this.router.navigate(["/branch/" + event.id + "/overview"]);
    }


    // Fetches data from API (subTable)
    fetchSubTableData(row, config, expanded) {
        if (expanded) {
            this.dataTable.rowDetail.collapseAllRows();
            return;
        }

        this._subTableConfig = Object.assign(this._subTableConfig, config);
        let url = GetApiurl(this._subTableConfig.remoteUrl, this._subTableConfig.remoteParams);

        if (this.subscriptions['dataFetch']) this.subscriptions['dataFetch'].unsubcribe();

        this.subscriptions['dataFetch'] = this.http.get<Object[]>(url, this.interfaceService.getHttpOptions())
            .pipe(catchError(E => { console.log(E); return E }))
            .subscribe(
                (data: Object[]) => {
                    this._subTableRows = data['records'];
                    this.dataTable.rowDetail.collapseAllRows();
                    if (this._subTableRows.length != 0)
                        this.dataTable.rowDetail.toggleExpandRow(row);
                    // this._page.totalElements = data['totalRecords'];// :ToDo: data.length;

                    delete this.subscriptions['dataFetch'];
                    setTimeout(() => { this.loading = false; }, 1500);
                    // this.loading = false;
                },
                Error => {
                    this._subTableRows = [];
                    delete this.subscriptions['dataFetch'];
                    this.loading = false;
                });
    }

    onRowAction(event: RowLinkClickEvent) {
        if (event.action == 'list') {
            let url = '/applications/' + event.data['id'] + '/projects/list';
            this.router.navigate([url])
        }
        else if (event.action == 'dashboard') {
            this.router.navigate(['/applications/' + event.data['id'] + '/dashboard'])
        }
        else if (event.action == 'review') {
            this.image_process = true;
            this.applicationService.check_registry_details(event.data['id']).subscribe(
                (res) => {
                    if (res) {
                        this.router.navigate(['/applications/' + event.data['id'] + '/dependency']);
                    }
                    else {
                        this.toasterService.pop(<Toast>{
                            type: 'info',
                            title: 'Configuration is not finished. Click configuration button to configure'
                        });
                    }
                    this.image_process = false;
                },
                error => {
                    this.image_process = false;
                    this.toasterService.pop(<Toast>{
                        type: 'error',
                        title: error['detail']
                    });
                }
            );
        }
        else if (event.action == 'config') {
            this.router.navigate(['/applications/' + event.data['id'] + '/configuration'])
        }
        else if (event.action == 'deploy') {
            this.deploy_process = true;
        }
        else if (event.action == 'edit') {
            this.router.navigate(['/applications/' + event.data['id'] + '/edit'])
        }
        else if (event.action == 'delete') {
            this.linkClicked.emit({ action: event.action, data: event.data });
        }
    }
    onActivate(data) {
        if (data['type'] == 'click') {
            this.application_type = data.row.application_type;
            const validate = data['rowElement']['attributes'][3].value;
            if (this.expand === 'APPS') {
                let index = this._rows.findIndex(x => x['id'] === data.row.id)
                this.dataTableService.assignValueApp(index);
            } else if (this.expand === 'PROJECTS') {
                let index = this._rows.findIndex(x => x['id'] === data.row.id)
                let val = { 'app_id': this.app_id, 'index': index }
                this.dataTableService.assignValueProject(val);
            }
            if (validate == 'false') {
                this.dataTable.rowDetail.collapseAllRows();
                this.dataTable.rowDetail.toggleExpandRow(data['row']);
            }
            else {
                this.dataTable.rowDetail.toggleExpandRow(data['row']);
            }
        }
    }
}
