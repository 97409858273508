import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { InterfaceService } from '@app/interfaces';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { authTokenId, GetApiurl } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class SupportService extends InterfaceService {
  constructor(private http: HttpClient) {
    super();
  }

  support_list(status, date) {
    return this.http.get(this.getApiUrl(`tickets/`, { 'status': status, 'date': date }), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  getPortalAccess(ticketId:any){
    return this.http.post(this.getApiUrl(`tickets/get-portal-access/`), JSON.stringify(ticketId), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  customer_support_list(status) {
    return this.http.get(this.getApiUrl(`tickets/`, { 'status': status }), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  newTicketCreate(data: any) {
    return this.http.post(this.getApiUrl(`tickets/`), JSON.stringify(data), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  uploadTicketFile(file: File, index: number, ticketId: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('file_index', index.toString());
    formData.append('ticketId', ticketId.toString());

    return this.http.post(this.getApiUrl(`tickets/upload/`), formData, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Authorization': `JWT ${localStorage.getItem(authTokenId)}` }
    });
  }

  getTicketDetails(id: any) {
    return this.http.get(this.getApiUrl(`tickets/${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  getSupportUser() {
    return this.http.get(this.getApiUrl(`tickets/get-support-user/`), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  getSupportHistory(ticketId: any) {
    return this.http.get(this.getApiUrl(`tickets/ticket-history/?ticket_id=${ticketId}`), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  detelteSendMessage(id: any) {
    return this.http.delete(this.getApiUrl(`tickets/delete-comment/?id=${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  deleteUploadFile(id: any) {
    return this.http.delete(this.getApiUrl(`tickets/comment-file-delete/?id=${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );

  }

  editTicketDetails(id: any, payload: any) {

    return this.http.put(this.getApiUrl(`tickets/${id}`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );

  }

  deleteTicketUploadFile(id: any) {
    return this.http.delete(this.getApiUrl(`tickets/file-delete/?id=${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );

  }


  updateSendMessage(id: any, payload: any) {
    return this.http.post(this.getApiUrl(`tickets/update-comment/?id=${id}`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  changeAccessPortal(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/create-support-user/`), JSON.stringify(payload), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  uploadCommentFiles(file: File, index: number, ticketId: number): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('file_index', index.toString());
    formData.append('ticket_comment_id', ticketId.toString());

    return this.http.post(this.getApiUrl(`tickets/comment-file-upload/`), formData, {
      reportProgress: true,
      observe: 'events',
      headers: { 'Authorization': `JWT ${localStorage.getItem(authTokenId)}` }
    });
  }


  getMessageDetails(id: any) {
    return this.http.get(this.getApiUrl(`tickets/get-comment/?ticket_id=${id}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );

  }

  addNewComment(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/add-comment/`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  changeTicketStatus(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/status-change/`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

  changeTicketAssignee(payload: any) {
    return this.http.post(this.getApiUrl(`tickets/assignee-change/`), JSON.stringify(payload), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }


  getCustomerPortal(portalId: any) {
    return this.http.get(this.getApiUrl(`tickets/view-access-portal/?customer_portal_id=${portalId}`), this.getHttpOptions('json'))
      .pipe(
        tap(Response => Response),
        catchError(this.handleError)
      );
  }

}