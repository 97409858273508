import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class DockerService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }
    verify_registry(data) {
        return this.http.post(this.getApiUrl(`application/verify-registry/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }
    
    getExistingRegistryData(id) {
        const url = `application/${id}/get-existing-registry-data/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    deletedata(id, reg_id) {
        const url = this.getApiUrl(`application/${id}/delete-data/`, {'id': reg_id});
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    deleteselected(id) {
        const url = this.getApiUrl(`application/${id}/delete/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
}