import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@app/auth';
import { LayoutComponent } from './layout/layout.component';
import { AuthForwardService } from '@app/auth';


const featureRoutes: Routes = [
    {
        path: '',
        loadChildren: './landing-page/landing-page.module#LandingPageModule'
    },
    {
        path: 'branch/:branch_id',
        // component: LayoutComponent,
        canActivate: [AuthGuardService],
        data: {
            breadcrumbs: 'Branch'
        },
        children: [
            { path: '', loadChildren: './home/home.module#HomeModule', },
        ],
        // resolve: { project: BranchResolver }
    },
    {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule'
    },
    {
        path: 'applications',
        component: LayoutComponent,
        loadChildren: '@app/features/home/applications/applications.module#ApplicationsModule',
        canActivate: [AuthGuardService],
        data: { breadcrumbs: 'Applications' }
    },
    // {
    //     path: 'applications/:id/projects',
    //     component: LayoutComponent,
    //     loadChildren: '@app/features/home/projects/projects.module#ProjectsModule',
    //     canActivate: [AuthGuardService],
    //     data: { breadcrumbs: 'Project' }
    // },
    { 
        path: '**', 
        redirectTo: 'error'
    }

];


export const featuresRouting = RouterModule.forRoot(featureRoutes);

