import {
    Component,
    OnInit,
    ElementRef,
    Output,
    EventEmitter,
    Input,
    HostListener,
    ChangeDetectorRef
} from '@angular/core';
import {chatBot} from '../chatBot/chat';
import {GetApiurl} from '@app/core/settings/config';
import {getStringValue} from './JsonStringFile';
declare var $: any;

@Component({
    selector: 'app-custom-help-icon',
    templateUrl: './custom-help-icon.component.html',
    styleUrls: ['./custom-help-icon.component.scss']
})
export class CustomHelpIconComponent implements OnInit {

    showHelpIcon = false;
    idName: any = '';
    placement = 'right';
    divClass: string = null;
    value: string[] = [];
    image_path = 'assets/img/help.png';
    // image_path = 'assets/img/help_white.png';

    @Output() outputData: EventEmitter<any> = new EventEmitter();

    constructor(
        private elRef: ElementRef,
        // tslint:disable-next-line:no-shadowed-variable
        private chatBot: chatBot,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
    }

    @Input()
    set helpIconName(data) {
        if (data) {
            this.idName = data;
            this.value = getStringValue(data);
        }
    }

    @Input()
    set placementPosition(data) {
        if (data) {
            this.placement = data;
        }
    }

    @Input()
    set customClass(data) {
        if (data) {
            this.divClass = data;
        }
    }

    @Input()
    set setWhiteIcon(data) {
        if (data) {
            this.image_path = 'assets/img/help_white.png';
        }
    }

    @HostListener('click', ['$event']) onClick() {
        const link = this.idName + 'link';
        if (event.target['id'] === link) {
            const id = '#' + this.idName;
            const el = this.elRef.nativeElement.querySelector(link);
            $(id).popover('hide');
            this.chatbot(el, id);
        }
    }

    popoverFun() {
        // const originalLeave = $.fn.popover.Constructor.prototype.leave;
        // $.fn.popover.Constructor.prototype.leave = function (obj) {
        //     const self = obj instanceof this.constructor ?
        //         obj : $(obj.currentTarget)[this.type](this.getDelegateOptions()).data('bs.' + this.type);
        //     let container, timeout;
        //     originalLeave.call(this, obj);
        //     if (obj.currentTarget) {
        //         container = $(obj.currentTarget).siblings('.popover');
        //         timeout = self.timeout;
        //         container.one('mouseenter', function () {
        //             // We entered the actual popover – call off the dogs
        //             console.log("mouse enter")
        //             clearTimeout(timeout);
        //             // Let's monitor popover content instead
        //             container.one('mouseleave', function () {
        //                 $.fn.popover.Constructor.prototype.leave.call(self, self);
        //                 console.log("mouseleave")
        //                 clearTimeout(timeout);
        //             });
        //         });
        //     }
        // };
        Array.from($('[data-toggle="popover"]')).forEach(item => {
            const id = '#' + item['id'];
            const value = $(id + 'value').html();
            $(item).popover({
                html: true,
                content: value,
                trigger: 'hover',
                placement: this.placement,
                delay: {show: 50, hide: 100}
            });
        });
        this.showHelpIcon = true;
        return true;
    }

    chatbot(e, id) {
        const url = GetApiurl('disable');
        if (url === 'https://redington.devops5d.com/backend/disable') {
            document.getElementById('chatbot-btn').style.display = 'none';
        } else if (url !== 'https://redington.devops5d.com/backend/disable') {
            this.chatBot.triggerBot(id);
        }
    }
    // tslint:disable-next-line:use-life-cycle-interface
    public ngDoCheck() {
        this.cdRef.detectChanges();
    }

}
