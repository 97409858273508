import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderBallBeatComponent } from './loader-ball-beat/loader-ball-beat.component';
import { LoaderWaveComponent } from './loader-wave/loader-wave.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    LoaderBallBeatComponent,
    LoaderWaveComponent
  ],
  exports: [
    LoaderBallBeatComponent,
    LoaderWaveComponent
  ]
})
export class ElementsModule { }
