import { Component, Input, OnInit } from '@angular/core';
import { SettingsService, TOASTERCONFIG } from '@app/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UserService } from '@app/services';
import { ToasterService, Toast } from 'angular2-toaster';
import { GetApiurl, authTokenId } from '../../../core/settings/config';
import { ValidationService } from '@app/shared/directives';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { AuthService } from '@app/auth';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { WhatsappService } from '@app/services/whatsapp.service';


declare var $: any;
declare var jQuery: any;

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

    user: any;
    imageSrc: any = 'assets/img/default-user.png';
    upload_file: any;
    password_build_form: FormGroup;
    account_form: FormGroup;
    passwordForm: FormGroup;
    mobileNumberForm: FormGroup;
    mobileNumberUpdateForm: FormGroup;
    show_form = false;
    settingProcess = false;
    passProcess = false;
    image_process = false;
    toasterConfig = TOASTERCONFIG;
    method = 'POST';
    edit_id: any;
    whichOne: String = 'profile';
    image: any;
    application_id: any;
    process = false;
    form_enabled = false;
    password_view = { current: false, newPassword: false, confirm: false };
    DateTime = new Date();

    pass_valid = [];
    isEdit: boolean = false;
    save_enable: boolean = false;
    psswrdboolean: boolean = false;
    reg_num = '1';
    enabled_users = [];
    verified_user = [];
    verified_mobile: any;
    verifyBoolean: boolean = false;
    verifyUpdateBoolean: boolean = false;
    verifyButton: boolean = false;
    updateVerifyButton: boolean = false;
    otpValue = [];
    mobileValue = [];
    updateBoolean: boolean = false;
    otpLoading: boolean = false;
    updateOtpLoading: boolean = false;
    // resendOtp: boolean = false;
    displayTimer: boolean = false;
    displayUpdateTimer: boolean = false;
    display: any;
    updateVerifyLoading: boolean = false;
    verifyLoading: boolean = false;
    enable_update_resend: boolean = false;
    enable_resend: boolean = false;
    timer: any;

    constructor(
        private router: Router,
        private settingsService: SettingsService,
        private fb: FormBuilder,
        private userService: UserService,
        private authService: AuthService,
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private whatsappService: WhatsappService
    ) {
        const password = new FormControl('', Validators.compose([Validators.required]));
        const certainPassword = new FormControl('', CustomValidators.equalTo(password));
        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe((params: Params) => {
            this.application_id = params['id'];
        });
        this.whatsappService.getRegUsers().subscribe(
            resp => {
                this.enabled_users = resp['users'];
            });
        this.settingsService.user$.subscribe(settings => {
            this.user = settings;
            this.buildWhatsappForm();
            this.updateWhatsappForm();
            if (this.user['firstName'] != null) {
                this.form_enabled = false;
                this.updateForm()
            }
            else {
                this.buildForm();
                this.buildWhatsappForm();
                this.updateWhatsappForm();
                this.form_enabled = true;
            }
            this.process = true;
            if (this.user['profile_image'] != null) {
                this.imageSrc = GetApiurl(this.user['profile_image'].substr(1)) + '?' + this.DateTime;
            }

            if (!this.user['isSuperUser'] && this.user['id']) {
                this.user['companyName'] = this.user['id'];
                this.userService.getCompanyData(this.user['id'])
                    .subscribe(resp => {
                        this.user['companyName'] = resp['company_name'];
                    });
            }

            this.edit_id = this.user['id'];
        });
        const body = document.querySelector("body");
        $('#update-number').on('hidden.bs.modal', () => {
            // body.style.overflow = "hidden";
            // const element = <HTMLElement>document.getElementById('profileModal');
            // element.style.overflow = 'auto';
        })
        $('#profileModal').on('show.bs.modal', () => {
            // $('body').css('overflow', 'hidden');
            body.style.overflow = "hidden";
        })
        $('#profileModal').on('hidden.bs.modal', () => {
            // $('body').css('overflow', 'auto');
            body.style.overflow = "auto";
        })

    }
    @Input('enabled')
    set enabled(data) {
        if (data == false) {
            this.form_enabled = false;
        }
    }

    open_profile_modal() {
        $('#profileModal').modal('show');
        this.account_form.disable();// diable profile modal form           

    }

    back() {
        $('#profileModal').modal('hide');
        this.test('profile')
        // this.isEdit = false;
        // this.account_form.disable(); 
        this.updateForm()
        this.password_build_form.reset();
        this.password_view = { current: false, newPassword: false, confirm: false };
        // this.form_enabled = false;

        let maildisabled: HTMLElement = document.getElementById("emailDisabled");
        if (maildisabled) {
            maildisabled['disabled'] = true;
            maildisabled.style.backgroundColor = '';
            maildisabled.style.color = '';
        }
        this.mobileNumberForm.reset();
        this.mobileNumberUpdateForm.reset();
        this.enable_resend = false;
        this.verifyButton = false;
        this.updateBoolean = false;
        this.enable_update_resend = false;
        this.verifyUpdateBoolean = false;
        this.displayUpdateTimer = false;
        this.displayTimer = false;
        this.verified_mobile = '';
        this.mobileValue = [];
        this.stopTimer();
        // this.whichOne = 'profile';

    }


    buildForm() {
        this.account_form = this.fb.group({
            'firstName': [this.user['firstName'], [Validators.required, Validators.maxLength(30), ValidationService.userNameValidator]],
            'lastName': [this.user['lastName'], [Validators.required, Validators.maxLength(100), ValidationService.userNameValidator]],
            'email': [this.user['email'], [Validators.required, ValidationService.emailValidator]],
            'mobile': [this.user['mobile'], [Validators.required, ValidationService.phoneValidator]],
            // 'companyName': [this.user['companyName'], Validators.required]
        });
        this.build_password_form();
    }
    buildWhatsappForm() {
        // if (typeof (this.verified_mobile) == 'string') {
        // this.mobileNumberForm = this.fb.group({
        //     'mobile': [{ value: this.verified_mobile, disabled: true }, [Validators.required, ValidationService.phoneValidator]],
        //     'otp': []
        // })
        // } else {
        //     this.mobileNumberForm = this.fb.group({
        //         'mobile': [this.verified_mobile, [Validators.required, ValidationService.phoneValidator]],
        //         'otp': []
        //     })
        // }
        this.mobileNumberForm = this.fb.group({
            'mobile': [this.verified_mobile, [Validators.required, ValidationService.phoneValidator]],
            'otp': []
        })
        this.verifyButton = false;
        this.updateVerifyButton = false;
    }

    editWhatsappForm() {
        // if (typeof (this.verified_mobile) == 'string') {
        this.mobileNumberForm = this.fb.group({
            'mobile': [{ value: this.verified_mobile, disabled: true }, [Validators.required, ValidationService.phoneValidator]],
            'otp': []
        })
        // } else {
        //     this.mobileNumberForm = this.fb.group({
        //         'mobile': [this.verified_mobile, [Validators.required, ValidationService.phoneValidator]],
        //         'otp': []
        //     })
        // }
        this.verifyButton = false;
        this.updateVerifyButton = false;
    }

    updateWhatsappForm() {
        this.mobileNumberUpdateForm = this.fb.group({
            'mobile': [this.verified_mobile, [Validators.required, ValidationService.phoneValidator]],
            'otp': []
        })
        this.verifyButton = false;
        this.updateVerifyButton = false;
    }

    updateForm() {
        this.account_form = this.fb.group({
            'firstName': [{ value: this.user['firstName'], disabled: true }, [Validators.required, Validators.maxLength(30), ValidationService.userNameValidator]],
            'lastName': [{ value: this.user['lastName'], disabled: true }, [Validators.required, Validators.maxLength(100), ValidationService.userNameValidator]],
            'email': [{ value: this.user['email'], disabled: true }, [Validators.required, ValidationService.emailValidator]],
            'mobile': [{ value: this.user['mobile'], disabled: true }, [Validators.required, ValidationService.phoneValidator]],
            // 'companyName': [{value:this.user['companyName'],disabled:true}, Validators.required]
        });
        this.build_password_form();
        // Validators.pattern(/[^\s]+/)
    }

    build_password_form() {
        this.password_build_form = this.fb.group({
            // 'current_password': ['', Validators.required],
            'passwordGroup': this.passwordForm
        });
        this.show_form = true;
    }

    test(flag) {
        this.verified_user = [];
        this.verified_mobile = [];
        this.updateForm()
        this.whichOne = flag;
        this.form_enabled = false;
        this.account_form.disable();
        this.isEdit = false;
        this.passwordForm.reset();
        this.mobileNumberForm.reset();
        this.mobileNumberUpdateForm.reset();
        this.enable_resend = false;
        this.verifyButton = false;
        this.updateBoolean = false;
        this.enable_update_resend = false;
        this.verifyUpdateBoolean = false;
        this.displayUpdateTimer = false;
        this.displayTimer = false;
        this.mobileValue = [];
        this.verified_mobile = '';
        this.updateOtpLoading = false;
        this.otpLoading = false;
        this.mobileNumberForm.get('mobile').enable();
        this.stopTimer();
        this.whatsappService.getRegUsers().subscribe(
            resp => {
                this.enabled_users = resp['users'];
                if (this.enabled_users.length > 0) {
                    let i = this.enabled_users.findIndex(x => x.user_id == this.user.id)
                    if (i != -1) {
                        this.verifyBoolean = true;
                        this.verified_user = this.enabled_users[i]['mobile'];
                        this.verified_mobile = this.verified_user.slice(2);
                        this.mobileValue = this.verified_mobile;
                        this.editWhatsappForm();
                    } else {
                        this.buildWhatsappForm();
                    }
                }
            });

    }

    uploadFile() {
        $('#image-input').trigger('click');
        this.form_enabled = false;
        this.isEdit = false;
        this.account_form.disable();
        let maildisabled: HTMLElement = document.getElementById("emailDisabled");
        maildisabled['disabled'] = true;
        maildisabled.style.backgroundColor = '';
        maildisabled.style.color = '';

    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.image_process = true;
            const file = event.target.files[0];
            this.upload_file = file;
            this.image = file.type;
            const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/gif'];
            if (allowed_types.includes(this.image)) {
                this.UploadImage().then(result => {
                    if (result) {
                        const reader = new FileReader();
                        reader.onload = e => this.imageSrc = reader.result;
                        reader.readAsDataURL(file);
                        // this.imageSrc = GetApiurl(result['data']['profile_image']);
                        result['data']['isAdmin'] = this.user['isAdmin']
                        result['data']['latest_app'] = this.user['latest_app']
                        this.settingsService.setUser(result['data']);
                        this.toasterService.pop(<Toast>{
                            type: 'success',
                            title: 'Your Profile has been Changed'
                        });
                        this.image_process = false;
                        this.form_enabled = false;
                        this.isEdit = false;
                        this.account_form.disable();
                        let maildisabled: HTMLElement = document.getElementById("emailDisabled");
                        maildisabled['disabled'] = true;
                        maildisabled.style.backgroundColor = '';
                        maildisabled.style.color = '';
                    }
                })
                    .catch(error => {
                        this.toasterService.pop(<Toast>{
                            type: 'error',
                            title: error
                        });
                        this.image_process = false;
                        this.form_enabled = false;
                        this.isEdit = false;
                        this.account_form.disable();
                        let maildisabled: HTMLElement = document.getElementById("emailDisabled");
                        maildisabled['disabled'] = true;
                        maildisabled.style.backgroundColor = '';
                        maildisabled.style.color = '';
                    });
            } else {
                if (this.image) {
                    let format = this.image.split('/')
                    this.toasterService.pop(<Toast>{
                        type: 'error',
                        title: `Image type not supported (.${format[1]} format)`
                    });
                } else {
                    this.toasterService.pop(<Toast>{
                        type: 'error',
                        title: `Image type not supported`
                    });
                }
                this.form_enabled = false;
                this.isEdit = false;
                this.account_form.disable();
                let maildisabled: HTMLElement = document.getElementById("emailDisabled");
                maildisabled['disabled'] = true;
                maildisabled.style.backgroundColor = '';
                maildisabled.style.color = '';
            }
        }
    }

    UploadImage(): Promise<any> {
        const url: any = GetApiurl(`user/${this.edit_id}/profile-image/`);
        const formData: FormData = new FormData();
        return new Promise((resolve, reject) => {
            formData.append('profile_image', this.upload_file, this.upload_file.name);
            formData.append('user_id', this.user['id']);
            const xhr = new XMLHttpRequest();
            xhr.open('post', url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem(authTokenId));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
        });
    }

    Changepassword() {
        this.passProcess = true;
        const form_data = this.password_build_form.value;
        const data = {
            'password': form_data['passwordGroup']['password']
        };
        this.userService.changePassword(data).subscribe(
            (res: object[]) => {
                this.passProcess = false;
                if (res['type'] === 'success') {
                    this.password_build_form.reset();
                    this.back();
                }
                this.toasterService.pop(<Toast>{
                    type: res['type'],
                    title: res['msg'],
                });
                this.psswrdboolean = false;
            },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error
                });
                this.passProcess = false;
                this.psswrdboolean = false;
            }
        );
        this.password_view = { current: false, newPassword: false, confirm: false };
    }
    updateProfile() {
        this.isEdit = true;
        this.form_enabled = true;
        this.account_form.enable();
        let maildisabled: HTMLElement = document.getElementById("emailDisabled");
        maildisabled['disabled'] = true;
        maildisabled.style.backgroundColor = '#9b9b9b85';
        maildisabled.style.color = '#0c0c0c4f';

    }
    hasFormChanged(place) {
        this.save_enable = false
        if (this.user.firstName != this.account_form.value.firstName || this.user.lastName != this.account_form.value.lastName || this.user.mobile != this.account_form.value.mobile) {
            this.save_enable = true
        }

    }

    submitProfile() {
        this.isEdit = false;
        this.settingProcess = true;
        this.userService.updateProfile(this.account_form.value, this.edit_id).subscribe(
            (res: object[]) => {
                res['data']['accesses'] = this.user['accesses']
                res['data']['isAdmin'] = this.user['isAdmin']
                res['data']['latest_app'] = this.user['latest_app']
                this.settingsService.setUser(res['data']);
                if (res['data']['profile_image'] != null) {
                    this.imageSrc = GetApiurl(res['data']['profile_image'].substr(1)) + "?" + Math.floor(Math.random() * 100) + 1;
                }
                this.user['first_name'] = res['data']['first_name']
                this.settingProcess = false;
                this.user['companyName'] = res['data']['companyName'];
                $('#profileModal').modal('hide')
                // $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
                // this.back();
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: 'Profile Saved',
                });
                this.form_enabled = false;
                this.account_form.disable();
                let maildisabled: HTMLElement = document.getElementById("emailDisabled");
                maildisabled['disabled'] = true;
                maildisabled.style.backgroundColor = '';
                maildisabled.style.color = '';


                // this.authService.redirectToHome();
            },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error
                });
                this.settingProcess = false;
            }
        );
    }

    onKeyUp(event) {
        if ($('popover-container')) {
            // $('popover-container').remove()
            $('popover-container').css('display', 'none')
        }
        let value = event.target.value;
        let number_exists = /\d/.test(value);
        this.pass_valid = [];
        // this.pass_valid[0]=false;
        if (value.length < 8) {
            this.pass_valid[0] = true;
        }
        // this.pass_valid[1]=false;
        if (value.search(/[0-9]/) < 0) {
            this.pass_valid[1] = true;
        }
        // this.pass_valid[2]=true;
        if (value.search(/[a-z]/) < 0) {
            this.pass_valid[2] = true;
        }
        // this.pass_valid[3]=true;
        if (value.search(/[A-Z]/) < 0) {
            this.pass_valid[3] = true;
        }
        // this.pass_valid[4]=true;
        if (value.search(/[!@#\$%\^&\*_]/) < 0) {
            this.pass_valid[4] = true;
        }
        if (value.length >= 50) {
            this.psswrdboolean = true;
        }
        else {
            this.psswrdboolean = false;
        }
    }
    onClick() {
        if ($('popover-container')) {
            // $('popover-container').remove()
            $('popover-container').css('display', 'none')
        }
        // if(document.getElementById('#popoverTemp')){
        //     document.getElementById('#popoverTemp').remove()
        // }
    }

    ngOnDestroy() {
        if ($('popover-container')) {
            // $('popover-container').remove()
            $('popover-container').css('display', 'none')
        }
        $('#profileModal').modal('hide')
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }

    sendOtp() {
        if (this.updateBoolean) {
            this.enable_update_resend = true;
            this.mobileNumberUpdateForm.get('mobile').disable();
            this.updateOtpLoading = true;
        } else {
            this.enable_resend = true;
            this.mobileNumberForm.get('mobile').disable();
            this.otpLoading = true;
        }
        let Payload = {
            "mobile": this.verified_mobile,
            "user_type": 1
        }
        this.whatsappService.otpGeneration(Payload).subscribe((response: any) => {
            if (response['status_code'] == 200) {
                if (this.updateBoolean) {
                    this.updateVerifyButton = true;
                } else {
                    this.verifyButton = true;
                }
                this.otpLoading = false;
                this.updateOtpLoading = false;
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "OTP generated successfully"
                });
            } else {
                this.otpLoading = false;
                this.updateOtpLoading = false;
                if (this.updateBoolean) {
                    this.displayUpdateTimer = false;
                } else {
                    this.displayTimer = false;
                }
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: response['message']
                });
            }

        });

    }

    verifyOtp() {
        this.verifyLoading = true;
        let Payload = {
            "mobile": this.verified_mobile,
            "otp": this.otpValue
        }
        this.whatsappService.otpVerification(Payload).subscribe(response => {
            this.verifyLoading = false;
            if (response['status_code'] == 200) {
                if (this.updateBoolean) {
                    this.updateVerifyButton = true;
                    this.updateWhatsappForm();
                    this.verifyUpdateBoolean = true;
                    this.displayUpdateTimer = false;
                } else {
                    this.verifyButton = true;
                    this.buildWhatsappForm();
                    this.verifyBoolean = true;
                    this.displayTimer = false;
                }
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "otp verified successfully"
                });
            } else {
                this.verifyLoading = false;
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: response['message']
                });
            }
        });

    }

    verifyOtpUpdateNumber() {
        this.updateVerifyLoading = true;
        let Payload = {
            "mobile": this.verified_mobile,
            "otp": this.otpValue
        }
        this.whatsappService.otpVerificationUpdate(Payload).subscribe(response => {
            if (response['status_code'] == 200) {
                this.updateVerifyLoading = false;
                if (this.updateBoolean) {
                    this.updateVerifyButton = true;
                    this.updateWhatsappForm();
                    this.verifyUpdateBoolean = true;
                    this.displayUpdateTimer = false;
                } else {
                    this.verifyButton = true;
                    this.buildWhatsappForm();
                    this.verifyBoolean = true;
                    this.displayTimer = false;
                }
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "otp verified successfully"
                });
                this.close();
                this.stopTimer();
            } else {
                this.updateVerifyLoading = false;
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: response['message']
                });
            }
        });
    }

    close() {
        this.updateBoolean = false;
        this.whatsappService.getRegUsers().subscribe(
            resp => {
                this.enabled_users = resp['users'];
                if (this.enabled_users.length > 0) {
                    let i = this.enabled_users.findIndex(x => x.user_id == this.user.id)
                    if (i != -1) {
                        this.verifyBoolean = true;
                        this.verified_user = this.enabled_users[i]['mobile'];
                        this.verified_mobile = this.verified_user.slice(2);
                        this.mobileValue = this.verified_mobile;
                        this.buildWhatsappForm();
                    }
                }
            });
        this.mobileNumberUpdateForm.reset();
        this.stopTimer();
    }

    otpEntered(event) {
        this.otpValue = event.target.value;
    }

    numberEntered(event) {
        this.mobileValue = event.target.value;
        this.verified_mobile = this.mobileValue;
    }

    updatemob() {
        this.verified_mobile = '';
        this.mobileValue = [];
        this.mobileNumberUpdateForm.controls['mobile'].setValue('');
        this.mobileNumberUpdateForm.get('mobile').enable();
        this.verifyUpdateBoolean = false;
        this.updateBoolean = true;
    }

    start() {
        this.reSendOtp();
        if (this.updateBoolean) {
            this.displayUpdateTimer = true;
            this.mobileNumberUpdateForm.get('mobile').disable();
        } else {
            this.displayTimer = true;
            this.mobileNumberForm.get('mobile').disable();
        }
        let minute = 2;
        let seconds = minute * 60;
        let textSec: any = '0';
        let statSec = 60;
        const prefix = minute < 10 ? '0' : '';
        this.timer = setInterval(() => {
            seconds--;
            if (statSec != 0) statSec--;
            else statSec = seconds - 1;
            if (statSec < 10) {
                textSec = '0' + statSec;
            } else {
                textSec = statSec;
            }
            this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

            if (seconds == 0) {
                clearInterval(this.timer);
                if (this.updateBoolean) {
                    this.displayUpdateTimer = false;
                    this.mobileNumberUpdateForm.get('mobile').enable();
                } else {
                    this.displayTimer = false;
                    this.mobileNumberForm.get('mobile').enable();
                }
            }
        }, 1000);
    }

    stopTimer() {
        clearInterval(this.timer);
        this.displayUpdateTimer = false;
        this.displayTimer = false;
        this.display = '01:59'
        this.enable_update_resend = false;
        this.enable_resend = false;
    }

    reSendOtp() {
        if (this.updateBoolean) {
            this.enable_update_resend = true;
            this.mobileNumberUpdateForm.get('mobile').disable();
        } else {
            this.enable_resend = true;
            this.mobileNumberForm.get('mobile').disable();
        }
        let Payload = {
            "mobile": this.verified_mobile,
            "user_type": 1
        }
        this.whatsappService.otpGeneration(Payload).subscribe((response: any) => {
            if (response['status_code'] == 200) {
                if (this.updateBoolean) {
                    this.updateVerifyButton = true;
                } else {
                    this.verifyButton = true;
                }
                this.toasterService.pop(<Toast>{
                    type: 'success',
                    title: "OTP resended successfully"
                });
            } else {
                if (this.updateBoolean) {
                    this.displayUpdateTimer = false;
                } else {
                    this.displayTimer = false;
                }
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: response['message']
                });
            }

        });

    }

    wrongNumber() {
        if (this.enable_resend) {
            this.verifyBoolean = false;
            this.enable_resend = false;
            this.verified_mobile = '';
            this.mobileValue = [];
            this.mobileNumberForm.reset();
            this.mobileNumberForm.get('mobile').enable();
            this.verifyButton = false;
            this.displayUpdateTimer = false;
            this.displayTimer = false;
            this.otpLoading = false;
        } else {
            this.verifyUpdateBoolean = false;
            this.enable_update_resend = false;
            this.verified_mobile = '';
            this.mobileValue = [];
            this.mobileNumberUpdateForm.reset();
            this.mobileNumberUpdateForm.get('mobile').enable();
            this.updateVerifyButton = false;
            this.displayUpdateTimer = false;
            this.displayTimer = false;
            this.updateOtpLoading = false;
        }
        this.stopTimer();
    }

}
