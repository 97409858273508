export class Application {
    id?: number;
    application_name = '';
    application_type: number;
    description = '';
    registry_url = '';
    username = '';
    password = '';
    registry_type: number;
    registry_type_in_word: number;

    constructor(data?: Object) {
        if (!(data instanceof Object)) { data = {}; }
        this.id = data['id'] || this.id;
        this.application_name = data['application_name'] || this.application_name;
        this.description = data['description'] || this.description;
        this.registry_type = data['registry_type'] || this.registry_type;
        this.registry_url = data['registry_url'] || this.registry_url;
        this.username = data['username'] || this.username;
        this.password = data['password'] || this.password;
        this.application_type = data['application_type'] || this.application_type;
        this.registry_type_in_word = data['registry_type_in_word'] || this.registry_type_in_word;
    }
}

export function imageType() {
    const item: Array<any> = [
        {'id': 1, 'text': 'AWS'},
        {'id': 2, 'text': 'Google'},
        {'id': 3, 'text': 'DockerHub'},
        // {'id': 4, 'text': 'Azure'},
        {'id': 4, 'text': 'Other registry'},
    ];
    return item;
}

export function webhookType() {
    const hooks: Array<any> = [
        {'id': 1, 'text': 'GitHub'},
        {'id': 2, 'text': 'GitLab'},
        {'id': 3, 'text': 'HTTP POST'}
    ];
    return hooks;
}