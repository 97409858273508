import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-application-pipelines',
    templateUrl: './application-pipelines.component.html',
    styleUrls: ['./application-pipelines.component.scss']
})
export class ApplicationPipelinesComponent implements OnInit {

    _config = {
        pipelineSteps: [],
        navigateUrl: null,
        heading: null
    };
    pipelineSteps;
    step;
    project_id;
    project_details;
    navigateUrl: string;
    showPipeline: boolean = false;
    last_index

    constructor() { }

    ngOnInit() {
    }

    @Output('linkClicked') linkClicked: EventEmitter<any> = new EventEmitter();

    @Input()
    set stage(stage) {
        if (stage) this.step = stage;
    }
    @Input()
    set config(config) {
        if (config instanceof Object) this._config = Object.assign(this._config, config);
        this.pipelineSteps = this._config.pipelineSteps;
        this.navigateUrl = this._config.navigateUrl;
        this.last_index = this.pipelineSteps.length - 1
        this.showPipeline = true;
    }
    onClickEvent(stage) {
        this.linkClicked.emit({ "stage": stage })
    }
    isStepCompleted(stage) {
        var step_index = this.pipelineSteps.indexOf(this.step);
        var stage_index = this.pipelineSteps.indexOf(stage)
        if (stage_index < step_index) return true;
    }

}
