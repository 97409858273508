import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { map, catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class ProjectService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    createProject(project) {
        return this.http.post(this.getApiUrl('projects/'), JSON.stringify(project), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    updateProject(project) {
        return this.http.post(this.getApiUrl('projects/update-project/'), JSON.stringify(project), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getProject(id) {
        return this.http.get(this.getApiUrl(`projects/${id}/get_project/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }


    createRepository(repo, id) {
        return this.http.post(this.getApiUrl(`projects/${id}/repository/`), JSON.stringify(repo), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteProject(id) {
        const url = this.getApiUrl(`projects/${id}/delete/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getProjectUsers(id) {
        const url = this.getApiUrl(`projects/${id}/get_users/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    get_project_metrics(project_id, query_params= null) {
        const url = this.getApiUrl(`project_metrics/${project_id}/get-overall-metrics${query_params}`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    createJiraCredentilas(data: any, id: any) {
        return this.http.post(this.getApiUrl(`projects/${id}/jira-credentials/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getJiraCredentilas(id: any) {
        return this.http.get(this.getApiUrl(`projects/${id}/get-jira-credentials/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    checkJiraConigExists(project_id) {
        return this.http.get(this.getApiUrl(`projects/${project_id}/check-jira-exists/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getAllProject(id, page = 1, limit= 20) {
        const offset = (page - 1) * limit;
        limit = page * limit;
        const url = this.getApiUrl(`projects/`, { 'application_id': id, 'offset': offset, 'limit': limit });
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    createDetails(value, id: any) {
        return this.http.post(this.getApiUrl(`project-tools/${id}/create-reviewboard/`),
            JSON.stringify(value), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getDetails(value, id) {
        return this.http.get(this.getApiUrl(`project-tools/${id}/get-reviewboard/`),
            this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    sonarCredentials(details: any, project_id: any) {
        return this.http.post(this.getApiUrl(`project-tools/${project_id}/sonar/`), JSON.stringify(details), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getSonar(value: any, project_id: any): Observable<any> {
        return this.http.get(this.getApiUrl(`project-tools/${project_id}/sonar-detail/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    store_jenkins_sonar(data) {
        const url = this.getApiUrl(`project-tools/jenkins-sonar-configuration/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }
    store_jenkins_credentials(data) {
        return this.http.post(this.getApiUrl(`project-tools/store-jenkins-credentials/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    store_docker_registry_credentials(data) {
        const url = this.getApiUrl(`project-tools/store-docker-registry-credentials/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    get_stored_tools_details(id) {
        return this.http.get(this.getApiUrl(`project-tools/${id}/get-stored-tools-details/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    get_project_details_for_edit(id) {
        return this.http.get(this.getApiUrl(`projects/${id}/get-project-details/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    finalSubmit(data, id) {
        return this.http.post(this.getApiUrl(`project-tools/${id}/project-configure/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    createOrUpdateNonRepo(data) {
        const url = this.getApiUrl(`projects/non-repo-create-or-update/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
}
