import { Component, OnInit, EventEmitter, Output, HostListener, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Toast, ToasterService } from 'angular2-toaster';
import { ApplicationService } from '@app/services';
import { Router } from '@angular/router';
import { NavigationControlService } from '@app/shared/directives/custom-devops-header/navigation-control.service';
import { TOASTERCONFIG, SettingsService } from '@app/core';
import { User } from '@app/models';

declare var $: any;

@Component({
    selector: 'app-applications-create-new',
    templateUrl: './applications-create-new.component.html',
    styleUrls: ['./applications-create-new.component.scss']
})
export class ApplicationsCreateNewComponent implements OnInit {

    @Output('cancelModal') cancelModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('clodeModal') clodeModal;

    appTrue: boolean = false;
    errorMessage: string = '';
    descriptionError: string = '';
    buttondisable: boolean = true;
    processStatus = false;
    Application_Form: FormGroup;
    isBackDrop: boolean;
    group_url: any;
    toasterConfig = TOASTERCONFIG;
    currentUser: User = null;


    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        $('#addApplication').modal('hide');
        $('.modal-backdrop').hide();
    }

    constructor(private fb: FormBuilder,
        private router: Router,
        private toasterService: ToasterService,
        private applicationService: ApplicationService,
        private navigateService: NavigationControlService,
        private settingsService: SettingsService) {

    }

    ngOnInit() {
        this.buildForm();
        this.settingsService.user$.subscribe(user => {
            this.currentUser = user;
        })
    }

    open_modal() {
        $('#addApplication').appendTo('body').modal('show');

    }

    buildForm() {

        this.Application_Form = this.fb.group({
            'application_name': ['', [Validators.required,
            Validators.maxLength(40), Validators.pattern(/^([a-zA-Z][a-zA-Z0-9-]*)$/)]],
            'description': [''],
            'application_type': [1]
        });
    }

    back() {
        // this.urls = this.router.url.split('/');
        $('#addApplication').modal('hide');
        this.Application_Form.controls['application_type'].setValue(1);
        this.Application_Form.reset();
        this.errorMessage = ''
        this.descriptionError = ''
        this.cancelModal.emit(true);
        this.isBackDrop = false;
        $('.modal-backdrop').remove();
        // this.group_url = this.router.url;
        // let id = this.group_url.split('/');
        // console.log("cc",`/applications/${id[2]}/dashboard`)
        // console.log("cd",[`/${id.slice(1,3).join('/')}`])
        // this.router.navigate([`/${id.slice(1,3).join('/')}/dashboard`])
        // this.router.navigate([`/${id.slice(1,3).join('/')}/pipeline/list`])

    }


    createApplication(values: any) {
        if (this.Application_Form.controls.description.value.length >= 255) {
            this.buttondisable = false;
            this.descriptionError = 'Maximum 255 characters in length'
            return;
        }
        this.buttondisable = true;
        this.processStatus = true;
        this.Application_Form.disable();
        const application = this.Application_Form.getRawValue();
        this.applicationService.createApplication(application).subscribe(resp => {
            if (resp) {
                let currentUser: User;
                let newlyCreatedApplication: object;
                newlyCreatedApplication = { id: resp[0].id };
                currentUser = this.currentUser;
                currentUser.app_id.push(newlyCreatedApplication)
                this.settingsService.setUser(currentUser);
                this.finished(resp);
            }
            // $('#addApplication').modal('hide');
            // $('#addApplication').removeClass('in');
            // $('.modal-backdrop').remove();
            // // $('#addApplication').hide();
            // this.navigateService.applicationAdded(true);
            // this.router.navigate([`/applications/${resp[0]['id']}/projects/list`]).then(res => res).catch(Err => Err);
            // this.Application_Form.reset();
            // this.Application_Form.controls['application_type'].setValue(1);
            // this.Application_Form.enable();
            // this.processStatus = false;

            // this.toasterService.pop(<Toast>{
            //     type: 'success',
            //     title: 'Application Created Successfully.',
            // });


        },
            error => {
                this.buttondisable = false;
                if (error['detail'].includes("choose another name")) {
                    this.errorMessage = 'Application name already exists';
                }
                else if (error['detail'].includes("Data too long for column 'description'")) {
                    this.descriptionError = 'Maximum 255 characters in length';
                }
                else {
                    this.errorMessage = error['detail'];
                }
                this.Application_Form.enable();
                this.processStatus = false;
            }
        );
        // console.log("app",application);
    }

    onNameChange() {
        if (this.Application_Form.controls.description.value.length < 255) {
            this.buttondisable = true
            this.descriptionError = '';
        }
        else{
            this.buttondisable = false
            this.descriptionError = 'Maximum 255 characters in length';
        }
        this.errorMessage = "";
    }



    async finished(resp) {
        // console.log("inn")
        this.processStatus = false;

        // await this.toasterService.pop(<Toast>{
        //     type: 'success',
        //     title: 'Application Created',
        // });


        const closemodalHide: any = document.querySelectorAll(".close-modal");
        closemodalHide[0] && closemodalHide[0].click();
        closemodalHide[1] && closemodalHide[1].click();
        this.navigateService.applicationAdded(true);
        this.navigateService.showToolTip(true);
        this.Application_Form.reset();
        this.Application_Form.controls['application_type'].setValue(1);
        this.Application_Form.enable();

        // $('#addApplication').remove();//modal('hide');
        $('.modal-backdrop').hide();
        // this.test();
        // this.toasterService.pop(<Toast>{
        //     type: 'success',
        //     title: 'Application Created Successfully.',
        // });
        $('#addApplication').modal('hide');
        this.router.navigate([`/applications/${resp[0]['id']}/projects/list`]).then(res => {
            this.clodeModal.nativeElement.click();
        }).catch(Err => Err);
        // $("#closeModal").click();
    }



}
