import * as moment from 'moment';

export class GitLab {
    created_at: string;
    default_branch: string;
    description: string;
    last_activity_at: string;
    name: string;
    name_with_namespace: string;
    path_with_namespace: string;
    id: number;
    visibility: string;

    constructor(data?: Object) {
        if (!(data instanceof Object)) data = {};

        this.created_at = moment(data['created_at']).format('DD MMM YY hh-mm A');
        this.description = data['description'] || null;
        this.default_branch = data['default_branch'] || null;
        this.id = data['id'] || null;
        this.last_activity_at = moment(data['last_activity_at']).format('DD MMM YY hh-mm A');
        this.name = data['name'] || null;
        this.name_with_namespace = data['name_with_namespace'] || null;
        this.path_with_namespace = data['path_with_namespace'] || null;
        this.visibility = data['visibility'] || null;
    }
}