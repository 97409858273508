import {ToasterConfig} from 'angular2-toaster';

export const industryType = [
    {'text': 'IT', 'id': 1},
    {'text': 'Production', 'id': 2},
    {'text': 'Manufacturing', 'id': 3},
    {'text': 'Service', 'id': 4},
    {'text': 'Non profit', 'id': 5}
];

export const reviewStatus = [
    {id: 1, text: 'All'},
    {id: 2, text: 'Pending'},
    {id: 3, text: 'Discarded'},
    {id: 4, text: 'Submitted'}
];

export const buildStatus = [
    {id: 1, text: 'All'},
    {id: 2, text: 'Success'},
    {id: 3, text: 'Failure'},
    {id: 4, text: 'Unstable'},
    {id: 5, text: 'Aborted'}
];

export const filterlist = [
    {id: 0, text: 'All'},
    {id: 1, text: 'Inprogress'},
    {id: 2, text: 'Completed'},
    {id: 3, text: 'Failed'},
    {id: 4, text: 'Blocked'},
];

export const alertlist = [
    {id: 1, text: 'All'},
    {id: 2, text: 'firing'},
    {id: 3, text: 'resolved'},
];

export const TOASTERCONFIG: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-center',
    animation: 'fade',
    newestOnTop: true,
    timeout: 5000,
    showCloseButton: false
});

export const notificationStatus = [
    {id: 1, text: 'All'},
    {id: 2, text: 'Read'},
    {id: 3, text: 'Unread'}
];

export const pubnubKey = {
    publishKey: 'pub-c-54e880f4-ad65-4b65-b64c-5060155eae8a',
    subscribeKey: 'sub-c-a926bf40-1ff2-4418-8f5f-6c6e9509a269',
};

export const buildType = [
    {id: 1, text: 'war'},
    {id: 2, text: 'ear'},
    {id: 3, text: 'jar'},
];

export const issue_status = [
    {id: 1, text: 'All'},
    {id: 2, text: 'With open issue count'},
    {id: 3, text: 'Without open issue count'}
];

export const kube_configs = {
    1: 'Google Kubernetes Engiene',
    2: 'Amazon EKS',
    3: 'IBM Cloud',
    4: 'Kubernetes',
    5: 'Azure Kubernetes Service',
    6: 'RED HAT openshift'
};
export const ProjectToolsList = [
    {name: 'Overview', active: 'overview', access: []},
    {name: 'Review Board', active: 'rb', access: []},
    {name: 'Jenkins', active: 'jenkins', access: []},
    {name: 'Jira', active: 'jira', access: []},
    {name: 'Freshdesk', active: 'fresh-desk', access: []},
];

// Action Attributes for action input
export const actionAttributes: any[] = [
    {id: 1, text: 'Attribute'},
    {id: 2, text: 'Configmap'},
    {id: 3, text: 'Secret'},
    {id: 4, text: 'PVC'},
    {id: 5, text: 'File'},
    {id: 6, text: 'Directory'},
    {id: 7, text: 'AttributeArray'}
];

// action IO type
export const actionIOType = [
    {id: 1, text: 'text'},
    {id: 2, text: 'number'},
    {id: 3, text: 'ng-select'},
    {id: 4, text: 'radio'},
    {id: 5, text: 'textarea'},
    {id: 6, text: 'tag'},
];

export const darkColorCodes = [
    "#C28F8F",
    "#6E63AF",
    "#f9972d",
    "#F27676",
    "#c15858",
    "#D98827",
    "#7578CC",
    "#AC75CC",
    "#91724E",
    "#82BCBA",
    "#a9e343",
    "#5BBF53",
    "#B04545",
    "#37938C",
    "#E259A3",
    "#71E43A",
    "#C6B42C",
    "#5BDABC",
    "#8EAF92",
    "#241B4A",
    "#72B7B3",
    "#DF77DC",
    "#F14E6D",
    "#82871C",
    "#2C4093",
  ];
  