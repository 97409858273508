import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-wave',
  template: `
<div class="sk-wave {{cssClass}}">
  <div class="sk-rect sk-rect1"></div>
  <div class="sk-rect sk-rect2"></div>
  <div class="sk-rect sk-rect3"></div>
  <div class="sk-rect sk-rect4"></div>
  <div class="sk-rect sk-rect5"></div>
</div>
  `,
  styles: ['']
})
export class LoaderWaveComponent implements OnInit {
  @Input('cssClass') cssClass: string;

  constructor() { }

  ngOnInit() {
  }

}
