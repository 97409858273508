import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { AuthService } from './auth.service';
import { SettingsService } from '@app/core';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        // Validating url params
        if (Object.keys(route.data).indexOf('regex') >= 0) {
            let params = route.params;
            for (let param of Object.keys(params)) {
                if (Object.keys(route.data['regex']).indexOf(param) >= 0) {
                    let regex_str = `^${route.data['regex'][param]}$`;
                    let exp = new RegExp(regex_str, 'g');

                    if (!exp.test(params[param])) {
                        this.router.navigate(['/error/not-found']);
                        return false;
                    }
                }
            }
        }

        // Checks is user authenticated
        if (!this.authService.authenticated()) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        if (Object.keys(route.data).indexOf('roles') == -1) return true;
        else {
            let userTypes: Array<string> = null;

            if (Object.keys(route.data).indexOf('userTypes') >= 0) userTypes = route.data['userTypes'];

            if (this.authService.permitted(route.data['roles'], userTypes)) return true;
            else {
                this.router.navigate(['/error/permission-denied']);
                return false;
            }
        }
    }
}
