export class ServerApplication {
    id?: number;
    server_url = '';
    username = '';
    password = '';
    server_type = '';

    constructor(data?: Object) {
        if (!(data instanceof Object)) { data = {}; }
        this.id = data['id'] || this.id;
        this.server_url = data['server_url'] || this.server_url;
        this.username = data['username'] || this.username;
        this.password = data['password'] || this.password;
        this.server_type = data['server_type'] || this.server_type;
    }
}

export function serverType() {
    const item: Array<any> = [
        {'id': 1, 'text': 'JBoss'}
    ];
    return item;
}

export class MonitoringApplication {
    id?: number;
    elastic_apm_url = null;
    kibana_url = '';
    service_name = '';
    type = '';
    context_name: any;

    constructor(data?: Object) {
        if (!(data instanceof Object)) { data = {}; }
        this.id = data['id'] || this.id;
        this.elastic_apm_url = data['elastic_apm_url'] || this.elastic_apm_url;
        this.kibana_url = data['kibana_url'] || this.kibana_url;
        this.service_name = data['service_name'] || this.service_name;
        this.type = data['type'] || this.type;
        this.context_name = data['context_name'] || this.context_name;
    }
}

export class Prometheus {
    id?: number;
    prometheus_url: string = null;
    target_url: string = '';
    job_name: string = '';
    type: string = '';
    alert_manager_url = '';

    constructor(data?: Object) {
        if (!(data instanceof Object)) { data = {}; }
        this.id = data['id'] || this.id;
        this.prometheus_url = data['prometheus_url'] || this.prometheus_url;
        this.target_url = data['target_url'] || this.target_url;
        this.job_name = data['job_name'] || this.job_name;
        this.type = data['type'] || this.type;
        this.alert_manager_url = data['alert_manager_url'] || this.alert_manager_url;
    }
}

export class Slack {
    id?: number;
    web_url: string = null;
    channel_name = '';

    constructor(data?: Object) {
        if (!(data instanceof Object)) { data = {}; }
        this.id = data['id'] || this.id;
        this.web_url = data['web_url'] || this.web_url;
        this.channel_name = data['channel_name'] || this.channel_name;
    }
}
