import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '@app/shared/directives';
import { InterfaceComponent } from '@app/interfaces';
import { StatusConfig } from './interfaces';
import { InterfaceService } from '@app/interfaces';
import { ProjectService } from '@app/services';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss']
})
export class StatusIconComponent implements OnInit {
  _config: StatusConfig = {
    statusSteps: [],
    navigateUrl: null,
  };
  statusSteps;
  step;
  project_id;
  navigateUrl: string;
  showStatus: boolean = false;
  index = -1;
  stepIndex;
  // Events
  @Output('linkClicked') linkClicked: EventEmitter<any> = new EventEmitter();


  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }


  @Input('config')
  set config(config: StatusConfig) {
    if (config instanceof Object) this._config = Object.assign(this._config, config);
    this.statusSteps = this._config.statusSteps;
    this.navigateUrl = this._config.navigateUrl;
    this.step = this._config.projectStatus;
    // this.index = -1;
    this.showStatus = true;
  }


  onClickEvent(stage) {
    this.linkClicked.emit({ "stage": stage })
  }

  changeStatusOnPagination(status){
    this.stepIndex = -1;
    this.step = status;
    this.index = -1;
  }

  isStepCompleted(step: string) {
    return this.statusSteps.indexOf(this.step[this.index]) >= this.statusSteps.indexOf(step);
  }

  // public ngDoCheck() {
  //   this.cdRef.detectChanges();
  // }
}
