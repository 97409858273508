import { Component, Input, Host, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    template: `
    <ng-container>
        <label class="needsclick">
            <input (change)="toggleCheck()" [checked]="isChecked()" class="needsclick" type="checkbox">
            <span class="fa fa-check"></span><ng-content></ng-content>
        </label>
    <ng-container>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppCheckboxComponent),
            multi: true
        }
    ]
})
export class AppCheckboxComponent {
    private _model: any;
    private onChange: (m: any) => void;
    private onTouched: (m: any) => void;

    // value input for checbox option
    @Input('value') value: any;

    isChecked() {
        return this.contains(this.value);
    }

    set(value: any) {
        this._model = value;
        this.onChange(this._model);
    }

    get model() {
        return this._model;
    }

    // From ControlValueAccessor Interface
    writeValue(value: any): void {
        this._model = value;
    }

    // From ControlValueAccessor Interface
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    // From ControlValueAccessor Interface
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Set Touched on blur
    onBlur() {
        this.onTouched(this._model);
    }

    // Add/remove value in the model
    toggleCheck() {
        if (this.contains(this.value)) {
            this.remove(this.value);
        } else {
            this.add(this.value);
        }

        this.onTouched(this._model);
    }

    // Checks model has the value
    contains(value: any): boolean {
        if (this._model instanceof Array) {
            return this._model.indexOf(value) > -1;
        } else if (!!this._model) {
            return this._model === value;
        }

        return false;
    }

    // Adds selected value into model
    private add(value: any) {
        if (!this.contains(value)) {
            if (this._model instanceof Array) {
                this._model.push(value);
            } else {
                this._model = [value];
            }
            this.onChange(this._model);
        }
    }

    // Removes unchecked value from the model
    private remove(value: any) {
        const index = this._model.indexOf(value);
        if (!this._model || index < 0) {
            return;
        }

        this._model.splice(index, 1);
        this.onChange(this._model);
    }
}
