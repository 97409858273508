import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authTokenId, GetApiurl } from '@app/core';
import { InterfaceService } from '@app/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


interface PagenationState {
    pageNo: number;
    show: number;
}
@Injectable({
    providedIn: 'root'
})


export class ActionPagenationService extends InterfaceService {
    private pageState: PagenationState;

    get currentPageState(): PagenationState {
        return this.pageState;
    }

    set currentPageState(pageState: PagenationState) {
        this.pageState = pageState;
    }

}

@Injectable({
    providedIn: 'root'
})
export class ActionsService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    addAction(value: any, logo: File, mode?: any) {
        let url: string = '';
        if (mode == 'add') {
            url = this.getApiUrl(`actions/`);
        } else {
            url = this.getApiUrl(`actions/${value['id']}/`);
        }
        // const url: any = GetApiurl(`actions/`);
        // value['logo'] = logo;
        const formData: FormData = new FormData();
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            if (typeof (logo) == 'object') {
                formData.append('logo', logo, logo.name);
            } else {
                value['logo'] = logo;
            }
            if (mode == 'add') {
                formData.append('data', JSON.stringify(value));
                // formData.append('logo', logo, logo.name);
                xhr.open('post', url, true);
            } else {
                formData.append('data', JSON.stringify(value));
                xhr.open('put', url, true);
            }
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem(authTokenId));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        try {
                            reject(JSON.parse(xhr.response));
                        } catch (error) {
                            reject(xhr.response);
                        }
                    }
                }
            };
        });
        // return this.http.post(url,formData, this.getHttpOptions('json'))
        //     .pipe(
        //         map(Response => Response),
        //         catchError(this.handleError)
        //     );
    }

    // get action types
    get_action_types() {
        return this.http.get(this.getApiUrl(`actions/get-action-types/`), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    isActionNameExist(name, type, domain_name,  action_id) {
        // let data={'name':name,'type':type,'doamin':domain_name}
        const params = { name, type, domain: domain_name, action_id };
        return this.http.get(this.getApiUrl(`actions/`, params), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    //create action details
    create_action_details(action_id, data, category_logo: File) {
        // let url = this.getApiUrl(`actions/${action_id}/create-action-details/`);
        // return this.http.post(url, data, this.getHttpOptions('json'))
        // .pipe(
        //     map(Response => Response),
        //     catchError(this.handleError)
        // );
        let url: string = '';

        url = this.getApiUrl(`actions/${action_id}/create-action-details/`);

        // const url: any = GetApiurl(`actions/`);
        // value['logo'] = logo;
        const formData: FormData = new FormData();
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            if (typeof (category_logo) == 'object') {
                formData.append('category_logo', category_logo, category_logo.name);
            } else {
                data['category_logo'] = category_logo;
            }

            formData.append('data', JSON.stringify(data));
            xhr.open('post', url, true);

            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem(authTokenId));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
        });
    }

    getActionDetails(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/get-action-details/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getAllInputs(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/get-all-inputs/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    getInputList(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/get-input-list/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // add/edit input
    create_action_input(action_id, data) {
        let url = this.getApiUrl(`actions/${action_id}/create-action-input/`);
        return this.http.post(url, data, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    //add/edit output
    create_action_output(action_id, data) {
        let url = this.getApiUrl(`actions/${action_id}/create-action-output/`);
        return this.http.post(url, data, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // action delete 
    deleteAction(id) {
        let url = this.getApiUrl(`actions/${id}/`);
        return this.http.delete(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    //delete input/output
    deleteInputorOutput(id) {
        let url = this.getApiUrl(`actions/${id}/delete-input-output/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // test action 
    testAction(id) {
        let url = this.getApiUrl(`actions/${id}/test-action/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // deploy action 
    deployAction(id) {
        let url = this.getApiUrl(`actions/${id}/deploy-action/`);
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    //save editor data
    saveEditorData(id, data) {
        let url = this.getApiUrl(`actions/${id}/save-editor-data/`);
        return this.http.post(url, data, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // get action editors data 
    getActionEditorData(id) {
        let url = this.getApiUrl(`actions/${id}/get-editor-data/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    // delete editor file 
    deleteEditorFile(id) {
        let url = this.getApiUrl(`actions/${id}/delete-editor-file/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    removeExistingMainFile(id, fileId) {
        let url = this.getApiUrl(`actions/${id}/remove-existing-main-file/?fileId=${fileId}/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    // test action config 
    testActionConfig(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/test-action-config/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    // deploy action config 
    deployActionConfig(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/deploy-action-config/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    // action log 
    actionLog(action_id) {
        let url = this.getApiUrl(`actions/${action_id}/get-pipeline-logs/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    getActionPipelineStatus(action_id: number) {
        let url = this.getApiUrl(`actions/${action_id}/get-pipeline-status/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    getPodLogParams(action_id: number) {
        let url = this.getApiUrl(`actions/${action_id}/get-pod-log-params/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    getPodStatus(action_id: number) {
        let url = this.getApiUrl(`actions/${action_id}/get-pod-status/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    // test pipeline 
    testActionPipeline(action_id, data) {
        let url = this.getApiUrl(`actions/${action_id}/test-action-pipeline/`);
        return this.http.post(url, data, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

}
