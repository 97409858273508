import { Router, ActivatedRoute, Params } from '@angular/router';

export class ActiveRoute {
    project_id;

    constructor(
        public route:ActivatedRoute
    ) {
        this.route.params.subscribe((params: Params) => {
            return this.project_id = params['id']
        });
    }
}