import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { GitLab } from '@app/models';
import { InterfaceService } from '@app/interfaces';

@Injectable()
export class GitLabService extends InterfaceService {

  constructor(private http: HttpClient) {
    super();
  }

  verifyCredentials(value) {
    return this.http.post(this.getApiUrl(`gitlab/verify_credentials/`), 
    JSON.stringify(value), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  getProjects(id:number) {
    let url = this.getApiUrl(`projects/${id}/repo_project_list/`);
    return this.http.get<GitLab[]>(url, this.getHttpOptions('json'))
    .pipe(
      map(data => data.map(i => new GitLab(i))),
      catchError(this.handleError)
    );
  }

  getReports(id, data=null) {
    let url = this.getApiUrl(`gitlab/${id}/get_report/`);
    return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
    .pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  getGitlabProject(id, projectId) {
    let url = this.getApiUrl(`gitlab/${id}/get_project/${projectId}`);
    return this.http.get(url, this.getHttpOptions('json'))
    .pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

}