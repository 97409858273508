export function getStringValue(flag: string) {
    const value = {
        applicationName: [
            'Should contain only letters, numbers and \'-\'',
            'E.g - name01, name-01, name02name, name-02-name, NameName',
            'Should not contain space and other special characters except hyphen'
        ],
        dnameockerized: [
            'Docker is a tool designed to make it easier to create, deploy, and run applications by using containers.',
            'Online providers:- Google Kubernetes Engine(GKE), Amazon EKS, etc...'
        ],
        non_Dockerized: [
            'Create, deploy, run without docker.',
            'E.g Jboss, apache'
        ],
        github_Token: [
            'Click you name (upper-right corner).',
            'Click Settings tab.',
            'Click Developer settings (left-side menu).',
            'Select Personal access token from left-side menu.',
            'Use "Generate new token" button to generate a new one ,then enter description and select scopes.',
            'You must copy the token when you generate it as you cannot view the token afterwards.'
        ],
        github_Username: ['Github Username'],
        github_Password: ['Github Password'],
        gitlab_URL: ['Gitlab Server Url'],
        gitlab_Username: ['Gitlab Username'],
        gitlab_Password: ['Gitlab Password'],
        gitlab_Token: ['Gitlab token'],
        SVN_Url: ['SubVersion Url'],
        SVN_Username: ['SubVersion Username'],
        SVN_Password: ['SubVersion Password'],
        reviewboard_Url: ['Reviewboard url'],
        local_Path: ['Project location', 'Example - /home/user/dir/&#60;project_name&#62;/', 'Make sure path end with \'/\''],
        reviewboard_Token: [
            'Auth token for access the reviewboard.',
            'Goto -> My Account -> Authentication',
            'Click Generate a new API token',
            'Make sure token have full access'
        ],
        default_Reviwer: ['Only default reviewer can accees review details for this branch.'],
        mail_Domain: ['Based on domain mail will send.', 'E.g - &#60;username&#62;@&#60;Domain_name&#62;.in'],
        sonarqube_Token: [
            'Go to -> User -> My Account -> Security',
            'Click Generate to create new token',
            'Copy it immediately; once you dismiss the notification you will not be able to retrieve it.'
        ],
        sonarqube_Projectkey: ['Project key is displayed at the bottom-right of the project homepage.'],
        java_Source: ['Its value should be Java Source versions.', 'Example:- versions: 1.7'],
        java_Binaries: ['Its value should be Java Path.', 'Example:- /home/devops/'],
        sonarqube_Path: ['Its value should be the unzipped path of sonar-runner zip file.', 'Example:- /opt/sonar-runner'],
        jenkins_Token: [
            'Click you name (upper-right corner).',
            'Click Configure (left-side menu).',
            'Use "Add new Token" button to generate a new one then name it.',
            'You must copy the token when you generate it as you cannot view the token afterwards.'
        ],
        baremetal_Server_Url: ['Server Url'],
        baremetal_Username: ['Server username'],
        baremetal_Password: ['Server password'],
        registry_Name: ['Registry name'],
        credential_name: ['Credential name'],
        registry_Url: ['Registry url for push docker images', 'e.g: https:&#60;Doamin name&#62;/registry'],
        registry_Username: ['Registry username'],
        registry_Password: ['Registry password'],
        Jira_ProjectKey: [
            'Click projects(top-left side corner)',
            'Select view all project',
            'Key is project-key value',
            'E.g -> BLUE'
        ],
        elasticApm_Url: ['Elastic Apm Url'],
        kibana_Url: ['Kibana Url'],
        alert_Manager_URL: ['Alert Manager Url'],
        prometheus_URL: ['Prometheus Url'],
        prometheus_Target_URL: ['Target url from "scrap_config" related to specified <b>job name</b> in <b>prometheus.yml</b>'],
        prometheus_Job_Name: ['Job name (need to be monitored) in <b>prometheus.yml</b>'],
        select_Dependency: ['Select a Database from the available list'],
        service_Name: ['Enter a valid service name in the box provided'],
        service_Port: ['Enter the correct port number for the service name'],
        deployment_Name: ['Enter the deployment name'],
        namespace: ['Enter a namespace value'],
        commit_Sha: ['Select sha for specific commit - first sha denotes recent commit'],
        revision_Number: ['Enter the subversion number to retrieve the contents'],
        friendly_Name: ['Enter a user fiendly name that is easy to remember'],
        google_kubernetes_engine: ['please upload a Google kubernetes config file'],
        amazon_EKS: ['please upload a Amazon EKS config file'],
        IBM_Cloud: ['Please upload a IBM Cloud config file'],
        kubernetes: ['Please upload a kubernetes config file'],
        azure_kubernetes_service: ['Please upload a Azure Kubernetes Service config file'],
        RED_HAT_OPENSHIFT: ['Please upload a Red hat openshift config file'],
        FreshDesk_ProjectKey: [
            'This is just refrence name for get ticket details. e.g RED',
            'commit message exampl "RED-10 testing"',
            'Ticket number is 10.',
            'Based on ticket number show the information.'
        ],
        pipelineApproval: ['Pipeline approval'],
        google_file: ['Open service accounts in cloud console',
                        'select email address and click tabs',
                        'click Add key and create new key',
                        'select json as key type and click create',
                        'service account key file gets downloaded',
                        'upload the downloaded json file'],
        linode_file: ['upload .pem file'],
        actionName: ['Should contain only lowercase <br/> letters numbers and hyphen',
                    'Should not contain space and <br/>other special character except hyphen',
                    `E.x - aws-resource-check01,<br/> 
                    aws-resource-check-01, <br/>
                    aws-resource-check02aws-resource-check,<br/>
                    aws-resource-check-02-aws-resource-check`,
        ],
        actionDescription: ['Name of the Action <br> which will be displayed on the ui',
                            'Purpose of the Action, describe <br> shortly'],
        actionLanguage: ['Select any one programming <br> languages you want to write an <br> action'],
        actionImage: ['Visual Representation of Action <br/> type.',
                     'Ex: if the action is related to <br/> aws s3 bucket upload s3 image',
                     'optional field'
        ],
        actionInternalName : ['Internal name for the action'],
        actionCheckout: ['if yes clone source code from version control system.',
                        'Ex: github, gitlab',
                        'if No will not clone source code from version control'
        ],
        actionCategory: ['Category under which this task needs to be grouped.',
                        'Ex: if the action is specific to aws you can categorize <br/> as aws',
                        'if not exist it will create new one.',
                        'next time any action comes with category as aws <br/> will be placed into aws category.'
        ],
        actionEnvironment: ['if checkout is yes means that project source code <br/> will be cloned inside this environment'],
        actionProjects: ['if checkout is yes and environment is also yes',
                        ' if you configured the specific branch which will be <br/> cloned inside the environment'
        ],
        actionBaseImage: ['if you want to run your action on different image <br/> you can specify here. ',
                        'enter valid docker image name',
                        'optional field'
                        ],
        actionInputFieldName: ['Field name it should be unique.',
                              'You\'ll use this field name to get this field  <br/> value in your  backend script',
                              'Ex:if you have a fieldName called REGION, <br/> you can get it using this name',
                            //   'for more info <a href="https://projects.starsystems.in/d5d/writing_actions/#attributes">D5d docs</a>'
                            ],
        //  actionInputFieldName: ['Field name it should be unique.',
        //                       'You\'ll use this field name to get this field value in your <br/> backend script',
        //                       'Ex:if you have a fieldName called REGION, you can get it as',
        //                       'if Python is your backend. Then <br/> <pre>value = os.getenv("PARAM_TESTVAR")</pre>',
        //                       'if shell script. Then <pre>value=$PARAM_TESTVAR</pre>',
        //                       'if GOLANG. Then <pre>   value := os.Getenv("PARAM_TESTVAR")</pre>',
        //                       'if php. Then <pre>   value = getenv("PARAM_TESTVAR")</pre>'
        // ],
        actionInputFieldType: ['input field type. ng-select is dropdown field',
                                'tag:  multiple entries for a field<br>',
                                `if you wanted to expose an array of ports <br/>to the backend,
                                 you will use the tag type <br/> which will result in multiple values be entered <br/> against that field.<br/> 
                                 You can enter one value and hit the tab <br/>to make that an element and continue doing<br/> it for other elements.`
        ],
        actionInputFieldValueType: ['How do you want to store the field value'],
        actionInputFieldLabel: ['Label of the input field',
                                'Ex: Enter Aws Instance Name:'
        ],
        actionInputFieldRequired: ['Wheather user must enter value for<br/> this field or not.'],
        actionInputFieldDescription: ['description of the input field',
                                    'Ex: Enter Aws Instance Name:'
        ],
        actionInputFieldUseOutput: ['<b>This field enables for TEXT field type only<b>','If you choose yes, previous actions <br/> output will be shown as values  in this field <br/> as dropdown'],
        actionInputFieldDynamic: ['If yes means fetch from your backend url.<br/> otherwise normal field based on your field <br/> type'],
        actionInputFieldHidden: [`this is hidden field. There are times you <br/> want to fetch values from your backend <br> 
                                 that value cannot be shown to the user`],
        actionOutputFieldName:['Name you want to expose as value, this name<br/> can be input to some other actions <br/> where you will be using useOutput:yes'],
        actionOutputFieldType:['Type of the value you want to expose to <br/> other actions'],
        customerDomain:['Please type your Domain name in the <br>format yourDomainExample.xyz'],


    };
    return value.hasOwnProperty(flag) ? value[flag] : [];
}
