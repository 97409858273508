import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService extends InterfaceService {

  constructor(private http: HttpClient) {
    super();
  }

  getWhatsappUsers(application_id) {
    return this.http.get(this.getApiUrl(`whatsapp/${application_id}/whatsapp-users/`), this.getHttpOptions('json'))
        .pipe(
            tap(Response => Response),
            catchError(this.handleError)
        );
}
  getRegUsers() {
    return this.http.get(this.getApiUrl(`whatsapp/get-registered-users/`), this.getHttpOptions('json'))
      .pipe(
          tap(Response => Response),
          catchError(this.handleError)
      );
  }

  otpGeneration(payload:any) {
    const url = this.getApiUrl(`whatsapp/otp-generation/`);
    return this.http.post(url, JSON.stringify(payload), this.getHttpOptions('json'))
    .pipe(
        tap(Response => Response),
        catchError(this.handleError)
    )
}

otpVerification(payload:any) {
  const url = this.getApiUrl(`whatsapp/otp-verification/`);
  return this.http.post(url, JSON.stringify(payload), this.getHttpOptions('json'))
  .pipe(
      tap(Response => Response),
      catchError(this.handleError)
  )
}

otpVerificationUpdate(payload:any) {
  const url = this.getApiUrl(`whatsapp/update-number-otp-verification/`);
  return this.http.post(url, JSON.stringify(payload), this.getHttpOptions('json'))
  .pipe(
      tap(Response => Response),
      catchError(this.handleError)
  )
}
}