import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProjectService, ProjectBranchService } from '@app/services';
declare var $: any;

//--
import { InterfaceComponent } from '@app/interfaces';
import { MenuService, SettingsService } from '@app/core';
import { AppPreferences } from '@app/models';
import { menu } from '../../menu';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends InterfaceComponent implements OnInit, OnDestroy {
    menuItems: Array<any>;
    appPreferences: AppPreferences = new AppPreferences();
    router: Router;
    sbclickEvent = 'click.sidebar-toggle';
    $doc: any = null;
    project_id = 0;
    branch_id = 0;
    project_details;
    branch_details;

    constructor(
        public menuService: MenuService,
        public settingsService: SettingsService,
        public injector: Injector,
        public route: ActivatedRoute,
        public projectService: ProjectService,
        private branchService: ProjectBranchService,
    ) {
        super();
        this.route.params.subscribe((params: Params) => {
            if ('project_id' in params) {
                this.project_id = params['project_id']
            }
            else if ('branch_id' in params)
                this.branch_id = params['branch_id']
        });
        //add menu Items
        this.menuItems = menuService.getMenu();
        console.log(this.menuItems)
        if (this.project_id != 0) {
            this.projectService.getProject(this.project_id)
                .subscribe(resp => {
                    this.project_details = resp
                    this.menuItems = menuService.getMenu();
                    this.menuItems.forEach((item) => {
                        if (item['heading'])
                            item['text'] = this.project_details['project_name']

                        if ("submenu" in item) {
                            item.submenu.forEach((subitem) => {
                                if (subitem['link'].includes("undefined"))
                                    subitem['link'] = subitem['link'].replace("undefined", this.project_id)
                                else
                                    subitem['link'] = subitem['link'].replace(/[0-9]+/g, this.project_id)
                            })
                        }
                        else if ("link" in item) {
                            if (item['link'].includes("undefined"))
                                item['link'] = item['link'].replace("undefined", this.project_id)
                            else
                                item['link'] = item['link'].replace(/[0-9]+/g, this.project_id)
                        }
                    });
                });
        }
        else if (this.branch_id != 0) {
            this.menuItems = menuService.getMenu();
            this.branchService.getBranch(this.branch_id)
                .subscribe(resp => {
                    this.branch_details = resp
                    this.project_details = resp['project']
                    this.projectService.checkJiraConigExists(this.project_details.id).subscribe(
                        data => {
                            if (!data) {
                                let index = this.menuItems.findIndex(x => x.text === 'jira');
                                if (index != -1) {
                                    this.menuItems.splice(index, 1);
                                }
                            }
                            else {
                                let index = this.menuItems.findIndex(x => x.text === 'jira');
                                if (index == -1) {
                                    const jira = {
                                        text: 'jira',
                                        link: `/branch/${this.branch_id}/jira/list`,
                                        icon: 'fa fa-line-chart'
                                    };
                                    this.menuItems.push(jira);
                                }

                            }
                            // this.freshDeskService.checkFreshDeskConfig(this.branch_id).subscribe(
                            //     res => {
                            //         if (res['status'] != 200) {
                            //             let index = this.menuItems.findIndex(x => x.text === 'fresh-desk');
                            //             if (index != -1) {
                            //                 this.menuItems.splice(index, 1);
                            //             }

                            //         } else {
                            //             let index = this.menuItems.findIndex(x => x.text === 'fresh-desk');
                            //             if (index == -1) {
                            //                 const freshDesk = {
                            //                     text: 'fresh-desk',
                            //                     link: `/branch/${this.branch_id}/fresh-desk`,
                            //                     icon: 'fa fa-bar-chart-o',
                            //                 };
                            //                 this.menuItems.push(freshDesk);
                            //             }
                            //         }
                            //     }
                            // )
                            this.menuItems.forEach((item) => {
                                if (item['heading'])
                                    item['text'] = this.project_details['project_name'] + ' / ' + this.branch_details['branch_name']

                                if ("submenu" in item) {
                                    item.submenu.forEach((subitem) => {
                                        if (subitem['link'].includes("undefined"))
                                            subitem['link'] = subitem['link'].replace("undefined", this.branch_id)
                                        else
                                            subitem['link'] = subitem['link'].replace(/[0-9]+/g, this.branch_id)
                                    })
                                }
                                else if ("link" in item) {
                                    if (item['link'].includes("undefined"))
                                        item['link'] = item['link'].replace("undefined", this.branch_id)
                                    else
                                        item['link'] = item['link'].replace(/[0-9]+/g, this.branch_id)
                                }
                            });
                        }
                    );
                });

        }
    }

    ngOnInit() {

        this.router = this.injector.get(Router);
        this.subscriptions['appPreferences'] = this.settingsService.appPreferences$.subscribe(settings => {
            this.appPreferences = settings || new AppPreferences();
        })

        this.router.events.subscribe((val) => {
            // close any submenu opened when route changes
            this.removeFloatingNav();
            // scroll view to top
            window.scrollTo(0, 0);
            // close sidebar on route change
            this.appPreferences['asideToggled'] = false;
        });

        // enable sidebar autoclose from extenal clicks
        this.anyClickClose();

    }

    anyClickClose() {
        this.$doc = $(document).on(this.sbclickEvent, (e) => {
            if (!$(e.target).parents('.aside').length) {
                this.appPreferences['asideToggled'] = false;
            }
        });
    }

    ngOnDestroy() {
        if (this.$doc)
            this.$doc.off(this.sbclickEvent);
    }

    toggleSubmenuClick(event, url = null) {
        this.router.navigate[url]
        if (!this.isSidebarCollapsed() && !this.isSidebarCollapsedText() && !this.isEnabledHover()) {
            event.preventDefault();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;

            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a').first();
            }
            ul = anchor.next();

            // hide other submenus
            let parentNav = ul.parents('.sidebar-subnav');
            $('.sidebar-subnav').each((idx, el) => {
                let $el = $(el);
                // if element is not a parent or self ul
                if (!$el.is(parentNav) && !$el.is(ul)) {
                    this.closeMenu($el);
                }
            });

            // abort if not UL to process
            if (!ul.length) {
                return;
            }

            // any child menu should start closed
            ul.find('.sidebar-subnav').each((idx, el) => {
                this.closeMenu($(el));
            });

            // toggle UL height
            if (parseInt(ul.height(), 0)) {
                this.closeMenu(ul);
            }
            else {
                // expand menu
                ul.on('transitionend', () => {
                    ul.height('auto').off('transitionend');
                }).height(ul[0].scrollHeight);
                // add class to manage animation
                ul.addClass('opening');
            }

        }

    }

    // Close menu collapsing height
    closeMenu(elem) {
        elem.height(elem[0].scrollHeight); // set height
        elem.height(0); // and move to zero to collapse
        elem.removeClass('opening');
    }

    toggleSubmenuHover(event) {
        let self = this;
        if (this.isSidebarCollapsed() || this.isSidebarCollapsedText() || this.isEnabledHover()) {
            event.preventDefault();

            this.removeFloatingNav();

            let target = $(event.target || event.srcElement || event.currentTarget);
            let ul, anchor = target;
            // find the UL
            if (!target.is('a')) {
                anchor = target.parent('a');
            }
            ul = anchor.next();

            if (!ul.length) {
                return; // if not submenu return
            }

            let $aside = $('.aside');
            let $asideInner = $aside.children('.aside-inner'); // for top offset calculation
            let $sidebar = $asideInner.children('.sidebar');
            let mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
            let itemTop = ((anchor.parent().position().top) + mar) - $sidebar.scrollTop();

            let floatingNav = ul.clone().appendTo($aside);
            let vwHeight = $(window).height();

            // let itemTop = anchor.position().top || anchor.offset().top;

            floatingNav
                .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
                .addClass('nav-floating')
                .css({
                    position: this.appPreferences['isFixed'] ? 'fixed' : 'absolute',
                    top: itemTop,
                    bottom: (floatingNav.outerHeight(true) + itemTop > vwHeight) ? 0 : 'auto'
                });

            floatingNav
                .on('mouseleave', () => { floatingNav.remove(); })
                .find('a').on('click', function (e) {
                    e.preventDefault(); // prevents page reload on click
                    // get the exact route path to navigate
                    let routeTo = $(this).attr('route');
                    if (routeTo) self.router.navigate([routeTo]);
                });

            this.listenForExternalClicks();

        }

    }

    listenForExternalClicks() {
        let $doc = $(document).on('click.sidebar', (e) => {
            if (!$(e.target).parents('.aside').length) {
                this.removeFloatingNav();
                $doc.off('click.sidebar');
            }
        });
    }

    removeFloatingNav() {
        $('.nav-floating').remove();
    }

    isSidebarCollapsed() {
        return this.appPreferences['isCollapsed'];
    }
    isSidebarCollapsedText() {
        return this.appPreferences['isCollapsedText'];
    }
    isEnabledHover() {
        return this.appPreferences['asideHover'];
    }

    // devops-custom sidebar collapse on menu item click
    toggleCollapsedSideabar() {
        this.appPreferences['isCollapsed'] = true;
        this.appPreferences['horizontal'] = false;     // devops-custom change (extra line)
    }
}
