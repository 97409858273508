import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-ball-beat',
  template: ` 
<div class="ball-beat loader">
  <div></div>
  <div></div>
  <div></div>
</div>`,
  styles: ['']
})
export class LoaderBallBeatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
