import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionList, OutputsFormat, UsedPropertiesDetails } from '@app/features/home/pipeline/pipeline/workflow-setup/workflow-interface';

@Injectable({
    providedIn: 'root'
})

export class HandleSomeFunctions {

    actionList: ActionList[] = [];
    index_list = {};
    categories_list: string[] = [];
    global_properties: any = [];

    constructor() {
    }

    private enableSubmit = new BehaviorSubject<boolean>(false);
    getSubmitStatus: Observable<boolean> = this.enableSubmit.asObservable();

    private loadingIcon = new BehaviorSubject<boolean>(false);
    getLoadingIcon: Observable<boolean> = this.loadingIcon.asObservable();

    private outputs = new BehaviorSubject<OutputsFormat[]>([]);

    private nameAvailability = new BehaviorSubject<string>(null);
    getNameAvailability: Observable<string> = this.nameAvailability.asObservable();

    private actionPageModal = new BehaviorSubject<boolean>(false);
    getActionPageModal: Observable<boolean> = this.actionPageModal.asObservable();

    private usedPropertiesDetails = new BehaviorSubject<UsedPropertiesDetails[]>([]);

    setSubmitStatus(data: boolean) {
        this.enableSubmit.next(data);
    }

    setGlobalProperties(properties) {
        this.global_properties = properties;
    }

    setLoaderIconStatus(data) {
        this.loadingIcon.next(data);
    }

    setOutputs(data: OutputsFormat) {
        const values = this.outputs.getValue();
        const index = values.findIndex(x => {
            // tslint:disable-next-line:max-line-length
            return x.action_name === data.action_name && x.environment_id === data.environment_id && x.stage_name === data.stage_name && x.project_id === data.project_id && x.index === data.index;
        });
        index === -1 ? values.push(data) : values[index] = data;
        this.outputs.next(values);
    }

    getOutputValues(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                const values = this.outputs.getValue();
                resolve(values);
            } catch (e) {
                reject(e);
            }
        });
    }

    getOutputList(type: { id: any, text: any }, environment_id): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                const values = this.outputs.getValue();
                const list = values.filter(x => {
                    return x.outputs.findIndex(y => y.valueType === type.text) !== -1;
                });
                resolve(list);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    getOutputListForCondition() {
        return new Promise<any>((resolve, reject) => {
            try {
                let temp = [];
                const values = this.outputs.getValue();
                const unique_values = [];
                if (this.global_properties != undefined) {
                    Object.keys(this.global_properties).forEach(x => {
                        unique_values.push(x);
                        temp.push({
                            'name': x
                        });
                    });
                }
                values.forEach(x => {
                    let out = x.outputs.map((elem, index) => {
                        if (unique_values.indexOf(elem.name) !== -1) {
                            return null;
                        }
                        unique_values.push(elem.name);
                        elem.action_name = x.action_name;
                        return elem;
                    }).filter(Boolean);
                    temp = temp.concat(out);
                });
                const res = temp.map((x, index) => {
                    x.id = index + 1;
                    x.text = x.name;
                    return x;
                });

                resolve(res);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
    }

    setOldDataToOutputs(data) {
        if (data) {
            this.outputs.next(data);
        } else {
            this.outputs.next([]);
        }
    }

    public setActionList(data) {
        this.actionList = data;
    }

    public setIndexList(data) {
        this.index_list = data;
    }

    public setCategoriesList(data) {
        this.categories_list = data;
    }

    availableActions() {
        return JSON.parse(JSON.stringify(this.actionList));
    }

    indexList() {
        return JSON.parse(JSON.stringify(this.index_list));
    }

    categoriesListGet() {
        return JSON.parse(JSON.stringify(this.categories_list));
    }

    setNameAvailability(data: string) {
        this.nameAvailability.next(data);
    }

    toggleActionPageModal(data: boolean) {
        this.actionPageModal.next(data);
    }

    setUsedPropertiesDetails(data: UsedPropertiesDetails) {
        const values = this.usedPropertiesDetails.getValue();
        const index = values.findIndex(x => {
            return x.stageName === data.stageName && x.blockName === data.blockName && x.usedIn.indexOne === data.usedIn.indexOne && x.usedIn.indexTwo === data.usedIn.indexTwo;
        });
        if (index === -1) {
            values.push(data)
        } else {
            if (values[index].propertiesName !== data.propertiesName) {
                values[index].propertiesName = data.propertiesName;
            } else {
                values[index] = data;
            }
        }
        this.usedPropertiesDetails.next(values);
    }

    setOldDataToUsedProperties(data: UsedPropertiesDetails[]) {
        if (data) {
            data.forEach(element => {
                this.setUsedPropertiesDetails(element);
            });
        } else {
            this.usedPropertiesDetails.next([]);
        }
    }

    getUsedPropertiesDetails() {
        return this.usedPropertiesDetails.getValue();
    }

    removeUsedProperties(data: UsedPropertiesDetails) {
        const values = this.usedPropertiesDetails.getValue();
        const index = values.findIndex(x => {
            return x.stageName === data.stageName && x.blockName === data.blockName && x.usedIn.indexOne === data.usedIn.indexOne && x.usedIn.indexTwo === data.usedIn.indexTwo && x.propertiesName === data.propertiesName;
        });
        if (index !== -1) {
            values.splice(index, 1);
        }
        this.usedPropertiesDetails.next(values);
    }
}
