import { OnDestroy } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { HttpErrorResponse } from '@angular/common/http';

//--
import { GetApiurl,getDynamicActionCompleteApiUrl } from '../core/settings/config';
import { getToken } from '@app/auth/token';

export class InterfaceService {
    subscriptions: Object = {};
    // Creates http options for the data type
    public getHttpOptions(type: 'json'|'xml'|'multipart' = 'json', auth: boolean = true): { headers: HttpHeaders } {
        let headers = new HttpHeaders({});

        // Appending auth header
        if (auth && getToken()) {
            headers = headers.append('Authorization', `JWT ${getToken()}`);
        }

        switch (type) {
            case "json": {
                headers = headers.append('Content-Type', 'application/json');
                break;
            }
            case "multipart": {
                break;
            }
        }
        return {
            headers: headers
        };
    }

    // Handeling error
    protected handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else if (error instanceof HttpErrorResponse) {
            errMsg = error.error ? error.error : error.toString();
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    protected getApiUrl(path: string, params?: Object) {
        return GetApiurl(path, params);
    }
    
    protected getDynamicActionApiUrl(path: string, params?: Object) {
        return getDynamicActionCompleteApiUrl(path, params);
    }

    ngOnDestroy() {

        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(
            ([name, subscription]) => subscription.unsubscribe()
        );
    }

}