import { Injectable } from "@angular/core";

declare var openChat: any;
declare var sendMessage: any;
declare var sendURL: any;
declare var updateRasaName: any;
declare var closeChat: any;

@Injectable({
    providedIn: 'root'
})
export class chatBot
{
    private rasaName = "";
    constructor()
    {
        

    }

    triggerBot(data){
        var intent = data.replace("#","/")
        var name = data.replace("#","");
        if(name.includes("_"))
        {
            name =  name.replace(/_/g," ");
        }
        var obj = { userText: name,rasaText: intent }
        openChat();
        sendMessage(obj);
    }

    updateUrl(url)
    {
        sendURL(url,this.rasaName);

    }

    updateUserName(name)
    {
        this.rasaName = name;
        updateRasaName(name);
        // localStorage.setItem("rasaUserName",name);
    }

    closeChatBot()
    {
        closeChat();
    }
}