import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Location } from '@angular/common';
import { ActionsService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { generateDirectoryTree, directoryListing, FileTemplate, TreeDnD, Action, Separator } from '../monaco-tree-view/index'
import { TreeNode } from '../monaco-tree-view/functions/TreeNode';
import { isNullOrUndefined } from 'util';
declare var $: any;


@Component({
	selector: 'app-actions-editor',
	templateUrl: './actions-editor.component.html',
	styleUrls: ['./actions-editor.component.scss']
})
export class ActionsEditorComponent implements OnInit, OnDestroy, AfterContentChecked {

	errorMessage: string = ''
	startnameval: string = '';
	buttondisable: boolean = true;
	act_tab = 0;
	del_flag: boolean = false;
	obj: any = {};
	editorOptions = { theme: 'vs-dark', language: '', automaticLayout: true };
	code: string = 'function x() { // TODO }';
	code1: string;
	editor: any;
	action_id: any;
	actionData: any;
	tabs: any[] = [];
	active_index: any = 0;
	value: any;
	file_name: string = '';
	loading: boolean = false;
	editorData: any[] = [];
	main_file: boolean = false;
	tab_index: any = 0;
	deleteFileId: any = null;
	// originalCode: string = 'function x() { // TODO }'

	@ViewChild('editor') editorSection: any;

	showEditor = false;
	saveEnableList: any = [];

	rootDirectoryName = 'demo'
	directories: TreeNode;
	treeConfiguration: any;
	lastClickedTime: number;
	lastClickedFile: any;
	directoryList: any = []
	isNewFolderClicked: boolean = false;
	defaultFolderName: string;
	isNewFileClicked: boolean = false;
	defaultFileName: string;
	showTreeView: boolean = false;
	nestedFilePath: any;
	// originalCode: string = 'function x() { // TODO }';
	constructor(
		private location: Location,
		private actionService: ActionsService,
		private route: ActivatedRoute,
		private ts: ToasterService,
		private cdr: ChangeDetectorRef
	) {
		this.action_id = this.route.snapshot.params.action_id;
		this.actionService.getActionDetails(this.action_id).subscribe((data: any) => {
			this.actionData = data;
			this.editorOptions['language'] = data.type_in_word.toLowerCase();
			this.getEditorData(this.action_id);
			setTimeout(() => {
				this.initializeTreeView()
			}, 1000);

		});
	}

	ngAfterContentChecked() {
		this.cdr.detectChanges();
	}

	ngOnDestroy(): void {
		console.log('parent ngOnDestroy called');
	}

	initializeTreeView() {
		this.showTreeView = true
		this.editorData.map((file) => this.directoryList.push(file.name))
		this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)
	}

	ngOnInit() {
		this.showEditor = true;
		this.active_index = 0;
		// this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)
		this.treeConfiguration = {
			dataSource: {
				/**
				 * Returns the unique identifier of the given element.
				 * No more than one element may use a given identifier.
				 */
				getId: function (tree, element) {
					return element.key;
				},

				/**
				 * Returns a boolean value indicating whether the element has children.
				 */
				hasChildren: function (tree, element) {
					return element.isDirectory;
				},

				/**
				 * Returns the element's children as an array in a promise.
				 */
				getChildren: function (tree, element) {
					return Promise.resolve(element.children);
				},

				/**
				 * Returns the element's parent in a promise.
				 */
				getParent: function (tree, element) {
					return Promise.resolve(element.parent);
				},
			},
			renderer: {
				getHeight: function (tree, element) {
					return 24;
				},
				renderTemplate: function (tree, templateId, container) {
					return new FileTemplate(container);
				},
				renderElement: (tree, element, templateId, templateData) => {
					const label = templateData.label as HTMLElement;
					templateData.set(element);
				},
				disposeTemplate: function (tree, templateId, templateData) {
					console.log('dispose', tree,);
					// FileTemplate.dispose();
				}
			},

			//tree config requires a controller property but we would defer its initialisation
			//to be done by the MonacoTree component
			//controller: createController(this, this.getActions.bind(this), true),
			dnd: new TreeDnD()
		};
	}

	handleNewFileNaming(event, file, isClickedOnParentLevel, inputField) {
		let currentFileName = event.target.value;
		if (isClickedOnParentLevel)
			this.directoryList.push(currentFileName)
		else {
			currentFileName = `${file.path}/${currentFileName}`
			this.directoryList.push(currentFileName)
		}
		this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)
		this.isNewFileClicked = false
		this.addFileToTabs(currentFileName)
		inputField.parentElement.parentElement.parentElement.parentElement.remove()
	}

	handleNewFolderNaming(event, file, isClickedOnParentLevel, inputField) {
		const currentFolderName = event.target.value

		if (isClickedOnParentLevel)
			this.directoryList.push(`${currentFolderName}/`)
		else {
			const folderPath = `${file.path}/${currentFolderName}/`
			this.directoryList.push(folderPath)
		}
		this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)
		this.isNewFolderClicked = false
		inputField.parentElement.parentElement.parentElement.parentElement.remove()
	}

	/***
	 * open created file in tabs
	 */
	addFileToTabs(newFileName: string) {
		const tabsLength = this.tabs.length
		const newFile = {
			id: 0,
			name: newFileName,
			file_name: newFileName,
			main_file: false,
			editor: ''

		}
		this.nestedFilePath = '';
		this.tabs.push(newFile),
			this.active_index = tabsLength - 1;
		this.saveFiles(newFile)
	}

	private openFilesInTabs(file: any) {
		const fileName = file.name;
		const filePath = file.filePath;

		const index = this.tabs.findIndex((tab) => tab.name == filePath);

		if (index > -1) {
			this.active_index = index;
		}
	}

	/**
	 * this will save file name when every new file created not data
	 */
	saveFiles(value) {
		value['data'] = value['editor']
		this.actionService.saveEditorData(this.action_id, value).subscribe(data => {
			console.log('saveFiles', data);
		});
	}

	/**
	 * Context Menu,
	 * Can add Any custom action here,
	 * Get Action
	 */
	getActions(file, event) {
		const actions = [];
		const isClickedOnParentLevel = isNullOrUndefined(file.parent)

		// Directory options
		if (file.isDirectory) {

			actions.push(new Action("1", "New File", "", true, () => {
				console.log("action New File on " + file.name);
				this.isNewFileClicked = true;
				let label = event.target;
				if (isClickedOnParentLevel) {
					let monacoTreeRow = document.createElement('div')
					monacoTreeRow.className = 'monaco-tree-row'

					let content = document.createElement('div')
					content.className = 'content'

					let monacoIconLabel = document.createElement("div");
					monacoIconLabel.className = "monaco-icon-label";
					monacoIconLabel.style.display = "flex";

					let labelDescriptionContainer = document.createElement('div');
					labelDescriptionContainer.className = 'monaco-icon-label-description-container'
					labelDescriptionContainer.style.overflow = "hidden";
					labelDescriptionContainer.style.textOverflow = "ellipsis";
					labelDescriptionContainer.style.whiteSpace = "nowrap";

					const input = document.createElement('input')
					input.classList.add('label-name')
					input.classList.add('new-folder-input')
					input.classList.add('new-folder-parent-level')

					input.addEventListener('keypress', (event: any) => {
						if (event.key === 'Enter')
							this.handleNewFileNaming(event, file, isClickedOnParentLevel, input)
					})
					input.addEventListener('focusout', (event: any) => {
						event.target.remove();
						console.log('element removed');
					})
					content.appendChild(monacoIconLabel)
					monacoIconLabel.appendChild(labelDescriptionContainer)
					labelDescriptionContainer.appendChild(input)
					monacoTreeRow.appendChild(content)
					label.prepend(monacoTreeRow)
					input.focus()
				} else {
					if (label.classList[0] == 'monaco-icon-label')
						label = label.parentElement.parentElement
					else if (label.className == 'monaco-icon-label-description-container')
						label = label.parentElement.parentElement.parentElement
					else
						label = label.parentElement.parentElement.parentElement.parentElement

					let expandedMonacoTreeRow = document.createElement('div');
					expandedMonacoTreeRow.className = 'monaco-tree-row has-children'
					expandedMonacoTreeRow.style.height = "24px";
					expandedMonacoTreeRow.style.paddingLeft = "44px";

					let content = document.createElement('div')
					content.className = 'content'

					let monacoIconLabel = document.createElement("div");
					monacoIconLabel.className = "monaco-icon-label";
					monacoIconLabel.style.display = "flex";

					let labelDescriptionContainer = document.createElement('div');
					labelDescriptionContainer.className = 'monaco-icon-label-description-container'
					labelDescriptionContainer.style.overflow = "hidden";
					labelDescriptionContainer.style.textOverflow = "ellipsis";
					labelDescriptionContainer.style.whiteSpace = "nowrap";

					const input = document.createElement('input')
					input.classList.add('label-name')
					input.classList.add('new-folder-input')
					input.addEventListener('keypress', (event: any) => {
						if (event.key === 'Enter')
							this.handleNewFileNaming(event, file, isClickedOnParentLevel, input)
					})
					input.addEventListener('focusout', (event: any) => {
						event.target.remove();
						console.log('element removed');
					})
					content.appendChild(monacoIconLabel)
					monacoIconLabel.appendChild(labelDescriptionContainer)
					labelDescriptionContainer.appendChild(input)
					expandedMonacoTreeRow.appendChild(content)
					label.insertAdjacentElement('afterend', expandedMonacoTreeRow)
					input.focus()
				}
			}));

			//menu separator
			actions.push(new Separator());

			actions.push(new Action("2", "New Directory", "", true, async () => {
				this.isNewFolderClicked = true;
				console.log("action New Directory on " + file.name);
				let label = event.target;
				if (isClickedOnParentLevel) {

					let monacoTreeRow = document.createElement('div')
					monacoTreeRow.className = 'monaco-tree-row'

					let content = document.createElement('div')
					content.className = 'content'

					let monacoIconLabel = document.createElement("div");
					monacoIconLabel.className = "monaco-icon-label";
					monacoIconLabel.style.display = "flex";

					let labelDescriptionContainer = document.createElement('div');
					labelDescriptionContainer.className = 'monaco-icon-label-description-container'
					labelDescriptionContainer.style.overflow = "hidden";
					labelDescriptionContainer.style.textOverflow = "ellipsis";
					labelDescriptionContainer.style.whiteSpace = "nowrap";

					const input = document.createElement('input')
					input.classList.add('label-name')
					input.classList.add('new-folder-input')
					input.classList.add('new-folder-parent-level')

					input.addEventListener('keypress', (event: any) => {
						if (event.key === 'Enter')
							this.handleNewFolderNaming(event, file, isClickedOnParentLevel, input)
					})
					input.addEventListener('focusout', (event: any) => {
						event.target.remove();
						console.log('element removed');
					})
					content.appendChild(monacoIconLabel)
					monacoIconLabel.appendChild(labelDescriptionContainer)
					labelDescriptionContainer.appendChild(input)
					monacoTreeRow.appendChild(content)
					label.prepend(monacoTreeRow)
					input.focus()
				}
				else {
					if (label.classList[0] == 'monaco-icon-label')
						label = label.parentElement.parentElement
					else if (label.className == 'monaco-icon-label-description-container')
						label = label.parentElement.parentElement.parentElement
					else
						label = label.parentElement.parentElement.parentElement.parentElement

					let expandedMonacoTreeRow = document.createElement('div');
					expandedMonacoTreeRow.className = 'monaco-tree-row has-children'
					expandedMonacoTreeRow.style.height = "24px";
					expandedMonacoTreeRow.style.paddingLeft = "44px";

					let content = document.createElement('div')
					content.className = 'content'

					let monacoIconLabel = document.createElement("div");
					monacoIconLabel.className = "monaco-icon-label";
					monacoIconLabel.style.display = "flex";

					let labelDescriptionContainer = document.createElement('div');
					labelDescriptionContainer.className = 'monaco-icon-label-description-container'
					labelDescriptionContainer.style.overflow = "hidden";
					labelDescriptionContainer.style.textOverflow = "ellipsis";
					labelDescriptionContainer.style.whiteSpace = "nowrap";

					const input = document.createElement('input')
					input.classList.add('label-name')
					input.classList.add('new-folder-input')
					input.addEventListener('keypress', (event: any) => {
						if (event.key === 'Enter')
							this.handleNewFolderNaming(event, file, isClickedOnParentLevel, input)
					})
					input.addEventListener('focusout', (event: any) => {
						event.target.remove();
						console.log('element removed');
					})
					content.appendChild(monacoIconLabel)
					monacoIconLabel.appendChild(labelDescriptionContainer)
					labelDescriptionContainer.appendChild(input)
					expandedMonacoTreeRow.appendChild(content)
					label.insertAdjacentElement('afterend', expandedMonacoTreeRow)
					input.focus()
				}

			}));


		}



		actions.push(new Action("5", "Delete", "", true, () => {
			console.log("action Delete on " + file.name);

			let filePath = file.path;
			if (file.isDirectory) {
				filePath = !filePath.endsWith('/') ? `${filePath}/` : filePath
				const filteredDirectories: any[] = this.directoryList.filter((directory) => directory.includes(filePath))
				filteredDirectories.map((directory) => {
					let index = this.directoryList.indexOf(directory)
					this.directoryList.splice(index, 1)
					const tabIndex = this.tabs.findIndex((tab) => tab.name == directory);
					if (tabIndex > -1) {
						const tab = this.tabs[tabIndex]
						if (tab['id']) {
							this.deleteEditorFile(tab['id'])
						} else {
							this.tabs.splice(tabIndex, 1)
							this.del_flag = true;
							this.act_tab = this.tabs.length - 1;
							this.active_index = 1;
							this.tab_index = 1;
						}
					}
				})

			}
			else {
				const index = this.directoryList.findIndex((value) => value == filePath)
				this.directoryList.splice(index, 1)
				const tabIndex = this.tabs.findIndex((tab) => tab.name == filePath);
				const tab = this.tabs[tabIndex]

				if (tab['id']) {
					this.deleteEditorFile(tab['id'])
				} else {
					this.tabs.splice(tabIndex, 1)
					this.del_flag = true;
					this.act_tab = this.tabs.length - 1;
					this.active_index = 1;
					this.tab_index = 1;
				}
			}

			this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)
		}));

		if (!isClickedOnParentLevel)
			actions.push(new Action("6", "Rename", "", true, () => {
				const label = event.target as HTMLElement

				const input = document.createElement('input')
				input.classList.add(label.className)
				input.classList.add('new-folder-input')
				input.addEventListener('keypress', (event: any) => {
					if (event.key === 'Enter')
						this.handleRenameFileOrFolder(file.name, event)
				})
				label.parentElement.appendChild(input)
				input.focus()
				label.style.display = 'none';

			}));


		return actions;
	}

	handleRenameFileOrFolder(oldName: string, event: any) {
		const newName = event.target.value;
		this.directoryList = this.directoryList.map(value => value.replace(oldName, newName))
		this.directories = generateDirectoryTree(this.directoryList, this.rootDirectoryName)

		const tabIndex = this.tabs.findIndex((tab) => tab.name == oldName);
		if (tabIndex > -1) {
			const tab = this.tabs[tabIndex]
			tab.name = newName;
			if (tab['id']) {
				// already saved and opened in tab
				console.log('if rename', tab);
				tab['data'] = tab['editor'];
				// delete tab['editor']
				// this.saveFiles(tab)
				// this.tabs.splice(tabIndex, 1, tab)
				// this.del_flag = true;
				// this.act_tab = this.tabs.length - 1;
				// this.active_index = 1;
				// this.tab_index = 1;
			} else {
				// justnow created and opened in tab
				console.log(' else rename', tab);

				this.tabs.splice(tabIndex, 1, tab)
				this.del_flag = true;
				this.act_tab = this.tabs.length - 1;
				this.active_index = 1;
				this.tab_index = 1;
			}

		}
		console.log("action Rename on " + newName);
	}

	onClickFile(file) {
		console.log('file', file);
		if (!file.isDirectory) {
			this.openFilesInTabs(file);
		}
		if (file.isDirectory) {
			return;
		}

		if (Date.now() - this.lastClickedTime < 500 && this.lastClickedFile === file) {
			this.onDoubleClickFile(file);
		}
		else {
			console.log(file.name + " clicked");
		}

		this.lastClickedTime = Date.now();
		this.lastClickedFile = file;

	}

	onDoubleClickFile(file: any) {
		console.log(file.name + " double clicked");
	}

	onInit(editor, idx, tab) {
		this.showEditor = true;
		this.editor = editor;
		if (tab && tab.hasOwnProperty('editor')) {
			this.editor.setValue(tab['editor'])
		} else {
			this.editor.setValue(this.editor.getId());
		}

		this.value = this.editor.getValue();
		// if(this.active_index == 0) {
		this.tabs[idx]['editor'] = this.editor.getValue();
		console.log('tabs', this.tabs);

		// }
		editor.onDidChangeModelContent(e => {
			this.editor = editor;
			// console.log('editor',editor.getValue(),this.tabs[idx]['name'])
			this.saveEnableList.indexOf(idx) === -1 && this.saveEnableList.push(idx);
			this.obj[this.tabs[idx]['name']] = editor.getValue();
			// editor.getValue() => will get value when type in a editor(stream)
		});
	}

	// to navigate to previous page 
	goBack() {
		this.location.back();
	}

	// on editor data save 
	onSave(tab, i) {
		this.errorMessage = '';
		this.startnameval = tab.name;
		// TODO: Disable save button if no changes in content this will save from ton of issues

		// this will contain old data before  saving
		// this.editor = tab['editor'].
		// console.log(this.editor.getValue());
		const fileId = tab.id;
		// this.file_name = tab.file_name;
		this.file_name = tab.name;
		this.main_file = tab.main_file;
		this.tab_index = i;
		// console.log("save",this.tab_index)
		// this.removeExistingMainFile(fileId)
		$('#fileNameModal').modal('show');
	}

	onClick() {
		// let id = this.tabs[this.tabs.length - 1].id + 1;
		try {
			if (this.tabs[this.tabs.length - 1]['name'].indexOf('File') > -1) {
				let i = parseInt(this.tabs[this.tabs.length - 1]['name'].replace('File', '')) + 1;
				this.tabs.push({ id: 'file' + i, name: 'File' + i, file_name: 'File' + i, main_file: false });
			} else {
				this.tabs.push({ id: 'file1', name: 'File1', file_name: 'File1', main_file: false });
			}
			this.obj[(this.tabs[Number(Object.keys(this.tabs)[Object.keys(this.tabs).length - 1]) - 1])["name"]] = this.editor.getValue();
		}
		catch (err) {
			this.tabs.push({ id: 'file1', name: 'File1', file_name: 'File1', main_file: false });
		}
		// console.log("valueeeeee",(this.tabs[Number(Object.keys(this.tabs)[Object.keys(this.tabs).length-1])-1])["name"] ,this.editor.getValue());

		this.onChange(this.tabs.length - 1)
	}

	onChange(idx, tab?: any) {
		this.showEditor = true;
		this.active_index = idx;
		// console.log(this.active_index)
		if (tab && tab.hasOwnProperty('editor')) {
			this.editor.setValue(tab['editor'])
		}
		this.del_flag = false;
	}


	onNameChange(nameval) {
		this.errorMessage = '';
		if (nameval.length > 100) {
			this.errorMessage = ' File name should be less than or equal to 100';
			this.buttondisable = false;
			return;
		}
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
		if(format.test(nameval)) {
			this.errorMessage = ' Remove space and special char';
			this.buttondisable = false;
			return;
		}
		let errorMessages = " The Name already exists";
		this.buttondisable = true;
		if (this.startnameval != nameval) {
			let ispresentflag = this.tabs.find(o => o.name === nameval);
			if (typeof ispresentflag != "undefined") {
				this.buttondisable = false;
				this.errorMessage = errorMessages;
			}
			ispresentflag = {};
			delete this.obj[nameval];
		}
	}

	// to get file name 
	getFileName() {
		this.loading = true;
		this.buttondisable = true;
		// this.file_name = $('input[name="file_name"]').val();
		// console.log('actionId',this.action_id)

		// console.log("filesave",this.tabs,this.file_name,this.obj)
		// console.log("names",this.tabs.map(({ name }) => name))

		let val = { 'file_name': this.file_name, data: this.editor.getValue(), 'main_file': this.main_file }
		this.actionService.saveEditorData(this.action_id, val).subscribe(data => {
			this.saveEnableList = this.saveEnableList.filter(x => x !== this.tab_index);
			this.loading = false;
			this.ts.pop('success', 'Editor data saved');
			$('#fileNameModal').modal('hide');
			const isMainFile = data['data'].is_main_file;
			// console.log("all",data)
			// this.removeExistingMainFileInUI(isMainFile, data);Object.keys(this.obj)
			// console.log("ss",Object.keys(this.obj),data['data'].id)

			let temp = Object.keys(this.obj).map(name => name.toLowerCase());

			console.log("dd", temp, this.obj);
			// console.log("ed",this.editor.getValue());

			if (Object.keys(this.obj).includes(data['data'].name)) {
				console.log(this.obj[data['data'].name], this.editor.getValue())


				this.tabs[this.tab_index] = {
					id: data['data'].id, name: data['data'].name, editor: this.obj[data['data'].name],
					file_name: data['data'].name, main_file: data['data'].is_main_file
				};
				delete this.obj[data['data'].name];
			}
			else {

				// 	this.tabs[this.tab_index] = {id: data['data'].id, name: data['data'].name, editor: this.editor.getValue(),
				// 							file_name:data['data'].name, main_file:data['data'].is_main_file}
				this.tabs[this.tab_index] = {
					id: data['data'].id, name: data['data'].name, editor: data['data'].file_content,
					file_name: data['data'].name, main_file: data['data'].is_main_file
				}
			}



			// console.log("all",data)
			this.removeExistingMainFileInUI(isMainFile, data);
			if (data['data'].is_main_file) {
				this.removeExistingMainFile(data['data'].id) // at backend level
			}
		}, error => {
			$('#fileNameModal').modal('hide');
			this.loading = false;
			this.ts.pop('error', error.detail)
		})
		this.del_flag = false;
	}

	private removeExistingMainFileInUI(isMainFile: boolean, data: Object) {
		if (isMainFile) {
			const currentFileIndex = this.tabs.findIndex(file => file.id === data['data'].id);
			if (currentFileIndex >= 0) {
				this.tabs.map((file, otherFileIndex) => {
					if (otherFileIndex != currentFileIndex) {
						file['main_file'] = false;
					}
				});
			}
		}
	}

	// file name modal close 
	closeModal() {
		$('#fileNameModal').modal('hide');
		this.startnameval = "";
	}

	onEnterFileName(event) {
		this.file_name = event.target.value;
	}

	// to get existing editor data 
	getEditorData(action_id) {
		this.tabs = [];
		this.actionService.getActionEditorData(action_id).subscribe(response => {
			if (response && response['data'].length > 0) {
				this.editorData = response['data'];
				response['data'].forEach(element => {
					this.tabs.push({ id: element.id, 'name': element.name, 'editor': element.file_content, 'file_name': element.name, 'main_file': element.is_main_file });
				});
			} else {
				this.tabs = [{ id: 'file1', 'name': 'File1', file_name: '', main_file: false }]
			}
			this.showEditor = true;
			this.active_index = 0;
		})
	}

	deleteEditorFile(fileId: number) {
		this.actionService.deleteEditorFile(fileId).subscribe(response => {
			if (response && response['data'].length > 0) {
				$('#editor-delete-modal').modal('hide');
				this.ts.pop('success', 'Editor Data Deleted')
				// console.log("del",this.tabs,(this.tabs).length-1,this.active_index)
				this.del_flag = true;
				this.tabs = this.tabs.filter((item) => item.id !== fileId);//to delete in frontend side
				this.act_tab = this.tabs.length - 1;
				this.active_index = 1;
				this.tab_index = 1;
				// console.log("len",this.act_tab)
				// this.getEditorData(this.action_id)
			}
		}, error => {
			this.ts.pop('error', error.detail)
		})
	}

	removeExistingMainFile(fileId: number) {
		this.actionService.removeExistingMainFile(this.action_id, fileId).subscribe(response => {
			if (response && response['data'].length > 0) {
				return true
			}
		}, error => {
			this.ts.pop('error', error.detail)
		})
	}

	openDeleteModal(fileId) {
		this.deleteFileId = fileId;
		$('#editor-delete-modal').modal('show');
	}

	closeDeleteModal() {
		$('#editor-delete-modal').modal('hide');
		this.deleteFileId = null;
	}

	isNumberType(value: any) {
		return typeof (value) === 'number'
	}

	handleDeleteModal() {
		// uncomment this code only if default file also needs to be deleted
		// if (! this.isNumberType(this.deleteFileId)){
		// 	const fileTobeDeleted:object = this.tabs.find(({id})=>id===this.deleteFileId)
		// 	const fileIndex = this.tabs.indexOf(fileTobeDeleted)
		// 	this.tabs.splice(fileIndex,1)
		// 	$('#editor-delete-modal').modal('hide');
		// 	this.ts.pop('success','Deleted Successfully')
		// }
		this.deleteEditorFile(this.deleteFileId);
	}

}
