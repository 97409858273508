import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { e } from '@angular/core/src/render3';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { GetApiurl, podLoggerBaseUrl } from '@app/core';
import { ActionsService, ActionPagenationService, PipelineService } from '@app/services';
import { Config } from '@app/shared/directives/custom-datatable/interface';
import { ToasterService } from 'angular2-toaster';
import { environment } from 'src/environments/environment';
import { HandleSomeFunctions } from '@app/features/home/pipeline/pipeline/workflow-setup/handle-some-functions';
import { Terminal } from 'xterm';
import { attach } from "xterm/lib/addons/attach/attach";
import { Location } from '@angular/common';
import { SettingsService } from '@app/core';
import { isNullOrUndefined, isObjectsEqual } from '@app/utils/utils';
import { TestActionInfo } from './interfaces/action-list-interface';
import { ReplaySubject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'app-action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {

    charlen: boolean = false;
    isvaild: boolean = false;
    columns: any;
    actionsForm: FormGroup;
    action_types: any[] = [];
    uploaded_file: any = undefined;
    filetype: any;
    imageUploaded: boolean = false;
    imageSrc: any;
    mode: string = 'Add';
    actionData: any;
    action_type: any;
    action_id: any;
    loading: boolean = false;
    imageName: any;
    action: string;
    actions_load: boolean = false;
    input_process: boolean = false;
    action_process: boolean = false;
    application_id = null;
    modal_msg = "Uploading JSON configuration to server.";
    editData = null;
    actionInfo: any;
    json_obj = {};
    show_test_action: boolean = false;
    pipeline_action: any;
    run_name: any;
    run_status: any;
    task_log: any;
    selected_action: any = 1;
    isShowLogIcon: boolean = false;
    isInprogress: boolean = false
    testPipelineStatus: string;
    currentTestActionId: number = 0;
    selectedTestActionId: number = 0;
    interval: any;
    podLog: string;
    isEnableLiveLog: boolean = false;
    isDisabledLogIcon: boolean = false;
    scrollInterval: any;
    socket: WebSocket;
    oldEditActionFormValues: any;
    isDisableUpdateBtn: boolean = true;
    actionStatus: TestActionInfo[] = [];

    @ViewChild('appDataTable') appDataTable: any;
    @ViewChild('tableActionsTpl') tableActionsTpl: any

    config: Config = {
        screenName: '',
        remoteUrl: 'actions/',
        remoteParams: {},
        pageSize: 10,
        tableButton: { text: 'Add Project', show: false, access: [], icon: 'fa fa-plus' },
        removeTableBorder: true,
        tableClass: 'table-custom-css-project',
        hidePagination: false
    };
    enableInputFormEditor: boolean = false;
    editorData: any[] = [];
    enableActionEditor: boolean = false;
    superUser: any;
    enableBack: boolean = false;
    active_pagination = 10;
    pod: boolean = false;
    refreshInterval: any;
    currentShow: number = 1;
    rowActionLoading = false;
    isErrorImageType: boolean = false;
    imageNameError = false;
    nameExits = false;
    domainName = '';
    readonly searchString$ = new ReplaySubject<boolean>(1);

    constructor(
        private fb: FormBuilder,
        private actionService: ActionsService,
        private router: Router,
        private ts: ToasterService,
        private route: ActivatedRoute,
        private handleSomeFunctions: HandleSomeFunctions,
        private location: Location,
        private settingsService: SettingsService,
        private pipelineService: PipelineService,
        private pageState: ActionPagenationService,
        private cdr: ChangeDetectorRef
    ) {
        this.route.parent.params.subscribe((params: Params) => {
            if ('id' in params) {
                this.application_id = params['id'];
                // console.log(this.application_id,"application id")
                this.config['remoteParams'] = { application: this.application_id };
            }
        });
        // if(this.route.snapshot.parent.params.app_id) {
        //     this.application_id = this.route.snapshot.parent.params.app_id;
        //     this.config.remoteParams['application'] = this.application_id;
        // }
        this.actionService.get_action_types().subscribe((data: any) => {
            this.action_types = data
        })
        // this.actionService.getAllInputs(21).subscribe((data: any) =>{
        //     this.action_types = data
        // })
        // this.actionService.getActionEditorData(id).subscribe((data: any) =>{
        //     this.action_types = data
        // })

    }

    ngAfterViewInit(): void {
        let element = document.querySelectorAll(".pagination li.pagination-page");
        // let element1 = document.querySelectorAll(".pagination li.pagination-page")
        // console.log("ele",element)
        // element.style.backgroundColor="blue";
        this.settingsService.user$.subscribe(settings => {
            this.domainName = settings['domain_name'];
        })
        this.handleSomeFunctions.getActionPageModal.subscribe(res => {
            if (res) {
                this.handleSomeFunctions.toggleActionPageModal(false);
                setTimeout(() => {
                    this.open_modal();
                }, 100);
            }
        })
    }

    ngOnInit() {
        // this.getStaticDetails();
        this.recoverLastPage();
        this.load_table_columns();
        this.settingsService.user$.subscribe(res => {
            this.superUser = res
            if (this.superUser["isSuperUser"]) {
                this.enableBack = true;
            }
        });
        $('#cancel-confirmation').on('hidden.bs.modal', () => {
            $('body').css('overflow', 'hidden');
            $('#actionModal').css('overflow', 'auto');
        })
        $('#cancel-confirmation').on('show.bs.modal', () => {
            $('body').css('overflow', 'auto');
        })
        $('#actionModal').on('hide.bs.modal', () => {
            $('body').css('overflow', 'auto');
        });
        $('#actionModal').on('show.bs.modal', () => {
            $('body').css('overflow', 'hidden');
        });
    }

    ngAfterContentChecked() {
        this.cdr.detectChanges();
    }


    charlong(word) {
        this.charlen = false;
        this.isvaild = false;

        if (word.includes('_')) {
            this.isvaild = true;
        }
        // if (word.length > 50) {
        //     this.charlen = true;
        // }
    }

    load_table_columns() {
        this.columns = [
            {
                name: 'Action Name',
                prop: 'name',
                sortable: true,
                cellTemplate: '',
                cellClass: 'project-create-pop',
                headerClass: 'action_hdclass'
            },
            {
                name: 'Description',
                prop: 'description',
                sortable: false,
                cellTemplate: '',
                cellClass: 'project-create-pop',
                headerClass: 'action_description'
            },
            {
                name: 'Status',
                prop: 'status_in_word',
                sortable: false,
                cellTemplate: '',
                cellClass: 'action-status'
            },
            {
                name: '',
                cellTemplate: this.tableActionsTpl,
                headerClass: 'action-status',
                sortable: false,
                prop: 'Action',
                cellClass: 'action-status'
            }
        ]
    }

    // open add/edit modal
    open_modal() {
        this.loading = false;
        this.mode = 'Add';
        this.action_type = [];
        this.imageSrc = '';
        this.uploaded_file = undefined;
        this.imageUploaded = false;
        this.buildForm();
        this.rowActionLoading = false;
        $('#actionModal').appendTo('body').modal('show');
    }

    // actions form build
    buildForm(data?: any) {
        this.actionsForm = this.fb.group({
            'name': [data ? data.name : '', [Validators.required, Validators.maxLength(50), Validators.pattern(/^([a-z][a-z0-9-]*)$/)]],
            'description': [data ? data.description : '', [Validators.required, Validators.maxLength(250), Validators.pattern(/^\S(?:.*\S)?$/)]],
            'type': [data ? data.type : '', Validators.required],
        });
        this.actionsForm.controls['name'].valueChanges.takeUntil(this.searchString$).pipe(
            tap(() => { }),
            debounceTime(400),
            switchMap(async (search) => {
                this.actionService.isActionNameExist(search, 'name', this.domainName, '').subscribe();
            }),
            tap(() => { })
        ).subscribe();
    }

    // On Row Click
    onRowAction(data, action, elementRef: any = null) {
        this.rowActionLoading = true;
        if (elementRef && elementRef.dataset.testActionId) {
            this.currentTestActionId = elementRef.dataset.testActionId;
        }
        this.actionData = data;
        this.action_id = data.id;
        // if (action == 'test') {
        //     this.action = action;

        // } else 
        if (action == 'edit') {
            this.isDisableUpdateBtn = true;
            this.mode = 'Edit';
            if (data.logo) {
                this.imageUploaded = true;
                this.imageSrc = GetApiurl(data['logo'].substr(1)) + "?" + Math.floor(Math.random() * 100) + 1
                this.imageName = data.logo.replace('media/', "")
            } else {
                this.imageName = '';
                this.imageUploaded = false;
            }
            this.enableInputFormEditor = false;
            if (this.actionData.internal_name && data.action_name != null) {
                this.enableInputFormEditor = true;
            } else {
                this.enableInputFormEditor = false;
            }
            this.actionService.getActionEditorData(this.action_id).subscribe(response => {
                this.enableActionEditor = false;
                if (response && response['data'].length > 0) {
                    this.enableActionEditor = true;
                } else {
                    this.enableActionEditor = false;
                }
                this.rowActionLoading = false;
            }, error => {
                this.rowActionLoading = false;
            });
            let index = this.action_types.findIndex(type => type.id == data.type)
            this.action_type = [this.action_types[index]];
            this.buildForm(data);
            this.oldEditActionFormValues = this.actionsForm.value;
            $('#actionModal').appendTo('body').modal('show');
        } else if (action == 'log') {
            this.getJobLogs();
            this.rowActionLoading = false;
        }
        else if (action == 'live-log') {
            this.getPodLogParameters();
            this.rowActionLoading = false;
        }
        else {
            $('#actionsModal').modal('show');
            this.action = action;
            this.rowActionLoading = false;
        }
    }

    hasFormChanged() {
        this.actionsForm.valueChanges.subscribe((latestValues) => {
            this.isDisableUpdateBtn = isObjectsEqual(this.oldEditActionFormValues, latestValues)
        })
    }

    callActionPipelineStatusEveryFiveSeconds() {
        // this.getActionPipelineStatus(this.interval)
        // this.getPodStatus(this.interval)

        // this.interval = setInterval(()=> {
        //     this.getActionPipelineStatus(this.interval)
        //     this.getPodStatus(this.interval)
        //     }, 5000);
        this.actionStatus.map((actionStatusObject) => {
            // if (!actionStatusObject.isPodCompleted) {
            // console.log('obj1',actionStatusObject)
            const interval: any = setInterval(() => {
                this.getPodStatus(interval, actionStatusObject.actionId)
                this.getActionPipelineStatus(interval, actionStatusObject.actionId)
            }, 5000)
            // }
        })

    }

    callJobsLogEveryFiveSeconds() {
        // this.interval = setInterval(()=>{

        //     // this.getJobLogs(this.interval)
        // },5000)
        this.actionStatus.map((actionStatusObject) => {
            if (!actionStatusObject.isJobLogContainSomeText) {
                const interval: any = setInterval(() => {
                    this.getJobLogs(interval, actionStatusObject.actionId);
                }, 5000)
            }
        })
    }

    getActionPipelineStatus(interval: number = 0, actionId: number = 0) {
        actionId = actionId == 0 ? this.action_id : actionId
        this.actionService.getActionPipelineStatus(actionId).subscribe(data => {
            this.testPipelineStatus = data['status'];
            if (this.testPipelineStatus == 'Completed') {
                if (interval != 0) {
                    clearInterval(interval);
                    // this.callJobsLogEveryFiveSeconds();
                }
                this.isInprogress = false;
                this.isShowLogIcon = false
                this.toggleInProgress(actionId, true)
                // this.toggleLogElement();
                this.showLogAndDeployIcons();
            }

        }, error => {
            this.ts.pop('error', error)
        })
    }

    // getStaticDetails() {

    //     if (!this.pod){
    //     this.pipelineService.getStaticData(this.application_id).subscribe((resp: boolean) => {
    //         if (resp['status'] == "completed") {
    //             this.pod = true;
    //         } 

    //     }, error => this.pod=false);}
    //     else {
    //         this.pod = false;
    //         this.refreshInterval = setInterval(() => {
    //             this.getStaticDetails();
    //         }, 1000)
    //     }
    // }


    getPodStatus(interval: number = 0, actionId: number = 0) {
        actionId = actionId == 0 ? this.action_id : actionId
        try {
            this.actionService.getPodStatus(actionId).subscribe(data => {

                if (data['ready'] && data['started']) {
                    this.isEnableLiveLog = true;

                    this.toggleLiveLog(actionId, true);
                    console.log('pod started')
                }
                else if (!isNullOrUndefined(data['state']['terminated'])) {
                    try {
                        const completedStatus = data['state']['terminated']['reason']
                        if (completedStatus === 'Completed') {
                            this.isInprogress = false;
                            this.isEnableLiveLog = false;

                            this.toggleInProgress(actionId, true)
                            this.toggleLiveLog(actionId, false);
                            this.socket.close(1000, 'complete')
                            if (interval != 0) {
                                clearInterval(interval);
                                clearInterval(this.scrollInterval);
                                this.callJobsLogEveryFiveSeconds();
                            }
                            this.showLogAndDeployIcons();
                        }
                    } catch (error) {
                        console.warn('error', error)
                    }

                }
            }, error => this.ts.pop('error', error['detail']))
        } catch (error) {
            console.warn('err', error)
        }
    }

    private toggleLiveLog(actionId: number, isEnabled: boolean) {
        let index = this.actionStatus.findIndex((item) => {
            return item.actionId == actionId;
        });

        const actionStatusObject = this.actionStatus[index];
        actionStatusObject.isEnableLiveLog = isEnabled;
        this.actionStatus.splice(index, 1, actionStatusObject);
    }

    private toggleInProgress(actionId: number, isEnabled: boolean) {
        let index = this.actionStatus.findIndex((item) => {
            return item.actionId == actionId;
        });

        const actionStatusObject = this.actionStatus[index];
        actionStatusObject.isPodCompleted = isEnabled;
        this.actionStatus.splice(index, 1, actionStatusObject);
    }

    private showLogAndDeployIcons() {
        try {
            this.appDataTable.refreshData();
            this.isDisabledLogIcon = true;
            this.showIcons();
        } catch (error) {
            this.appDataTable.refreshData();
            this.showIcons();
        }
    }

    showIcons(actionId: number = 0): void {
        actionId = actionId == 0 ? this.currentTestActionId : actionId
        const currentLogELement = document.getElementById(`log-${actionId}`);
        const currentDeployELement = document.getElementById(`deploy-${actionId}`);
        if (!isNullOrUndefined(currentLogELement)) {
            currentLogELement.style.display = 'inline-block';
        }
        if (!isNullOrUndefined(currentDeployELement)) {
            currentDeployELement.style.display = 'inline-block';
        }
    }

    getPodLogParameters(isIntial = false) {
        this.actionService.getPodLogParams(this.action_id).subscribe(data => {
            const podName = data['pod_name']
            const containerType = data['container_type']
            const nameSpace = data['namespace']
            const url = `${podLoggerBaseUrl}logs?pod=${podName}&container=${containerType}&namespace=${nameSpace}`
            if (!isIntial)
                $('#live-log-modal').modal('show');

            this.webSocketLogs(url)

        }, error => {
            this.ts.pop('error', error)
        })
    }

    getJobLogs(interval: number = 0, actionId: number = 0) {
        actionId = actionId == 0 ? this.action_id : actionId
        this.actionService.actionLog(actionId).subscribe(data => {
            this.pipeline_action = data['logs'];
            //    if(Object.keys(this.pipeline_action).length){
            this.run_name = this.pipeline_action['task_name'];
            this.run_status = this.pipeline_action['status'];
            if (interval == 0) {
                $('#log_modal').modal('show');
            }
            // else {
            //     clearInterval(this.interval)
            // }
            let actionStatusObject = {
                id: actionId,
                actionId: actionId,
                isDisabledLog: false,
                podStatus: '',
                isPodCompleted: false,
                isJobLogContainSomeText: false,
                isEnableLiveLog: false,
                jobLog: 'No logs to display',
                jobProperties: 'No properties to display'
            }
            let index = this.actionStatus.findIndex((item) => {
                return item.actionId == actionId
            })
            if (index != -1) {
                this.actionStatus.splice(index, 1, actionStatusObject)
            } else {
                this.actionStatus.push(actionStatusObject);
            }
            if (this.selected_action == 1) {
                if (this.pipeline_action.hasOwnProperty('logs') && this.pipeline_action['logs'] != "<br/>") {
                    let index = this.actionStatus.findIndex((item) => {
                        return item.actionId == actionId;
                    });

                    const actionStatusObject = this.actionStatus[index];
                    actionStatusObject.jobLog = this.pipeline_action['logs'];
                    this.actionStatus.splice(index, 1, actionStatusObject);
                    const logsSection = document.getElementById('logs-modal');
                    // logsSection.innerHTML = this.pipeline_action['logs'];
                    if (this.pipeline_action['status'] == 'Completed' && this.action_id == actionId) {

                        logsSection.innerHTML = actionStatusObject.jobLog;
                    }
                    this.task_log = this.pipeline_action['logs'];
                }
                else {
                    const logsSection = document.getElementById('logs-modal');

                    //  it has <br/> user is not stdout anything 
                    let index = this.actionStatus.findIndex((item) => {
                        return item.actionId == actionId;
                    });

                    const actionStatusobject = this.actionStatus[index];
                    if (this.pipeline_action['logs'] == '<br/>') {
                        logsSection.innerHTML = '';
                        logsSection.innerHTML = 'You have not printed any statement';
                        actionStatusObject.isPodCompleted = true;
                        actionStatusObject.isJobLogContainSomeText = true;
                        actionStatusobject.jobLog = 'You have not printed any statement';
                        this.actionStatus.splice(index, 1, actionStatusobject);

                    }
                    else {
                        if (this.action_id == actionId) {
                            logsSection.innerHTML = 'No logs to display'; // wait for some time
                            actionStatusobject.jobLog = 'No logs to display';
                            this.actionStatus.splice(index, 1, actionStatusobject);
                        }
                    }
                    this.task_log = "No logs to display";

                }
            }

            // this.task_log != "No logs to display"  && clearInterval(interval)
            if (this.task_log != 'No logs to display') {
                let index = this.actionStatus.findIndex((item) => {
                    return item.actionId == actionId;
                })
                if (index != -1) {
                    const actionStatusObject = this.actionStatus[index]
                    actionStatusObject.isJobLogContainSomeText = true;
                    actionStatusObject.isPodCompleted = true;
                    actionStatusObject.jobLog = this.pipeline_action['logs'];
                    this.actionStatus.splice(index, 1, actionStatusObject);
                    this.showIcons(actionId)
                    clearInterval(interval);
                }
            }
            //    } 
            //    else {
            //     this.ts.pop('error', 'No logs to display')
            //    }
        }, error => {
            this.isShowLogIcon = true;
            this.isInprogress = true;
            // this.toggleInProgress(actionId, false);
            this.ts.pop('error', 'No logs to display')
        });
    }

    // getCurrentTaskLog (actionId:number, isPropertiesClicked: boolean = false) {        
    //     const index = this.actionStatus.findIndex( (item)=> item.actionId == actionId )
    //     const logsSection = document.getElementById('logs-modal');
    //     const propertiesSection =  document.getElementById('properties-modal');

    //     if (index !=-1 && !isPropertiesClicked) {
    //         logsSection.innerHTML = this.actionStatus[index].jobLog;
    //         // return this.actionStatus[index].jobLog;
    //     }
    //     else if(index !=-1 && isPropertiesClicked) {
    //         propertiesSection.innerHTML = this.actionStatus[index].jobProperties;
    //         // return this.actionStatus[index].jobProperties;
    //     }
    // }

    isLogContainsSomeText(rowId: number) {
        // console.log('row', rowId)
        // console.log('selectedActionId', this.selectedTestActionId)
        // console.log('log', this.task_log)
        // console.log('bool',this.selectedTestActionId == rowId && this.task_log == 'No logs to display')
        // return this.selectedTestActionId == rowId && this.task_log == 'No logs to display'
        return this.actionStatus.findIndex((item) => item.actionId == rowId && item.isJobLogContainSomeText == false) >= 0
    }

    isPodCompleted(rowId: number) {
        return this.actionStatus.findIndex((item) => item.actionId == rowId && item.isPodCompleted == false) >= 0
    }

    isEnabledLiveLog(rowId: number) {
        return this.actionStatus.findIndex((item) => item.actionId == rowId && item.isEnableLiveLog == true) >= 0
    }

    webSocketLogs(url: string) {
        try {
            const xterm = new Terminal();
            const container = document.getElementById('terminal')
            container.innerHTML = '';
            const socketUrl = url
            this.socket = new WebSocket(url);
            attach(xterm, this.socket, false, true);
            // attach(xterm,sock,true,true);
            this.socket.onmessage = event => {
                // this.podLog += event.data + "<br/>"
                container.innerHTML += event.data + "<br/>"
                // this.scrollTerminal(container);
            };
            this.scrollTerminal(container);
            // xterm.open(container);
            // container.innerHTML +=  output;

        } catch (error) {
            console.warn('error', error)
        }

    }

    private scrollTerminal(container: HTMLElement) {
        this.scrollInterval = setInterval(() => {
            container.scrollTop = container.scrollHeight;
        }, 2000)
        // window.setInterval(function () {
        //     container.scrollTop = container.scrollHeight;
        // }, 2000);
    }

    onTypeSelected(event) {
        // this.actionsForm.controls['type'].setValue(event.text);
        this.actionsForm.controls['type'].setValue(event.id);
    }

    onImageSelected(event) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if(file.name.length > 100) {
                this.imageNameError = true;
            } else {
                this.imageNameError = false;
            }
            const type = file['type'];
            const supportedTypes = ['image/jpeg', 'image/png', 'image/jpg']
            if (supportedTypes.includes(type)) {
                this.uploaded_file = file;
                this.filetype = file.type;
                const reader = new FileReader();
                reader.onload = e => this.imageSrc = reader.result;
                reader.readAsDataURL(file);

                /* Shown image view after getting image src.
                To avoid alt image preview before loading image */
                setTimeout(() => {
                    this.imageUploaded = true;
                }, 100)
                this.isDisableUpdateBtn = false;
                this.isErrorImageType = false;
            }
            else {
                this.isErrorImageType = true;
            }
        }
    }

    // save action
    createAction(param) {
        if (param == 'save and create input') {
            this.input_process = true;
        } else if (param == 'save and create action') {
            this.action_process = true;
        } else {
            this.loading = true
        }
        let value = this.actionsForm.value;
        value['application_id'] = this.application_id;
        if (this.mode == 'Add') {
            let image_data: any;
            if (this.uploaded_file) {
                image_data = this.uploaded_file;
            } else {
                image_data = '';
            }
            this.actionService.addAction(value, image_data, 'add').then((data: any) => {
                if (data) {
                    this.imageUploaded = true;
                    if (param == 'save and create input') {
                        this.input_process = false;
                        if (this.application_id) {
                            this.router.navigate([`/applications/${this.application_id}/actions/${data.id}/input-form-editor`]);
                        } else {
                            this.router.navigate([`/admin/actions/${data.id}/input-form-editor`])
                        }
                    } else if (param == 'save and create action') {
                        this.action_process = false;
                        if (this.application_id) {
                            this.router.navigate([`/applications/${this.application_id}/actions/${data.id}/action-editor`]);
                        } else {
                            this.router.navigate([`/admin/actions/${data.id}/action-editor`]);
                        }
                    } else {
                        this.loading = false;
                        this.ts.pop('success', 'Action Created')
                        // this.isShowLogIcon = false;
                        this.appDataTable.refreshData();
                    }
                }
            }, error => {
                this.loading = false;
                $('#actionModal').modal('hide');
                this.cancel()
                this.ts.pop('error', error.detail)
            })
            $('#actionModal').modal('hide');
            this.cancel()

            // $('#.modal-backdrop').remove();

        } else {
            value['id'] = this.action_id;
            let image: any;
            if (this.uploaded_file) {
                image = this.uploaded_file;
            } else {
                image = this.imageName;
            }
            this.actionService.addAction(value, image, 'edit').then((data: any) => {
                if (data) {
                    this.imageUploaded = true;
                    if (param == 'save and create input') {
                        this.input_process = false;
                        if (this.application_id) {
                            this.router.navigate([`/applications/${this.application_id}/actions/${data.id}/input-form-editor`]);
                        } else {
                            this.router.navigate([`/admin/actions/${data.id}/input-form-editor`])
                        }
                    } else if (param == 'save and create action') {
                        this.action_process = false;
                        if (this.application_id) {
                            this.router.navigate([`/applications/${this.application_id}/actions/${data.id}/action-editor`]);
                        } else {
                            this.router.navigate([`/admin/actions/${data.id}/action-editor`]);
                        }
                    } else {
                        this.loading = false;
                        this.ts.pop('success', 'Action Updated')
                        this.isShowLogIcon = false;
                        this.appDataTable.refreshData();
                    }
                    $('#actionModal').modal('hide');
                    this.cancel()
                }
            }, error => {
                this.loading = false;
                $('#actionModal').modal('hide');
                this.cancel()
                this.ts.pop('error', error.detail)
            })
        }
        //this.imageSrc = '';
        //this.uploaded_file = undefined;
        $('body').removeClass('modal-open');
    }

    // Action delete and Test
    deleteAction(data, action) {
        this.show_test_action = false;
        if (action == 'Delete') {
            this.loading = true;
            this.actionService.deleteAction(data.id).subscribe(data => {
                this.loading = false;
                this.ts.pop('success', data['msg']);
                $('#actionsModal').modal('hide');
                this.appDataTable.refreshData();
            }, error => {
                this.loading = false;
                $('#actionsModal').modal('hide');
                console.log(error)
                this.ts.pop('error', error.detail);
            })
        } else if (action == 'test') {
            this.modal_msg = "Uploading JSON configuration to server."
            this.actions_load = true;
            this.actionService.testAction(this.action_id).subscribe(data => {
                this.json_obj = data['data'];
                this.actionInfo = {
                    "name": this.json_obj['name'],
                    "path": "",
                    "message": this.json_obj['description'],
                    "index": 0,
                    "fileName": this.json_obj['name'],
                    "internalName": this.json_obj['internalName'],
                    "categories": this.json_obj['categories'],
                    "infrastructure": this.json_obj['infrastructure'],
                    "projects": this.json_obj['projects'],
                    "logo": this.json_obj['logo']
                }
                this.modal_msg = "Uploading Action data to server."
                this.actionService.testActionConfig(this.action_id).subscribe(response => {
                    this.actions_load = false;
                    this.show_test_action = true;
                    // $('#actionsModal').modal('hide');
                    // $('#actionsTestModal').modal('show');
                }, error => {
                    this.actions_load = false;
                    this.ts.pop('error', error.detail);
                    $('#actionsModal').modal('hide');
                })
            }, error => {
                this.actions_load = false;
                this.ts.pop('error', error.detail);
                $('#actionsModal').modal('hide');
            })
        }
        else if (action == 'Deploy') {
            this.modal_msg = "Uploading JSON configuration to server."
            this.actions_load = true;
            this.actionService.deployAction(this.action_id).subscribe(data => {
                this.modal_msg = "Uploading Action data to server."
                this.actionService.deployActionConfig(this.action_id).subscribe(response => {
                    this.actions_load = false;
                    this.appDataTable.refreshData();
                    this.ts.pop('success', 'Action Deployed')
                    $('#actionsModal').modal('hide');
                }, error => {
                    this.actions_load = false;
                    this.ts.pop('error', error.detail);
                    $('#actionsModal').modal('hide');
                })
            }, error => {
                this.actions_load = false;
                this.ts.pop('error', error.detail);
                $('#actionsModal').modal('hide');
            })
        }
    }
    cancelPipeline(event) {
        this.show_test_action = false;
        $('#actionsModal').modal('hide');
        // $('#actionsTestModal').modal('hide');
    }

    //close action modal
    back() {
        $('#actionsModal').modal('hide');
    }

    actionDetailsSave($event) {
        var data = {
            'environment': $event.activeEnvironment,
            'target': $event.value,
            'data': $event.res,
            'action': this.actionInfo
        };
        console.log(data);
        this.actionService.testActionPipeline(this.action_id, data).subscribe(response => {
            this.ts.pop('success', 'Pipeline Created');
            this.actions_load = false;
            this.show_test_action = false;
            this.handleSomeFunctions.setSubmitStatus(false);
            $('#actionsModal').modal('hide');
            this.isShowLogIcon = true;
            // this.isInprogress = true;
            this.getPodLogParameters(true)
            // this.toggleLogElement()
            const currentLogELement = document.getElementById(`log-${this.currentTestActionId}`);
            const currentDeployELement = document.getElementById(`deploy-${this.currentTestActionId}`);
            if (!isNullOrUndefined(currentLogELement)) {
                currentLogELement.style.display = 'none'
            }
            // currentLogELement.style.display = 'none'
            currentDeployELement.style.display = 'none'
            this.selectedTestActionId = this.currentTestActionId;
            this.testPipelineStatus = '';
            // this.callActionPipelineStatusEveryFiveSeconds();

            //  when Test button clicks gather actionStatus info
            //  before pushing check if already exist if exist just update not to push
            const actionStatusObject = {
                id: this.action_id,
                actionId: this.action_id,
                isDisabledLog: true,
                podStatus: '',
                isPodCompleted: false,
                isJobLogContainSomeText: false,
                isEnableLiveLog: false,
                jobLog: 'No logs to display',
                jobProperties: 'No properties to display'
            }

            let index = this.actionStatus.findIndex((item) => {
                return item.id == actionStatusObject.id &&
                    item.actionId == actionStatusObject.actionId
                // item.isDisabledLog == actionStatusObject.isDisabledLog && 
                // item.isJobLogContainSomeText == actionStatusObject.isJobLogContainSomeText && 
                // item.isPodCompleted == actionStatusObject.isPodCompleted && 
                // item.podStatus == actionStatusObject.podStatus
            });

            if (index != -1) {
                this.actionStatus.splice(index, 1, actionStatusObject)
            } else {
                this.actionStatus.push(actionStatusObject);
            }

            this.callActionPipelineStatusEveryFiveSeconds();

        }, error => {
            this.ts.pop('error', error.detail);
            this.show_test_action = false;
            this.actions_load = false;
            $('#actionsModal').modal('hide');
            this.handleSomeFunctions.setSubmitStatus(false);
        })
    }

    ChangingPaginationValue($event) {
        this.config.pageSize = Number($event.target.value);
        this.appDataTable.reConfigAndRefresh(this.config);

    }

    onSelect(value, actionId) {
        // this.action = value;
        if (value == 1) {
            if (this.pipeline_action.hasOwnProperty('logs') && this.pipeline_action['logs'] != "<br/>") {
                // this.getCurrentTaskLog(actionId)
                if (this.action_id == actionId) {
                    const logsSection = document.getElementById('logs-modal');
                    logsSection.innerHTML = '';
                    logsSection.innerHTML = this.pipeline_action['logs']
                }
                this.task_log = this.pipeline_action['logs'];
            }
            else {
                const logsSection = document.getElementById('logs-modal');
                logsSection.innerHTML = '';
                if (this.pipeline_action['logs'] == '<br/>') {

                    logsSection.innerHTML = 'You have not printed any statement';
                } else {
                    if (this.action_id == actionId) {
                        logsSection.innerHTML = 'No logs to display'; // wait for some time
                    }
                }
                this.task_log = "No logs to display";
            }
        }
        else {
            if (this.pipeline_action.hasOwnProperty('parameters') && this.pipeline_action['parameters'] != "") {
                let index = this.actionStatus.findIndex((item) => {
                    return item.actionId == actionId;
                });

                const actionStatusobject = this.actionStatus[index];
                actionStatusobject.jobProperties = this.pipeline_action['parameters'];
                this.actionStatus.splice(index, 1, actionStatusobject);
                if (this.action_id == actionId) {
                    const logsSection = document.getElementById('logs-modal');
                    logsSection.innerHTML = '';
                    logsSection.innerHTML = this.pipeline_action['parameters'];
                }
                // this.getCurrentTaskLog(actionId, true)
                this.task_log = this.pipeline_action['parameters'];
            }
            else {
                this.task_log = "No parameters to display";
                if (this.action_id == actionId) {
                    const logsSection = document.getElementById('logs-modal');
                    logsSection.innerHTML = '';
                    logsSection.innerHTML = 'No parameters to display';
                }
            }
        }
    }
    cancelConfirmation() {
        this.enableActionEditor = false;
        this.enableInputFormEditor = false;
        // this.isDisableUpdateBtn = true;
        this.isDisableUpdateBtn = false;
        this.isErrorImageType = false;
        this.imageNameError = false;
        if (this.actionsForm.dirty || this.uploaded_file) {
            $('#cancel-confirmation').appendTo('body').modal('show');
        } else {
            $('#actionModal').modal('hide');
        }

    }

    cancel() {
        $('#cancel-confirmation').modal('hide');
        $('#actionModal').modal('hide');
        $('.modal-backdrop').hide();
        this.imageSrc = '';
        this.uploaded_file = undefined;
        this.imageUploaded = false;
    }
    declineCancel() {
        $('#cancel-confirmation').modal('hide');
        // $('.modal-backdrop').remove();
        // $('.modal-backdrop').hide();
    }

    goBack() {
        this.location.back();
    }

    ngOnDestroy() {
        // this.cancel();
        $('#actionModal').remove();
        $('#cancel-confirmation').remove();

        // $('#.modal-backdrop').remove();
        this.pageState.currentPageState = {
            pageNo: document.querySelector(".pagination li.active a")['innerText'],
            show: this.active_pagination,
        };
        this.searchString$.next(false);
        this.searchString$.complete();
    }
    recoverLastPage() {
        const lastPage = this.pageState.currentPageState;

        if (lastPage) {
            this.active_pagination = lastPage.show;
            this.config.pageSize = Number(lastPage.show);
            this.currentShow = lastPage.pageNo;
            setTimeout(() => {
                let pageSelect: HTMLElement = document.querySelectorAll<HTMLElement>(".pagination li.pagination-page a")[this.currentShow - 1];
                pageSelect.click();
            }, 100);
        }
    }
}
