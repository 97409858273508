import { AfterViewInit, OnDestroy, Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[app-autocomplete]'
})
export class AppAutocomplete implements AfterViewInit {
    private _el: HTMLElement;
    @Input('app-autocomplete') autocomplete: string;

    constructor(elementRef: ElementRef) {
        this._el = elementRef.nativeElement;
    }

    ngAfterViewInit() {
        let inputs = this._el.getElementsByTagName('input');
        if (inputs.length > 0) {
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].setAttribute('autocomplete', this.autocomplete);
            }
        }
    }

}
