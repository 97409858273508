import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AuthService} from '@app/auth';
import {ConfigDetails} from '@app/shared/directives/custom-breadcrumb/breadcrumbs-interface';


@Component({
    selector: 'app-custom-breadcrumb',
    templateUrl: './custom-breadcrumb.component.html',
    styleUrls: ['./custom-breadcrumb.component.scss']
})
export class CustomBreadcrumbComponent implements OnInit {
    crumbs: Array<{ text: string; path: string, next?: any }> = [];
    hideFirst = false;
    remove_class = false;
    config_details: ConfigDetails = {
        remove_hr: false,
        remove_ol_mb: false,
        remove_li_mb: false
    };

    @Output() outputData: EventEmitter<any> = new EventEmitter();

    constructor(
        private authService: AuthService
    ) {

    }

    @Input()
    set crumbsData(data) {
        if (data) {
            this.crumbs = data;
        }
    }

    @Input()
    set HideFirst(data) {
        this.hideFirst = data;
    }

    @Input()
    set removeClass(data) {
        this.remove_class = !!data;
    }

    @Input()
    set config(data) {
        if (data instanceof Object) {
            Object.keys(data).forEach(key => {
                this.config_details.hasOwnProperty(key) ? this.config_details[key] = data[key] : console.warn('Key not match');
            });
        }
    }

    ngOnInit() {
    }

    navigateToHome() {
        this.authService.redirectToHome();
    }

    isLastCrumb(index: number) {
        return this.crumbs.length > 0 ? index + 1 === this.crumbs.length : false;
    }

    emitData(data) {
        if (data !== undefined) {
            this.outputData.emit(true);
        }
    }

}
