import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InterfaceService} from '@app/interfaces';
import {map, catchError, tap} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ProjectBranchService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    // get list of branch
    getBranches(project_id: number) {
        return this.http.get(this.getApiUrl(`branch/${project_id}/get_branches/`), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    // Get  branch
    getBranch(branch_id: number, projects: number = 0) {
        return this.http.get(this.getApiUrl(`branch/${branch_id}/get_branch/?projects=${projects}`), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getBranchHeader(branch_id: number) { // get sibling branches with branch id(for header)
        return this.http.get(this.getApiUrl(`branch/${branch_id}/branch_header/`), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }


    createBranch(project_id: number, branch_list: any) {
        return this.http.post(this.getApiUrl(`branch/${project_id}/create_branch/`), JSON.stringify(branch_list), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    createBranchEnv(branch_list: any) {
        return this.http.post(this.getApiUrl(`branch/create_branch/`), JSON.stringify(branch_list), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteBranch(branch_id: number) {
        return this.http.get(this.getApiUrl(`branch/${branch_id}/delete/`), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    reConfiguration(branch_id: number, is_remove: boolean = false) {
        let url = this.getApiUrl(`branch/${branch_id}/re_configure/?remove_reConfig=${is_remove}`)
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getAllBranch(params) {
        let url = this.getApiUrl(`branch`, params);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    saveProjectBranches(project_id, branches) {
        return this.http.post(this.getApiUrl(`branch/${project_id}/create-multiple-branch/`), JSON.stringify({'branch_list': branches}), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    getBranchesBasedOnApp(App_id) {
        return this.http.get(this.getApiUrl(`branch/${App_id}/get-branches-use-app/`), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    updateBranch(data, project_id) {
        return this.http.post(this.getApiUrl(`branch/${project_id}/update-branch/`), JSON.stringify(data), this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }

    get_branch_list_with_config_status(params) {
        const url = this.getApiUrl(`branch/get-branch-list-with-status/`, params);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
}
