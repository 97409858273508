const settings = {
    text: 'Settings',
    link: '/branch/0/settings',
    icon: 'fa fa-gear',
}
const overview = {
    text: 'Overview',
    link: '/branch/0/overview',
    icon: 'fa fa-dashboard',
};
const application = {
    text: 'Home',
    link: '',
    icon: 'fa fa-dashboard',
    submenu: [
        {
            text: 'Dashboard',
            link: '/branch/0/repository/subversion'
        },
        {
            text: 'Monitoring',
            link: '/branch/0/repository/subversion'
        },
        {
            text: 'Projects',
            link: '',
            icon: 'fa fa-dashboard',
            submenu: [
                {
                    text: 'Monitoringsss',
                    link: '/branch/0/repository/subversion'
                }, 
            ]
        },
        {
            text: 'Configuration',
            link: '/branch/0/repository/gitlab'
        },
        {
            text: 'Image Creation',
            link: '/branch/0/repository/gitlab'
        },
        {
            text: 'Deploy',
            link: '/branch/0/repository/gitlab'
        }
    ]
};

const repository = {
    text: 'Repository',
    link: '',
    icon: 'fa fa-dashboard',
    submenu: [
        {
            text: 'Subversion',
            link: '/branch/0/repository/subversion'
        },
        {
            text: 'GitLab',
            link: '/branch/0/repository/gitlab'
        }
    ]
};

const project = {
    text: 'Projects',
    link: '/project/list',
    icon: 'fa fa-folder'
}

const reviewboard = {
    text: 'Reviewboard',
    link: '/branch/0/reviewboard',
    icon: 'fa fa-square'
}

const jenkins = {
    text: 'Jenkins',
    link: '/branch/0/jenkins/list',
    icon: 'fa fa-openid'
};

const headingMain = {
    text: 'Main menu',
    heading: true,
};

const jira = {
    text: 'jira',
    link: '/branch/0/jira/list',
    icon: 'fa fa-line-chart'
};

const freshDesk = {
    text: 'fresh-desk',
    link: '/branch/0/fresh-desk',
    icon: 'fa fa-bar-chart-o',
};

export const menu = [
    headingMain,
    application,
    overview,
    reviewboard,
    jenkins,
    jira,
    freshDesk,
];

export const application_menu = [
    application,
];

export const project_menu = [
    application,
];
