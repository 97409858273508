import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toast, ToasterService } from 'angular2-toaster';
import { FinalFormat, FormFieldsFormat, FetchDetails, Inputs } from './dynamic-action-interface';
import { ApplicationService, PipelineService, ProjectBranchService } from '@app/services';
import { authTokenId, GetApiurl } from '@app/core';
import { ActivatedRoute } from '@angular/router';
import { HandleSomeFunctions } from '@app/features/home/pipeline/pipeline/workflow-setup/handle-some-functions';
import {
    ActionList,
    OutputsFormat,
    UseOutput
} from '@app/features/home/pipeline/pipeline/workflow-setup/workflow-interface';

@Component({
    selector: 'app-dynamic-action-json',
    templateUrl: './dynamic-action-json.component.html',
    styleUrls: ['./dynamic-action-json.component.scss']
})
export class DynamicActionJsonComponent implements OnInit {

    @Output('finalData') finalData: EventEmitter<any> = new EventEmitter<any>();
    @Output('removeData') removeData: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('cancelEverything') cancelEverything: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('valueChangeDetect') valueChangeDetect: EventEmitter<any> = new EventEmitter<any>();
    @Output('nameCheck') nameCheck: EventEmitter<any> = new EventEmitter<any>();

    // common
    loading = true;
    dynamicActionForm: FormGroup;
    formFields: FormFieldsFormat = null;
    loadingHandle = {};
    dynamicValue = {};
    selectedValues = {};
    oldData: FinalFormat = null;
    app_id = 0;
    Environment: any = null;
    environmentId = 0;
    pipelineId = 0;
    disableDelete = true;
    stage_name = '';
    targetId = 0;
    active_action: ActionList = null;
    task_count = 0;
    test_action: boolean = false;

    // File handling
    files = {};
    fileNames = {};


    // Action
    actionName = '';

    // Process handling
    submitProcess = false;

    // Outputs handling
    useOutputs = {};
    outputsData = {};
    parentSelectHandle = {};

    // Parent and child details
    childData: object = {};
    defaultFetchCancel: string[] = [];
    dynamicUrlIndex: number[] = [];

    // Notification handle
    notificationCheckbox = false;

    // Environment variables
    environmentList: Array<any> = [];
    activeEnvironment: any = null;
    deploymentTargetList: Array<any> = [];
    activeDeployment: any = null;
    showDeploymentTarget = false;

    // Project and branch variable;
    branch_details: any = null;
    project_details: any = null;
    branch_id: number = null;
    disableEnvironment = false;
    obj = Object;
    useValues = {};
    // Old data total
    oldDataAll = null;

    // name check variable
    enableLoader = false;
    alreadyExist = false;
    showMessage = false;

    defaultCheckedRadios: Array<any> = [];
    defaultSelectedNgSelect: Array<any> = [];

    constructor(
        private fb: FormBuilder,
        private toasterService: ToasterService,
        private pipelineService: PipelineService,
        private route: ActivatedRoute,
        private handleSomeFunctions: HandleSomeFunctions,
        private applicationService: ApplicationService,
        private projectBranchService: ProjectBranchService,
    ) {
        this.route.params.subscribe(res => {
            this.app_id = res.id;
            this.pipelineId = res.pipeline_id;
        });
        this.handleSomeFunctions.getSubmitStatus.subscribe(res => this.submitProcess = res);
        this.handleSomeFunctions.getNameAvailability.subscribe(res => {
            if (res) {
                res === 'not exists' ? this.alreadyExist = false : this.alreadyExist = true;
                this.enableLoader = false;
                this.showMessage = true;
            }
        });
    }

    ngOnInit() {
    }


    @Input('action_name')
    set action_name(data) {
        if (data) {
            this.actionName = data;
        }
    }

    @Input('appId')
    set appId(data) {
        if (data) {
            this.app_id = data;
        }
    }

    @Input('default_data')
    set default_data(data) {
        this.disableDelete = !data;
        if (data) {
            this.oldDataAll = data.data.environment;
            this.oldData = data.data.environment.data;
        }
    }

    @Input('ids')
    set ids(data) {
        if (data) {
            this.stage_name = data.stage_id;
            this.active_action = data.action;
            this.task_count = data.count;
            var form_data = null;
            // Test action logic
            if ('form_details' in data) {
                form_data = data['form_details'];
                this.test_action = true;
            }
            if (this.active_action.infrastructure) {
                this.getEnvironmentList(true, form_data);
            } else {
                this.getFormsDetails(form_data);
            }
        }
    }

    /**
     * This method used to get form's details.
     * Will get data based on branch_id, action_name, environment_id, application_id.
     * Need to format data to required format based on data. If it's required need to set required else set optional to description.
     * If it's dynamic means need to call backend api and incoming data assign to dynamicValue based on formName.
     * For edit need to set data. Based on old data will set form value and need to format.
     * */
    getFormsDetails(form_data = null) {
        // for test action
        if (form_data) {
            this.formCreation(form_data);
        }
        else {
            const postData = {
                'action_name': this.actionName,
                'application_id': this.app_id
            };
            this.pipelineService.getFormFields(null, postData).subscribe(
                (res: any) => {
                    this.formCreation(res);
                },
                error => {
                    this.dynamicActionForm = this.fb.group({});
                    // this.loading = false;
                    this.toasterHandle('error', error.detail);
                }
            );
        }
    }

    formCreation(res) {
        this.formFields = res;
        const objectValue = {};
        objectValue['action_name'] = ['', [Validators.required,
        Validators.maxLength(35), Validators.pattern(/^([a-zA-Z][a-zA-Z0-9-]*)$/)]];
        objectValue['infrastructure'] = this.active_action.infrastructure ? ['', Validators.required] : [''];
        objectValue['projects'] = this.active_action.projects ? ['', Validators.required] : [''];
        this.formFields.inputs.forEach((ele, index_val) => {
            if (ele.type == 'radio' && ele.defaultValue && ele.defaultValue.length > 0) {
                const { formName, defaultValue } = ele;
                // this.defaultCheckedRadios.push({formName, checkedId:defaultValue[0].id})
                this.defaultCheckedRadios.push({ formName, text: defaultValue[0].text })
            }
            else if (ele.type == 'ng-select' && ele.defaultValue && ele.defaultValue.length > 0) {
                const { formName, defaultValue } = ele;
                this.defaultSelectedNgSelect.push({ formName, text: defaultValue[0].text })
            }
            // else {
            //     Control(this.dynamicActionForm.controls[ele.formName])
            // }
            objectValue[ele.formName] = [{
                value: ele.defaultValue ? ele.defaultValue : '',
                disabled: !!ele.disabled
            }];
            if (ele.required) {
                // objectValue[ele.formName].push(Validators.required);
                // objectValue[ele.formName] = ['', [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)]];
                if(ele.type == 'radio'){
                    objectValue[ele.formName].push(Validators.required);
                }
                // else{
                //     objectValue[ele.formName] = ['', [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)]];  
                // }
            }   
          else {
                ele.description += ' (optional)';
            }
            if ('fetchDetails' in ele) {
                for (let childFormName of ele.fetchDetails.childFormName) {
                    ele.hasOwnProperty('fetchDetails') ? this.defaultFetchCancel.push(childFormName) : console.log();
                }
            }
            this.defaultFetchCancel.includes(ele.formName) ? ele.defaultFetch = false : console.log();
            this.loadingHandle[ele.formName] = false;
            this.dynamicValue[ele.formName] = ele.value;
            if ('isDynamic' in ele && ele.isDynamic.dynamic) {
                ele.isDynamic.url = ele.isDynamic.url;
                ele.isDynamic.url = ele.isDynamic.url.replace('<application_id>', this.app_id.toString());
                if (ele.isDynamic.dynamic && (ele.hasOwnProperty('defaultFetch') ? ele.defaultFetch : true)) {
                    this.getDynamicValues(ele.isDynamic.url, ele.isDynamic.method, ele.formName);
                }
                ele.isDynamic.url ? this.dynamicUrlIndex.push(index_val) : console.log();
            }
            if ('useOutput' in ele && ele.useOutput) {
                const use_output_event = { id: 1, text: ele.valueType };
                this.useOutputSelect(use_output_event, ele.formName);
            }
        });
        this.dynamicActionForm = this.fb.group(objectValue);
        if (this.oldData) {
            let currentOldData = this.oldData.inputs;
            
            // currentOldData.splice(0, 3);
            currentOldData = currentOldData.slice(3);
            this.formFields.inputs.forEach((ele, index_val)=>{
                if (ele.type == 'radio' ){
                  let radioFieldFormName  = ele.formName;
                  if (ele.formName == currentOldData[index_val].name) {
                    let oldDataRadioFieldValue = currentOldData[index_val].value;
                    this.defaultCheckedRadios.push({ formName: radioFieldFormName, text: oldDataRadioFieldValue })
                  } 
                }
            } )
            const temp = {};
            this.oldData.inputs.forEach(ele => {
                temp[ele.name] = ele.value;
            });
            this.dynamicActionForm.patchValue(temp);
            this.dynamicActionForm.updateValueAndValidity();
            this.outputsData = this.oldData.usedOutput;
            this.selectedValues = this.oldData.ngSelect;
            this.useOutputs = this.oldData.outputBasedOnName;
            this.parentSelectHandle = this.oldData.parentSelect;
            this.task_count = this.oldData.task_index;
            this.notificationCheckbox = this.oldData.notifications;
            this.disableEnvironment = true;
            const OD = this.oldDataAll;
            OD.environment ? this.environmentSelect(OD.environment, OD.target) : this.disableLoader();
        } else {
            this.disableLoader();
        }
    }

    isNullOrUndefined = (value: any) => {
        return value == null; // if null or undefined returns true
    }

    onRadioFieldChanged(formName, value) {
        let hiddenFields= []
        const temp = {}
        if (this.oldData) {
            this.formFields.inputs.forEach((ele)=>{
                if (ele['hiddenCondition']) 
                    hiddenFields.push({'formName': ele.formName, value: ele['hiddenCondition']['value']}) 
                
            })
    
            hiddenFields.forEach((element)=>{
                if (element.value == value){
                    this.oldData.inputs.forEach((oldValues)=>{
                        if (element.formName==oldValues.name) {
                            oldValues.value = '';
                            temp[oldValues.name] = oldValues.value;    
                        }
                    })
                }
            })
            this.dynamicActionForm.patchValue(temp);
            this.dynamicActionForm.updateValueAndValidity();
        } 
    }

    canCheckRadioButtonIfDefaultValue(radioformName: string, displayText: string) {
        const matchedObject = this.defaultCheckedRadios.find(({ formName, text }) => formName == radioformName && text == displayText)
        return !this.isNullOrUndefined(matchedObject) // if not undefined we have default checked radio items
    }

    canSelectNgSelectIfDefaultValue = (ngSelectFormName: string, displayText: any) => {
        if (this.defaultSelectedNgSelect.length > 0) {
            const matchedNgSelectObject = displayText.find(({ text }) => text = this.defaultSelectedNgSelect[0].text)
            if (!this.isNullOrUndefined(matchedNgSelectObject)) {
                const matchedDefaultNgSelectObject = this.defaultSelectedNgSelect
                    .find(({ formName }) => formName == ngSelectFormName)
                return !this.isNullOrUndefined(matchedDefaultNgSelectObject) && [matchedDefaultNgSelectObject.text]
            }
        }
    }

    disableLoader(valueChangeDetect = false) {
        if (valueChangeDetect) {
            this.dynamicActionForm.valueChanges.subscribe(emitData => {
                this.valueChangeDetect.emit(emitData);
            });
        }
        this.handleSomeFunctions.setLoaderIconStatus(false);
        this.loading = false;
    }

    /**
     * This method use to handle toaster message. This is common for all.
     * @type: This variable contain type of toaster e.g info.
     * @message: This is string variable.
     * */
    toasterHandle(type: 'info' | 'warning' | 'success' | 'error', message: string) {
        this.toasterService.pop(<Toast>{
            type: type,
            title: message
        });
    }

    /**
     * This method use to get data dynamically from api.
     * @url: String variable. It's contain api details.
     * @method: GET or POST method.
     * @formName: This is used to store data to dynamicValue based on formName;
     *
     * */
    getDynamicValues(url, method, formName, postData = null) {
        try {
            // if url contains any brackets it will be like useValues[REGION], here regions is formName
            if (url.match(/\[\w*/g)) {
                // extracting formName inside square bracket of useValues[FORMNAME]
                const formNamesInURL = this.extractFormNamesFromUrl(url);
                url = this.replaceFormNamesFromUrlWithCurrentText(formNamesInURL, url);
            }
            this.loadingHandle[formName] = true;
            this.dynamicValue[formName] = [];
            postData = postData ? postData : {};
            this.pipelineService.getDynamicValues(url, method, postData).subscribe(
                (res: any) => {
                    this.dynamicValue[formName] = res.list;
                    this.loadingHandle[formName] = false;
                },
                error => {
                    this.loadingHandle[formName] = false;
                    this.toasterHandle('error', error.detail);
                }
            );
        } catch (e) {
            console.log(e);
        }
    }

    /**
  * This method use to get  direct value (user should input) from text input field or number input field.
  * this function will trigger when field losses focus
  * this method doesnt autopopulate values from backend user must feed values.
  * example:core/ssh/<useValues[username].rawText>/<useValues[password].rawText>/<useValues[jira_url].rawText>/get-jira-projects/
  * 
  *
  * */
    getTextFieldValue() {
        this.formFields.inputs.forEach((ele, index_val) => {
            if ('isDynamic' in ele && ele.isDynamic.dynamic) {
                try {
                    let url = ele.isDynamic.url
                    // if url contains any brackets it will be like useValues[REGION], here regions is formName
                    if (url.match(/\[\w*/g)) {
                        // extracting formName inside square bracket of useValues[FORMNAME]
                        const formNamesInURL = this.extractFormNamesFromUrl(url);
                        url = this.replaceFormNamesFromUrlWithCurrentText(formNamesInURL, url);
                    }
                    // old code
                    // ele.isDynamic.url = ele.isDynamic.url;
                    // ele.isDynamic.url = ele.isDynamic.url.replace('<application_id>', this.app_id.toString());
                    if (ele.isDynamic.dynamic && (ele.hasOwnProperty('defaultFetch') ? ele.defaultFetch : true)) {
                        this.getDynamicValues(url, ele.isDynamic.method, ele.formName);
                    }
                    ele.isDynamic.url ? this.dynamicUrlIndex.push(index_val) : console.log();
                }
                catch (error) {
                    console.log('extracting Raw text from text field', error)
                }

            }

        });
    }

    /**
     * This method is used to extract formName inside 
     * square bracket of useValues[FORMNAME]
     * */
    private extractFormNamesFromUrl(url: string) {
        const extractFormNamesFromUrl = url.match(/\[\w*/g);
        const formNamesInURL = [];
        extractFormNamesFromUrl.map((extractedFormName, index) => {
            formNamesInURL.push(extractedFormName.replace("[", ''));
        });
        return formNamesInURL;
    }

    private replaceFormNamesFromUrlWithCurrentText(formNamesInURL: any[], url: string) {
        formNamesInURL.map((formNameInURL, index) => {
            let currentfield = ''
            let currentInputField = document.getElementsByName(formNameInURL)[0] as HTMLFormElement;
            if (currentInputField) {
                if (currentInputField['innerText'] != "") {
                    currentfield = currentInputField['innerText'];
                }
                if (this.isNullOrUndefined(currentInputField)) {
                    url = url.replace(`<useValues[${formNameInURL}].rawText>`, null);
                    url = url.replace(`<useValues[${formNameInURL}].text>`, null);
                }
                else {
                    let currentFieldValue: string = currentInputField.value;
                    if (currentFieldValue === '') {
                        url = url.replace(`<useValues[${formNameInURL}].rawText>`, null);
                    }
                    else {
                        if (currentfield == '') {
                            let url_var = currentFieldValue.split('/')[3]
                            if (url_var == 'jira') {
                                currentFieldValue = currentFieldValue.replace("/jira", '')
                            }
                            currentFieldValue = currentFieldValue.replace("https://", '')
                            currentFieldValue = currentFieldValue.replace("/", '')
                            url = url.replace(`<useValues[${formNameInURL}].rawText>`, currentFieldValue);
                        }
                        else {
                            url = url.replace(`<useValues[${formNameInURL}].rawText>`, currentfield);
                        }
                    }
                }
            }
        });
        return url;
    }

    /**
     * Ng select selected event handle method.
     * */
    selected($event: any, formName: string, returnId: 'id' | 'text' | 'value', fetchDetails, multiple) {
        returnId = returnId ? returnId : 'id';
        if (!multiple) {
            this.dynamicUrlIndex && this.dynamicUrlIndex.forEach(index_val => {
                const childFormName = this.formFields.inputs[index_val]['childFormName']
                const frontEndFormName = this.formFields.inputs[index_val]['formName']
                const ele = this.formFields.inputs[index_val];
                if ((ele.isDynamic.url.indexOf(`<${frontEndFormName}>`)) && this.dynamicActionForm.value[frontEndFormName]!.length > 0) {
                    const childFormIndex = this.findChildFormNameIndex(childFormName)// for now this will never return negative value
                    const ele = this.formFields.inputs[childFormIndex];//dependentformNameIndex
                    const frontEndFormNameValue = this.dynamicActionForm.value[frontEndFormName]
                    if (ele && ele.isDynamic.url != undefined) {
                        ele.isDynamic.url = ele.isDynamic.url.replace(`<${frontEndFormName}>`, frontEndFormNameValue);
                    }
                }
            })
            if (returnId !== 'value') {
                this.dynamicActionForm.controls[formName].setValue(returnId === 'id' ? $event.id : $event.text);
            } else {
                const value = this.dynamicValue[formName].filter(x => x.id === $event.id);
                this.dynamicActionForm.controls[formName].setValue(value[0].value);
            }
            this.selectedValues[formName] = $event;
        }
        else {
            let selected_value = [];
            if (returnId !== 'value') {
                selected_value = this.dynamicActionForm.controls[formName].value.length > 0 ? this.dynamicActionForm.controls[formName].value : [];
                selected_value.push(returnId === 'id' ? $event.id : $event.text);
            } else {
                const value = this.dynamicValue[formName].filter(x => x.id === $event.id);
                selected_value = this.dynamicActionForm.controls[formName].value.lenght > 0 ? this.dynamicActionForm.controls[formName].value : []
                selected_value.push(value[0].value);
            }
            this.dynamicActionForm.controls[formName].setValue(selected_value);
            if (this.selectedValues[formName] != undefined) this.selectedValues[formName].push($event);
            else this.selectedValues[formName] = [$event];
        }
        if (fetchDetails) {
            this.childDetailsHandle(fetchDetails, $event, formName);
        }
    }

    private findChildFormNameIndex(childFormName: string) {
        return this.formFields.inputs.findIndex((fields, index) => fields.formName && fields.formName == childFormName);
    }

    /**
     * Child details handle
     * */
    childDetailsHandle(fetchDetails: FetchDetails, value, formName) {
        const $event = this.dynamicValue[formName].filter(x => x.id === value.id)[0];

        /**
         *  storing Response of selected field in its formName
         *  here REGION is FormName (i.e) {REGION:{'id':1,'text':'someValue'},..}
         */
        this.useValues[formName] = $event;

        const postData = { 'selected': $event };
        for (let childFormName of fetchDetails.childFormName) {
            this.childData[childFormName] = true;
            this.selectedValues[childFormName] = null;
            const index = this.formFields.inputs.findIndex(x => x.formName === childFormName);
            const type = this.formFields.inputs[index].type;
            const returnKey = this.formFields.inputs[index].returnKey;
            const name = '$$control_' + childFormName + '$$';
            if (this.formFields.inputs[index].isDynamic) {
                let rep = null
                let url = this.formFields.inputs[index].isDynamic.url;
                if (url.match(/\[\w*/g)) {
                    // extracting formName inside square bracket of useValues[FORMNAME]
                    const extractFormNamesFromUrl = url.match(/\[\w*/g);
                    const formNamesInURL = [];
                    extractFormNamesFromUrl.map((extractedFormName, index) => {
                        formNamesInURL.push(extractedFormName.replace("[", ''))
                    });
                    formNamesInURL.map((formNameInURL, index) => {
                        // url = url.replace('<credential_id>',this.credentialId);
                        try {
                            if (this.useValues[formNameInURL].id || this.useValues[formNameInURL].text) {
                                url = url.replace(`<useValues[${formNameInURL}].id>`, this.useValues[formNameInURL].id);
                                url = url.replace(`<useValues[${formNameInURL}].text>`, this.useValues[formNameInURL].text);
                                url = url.replace(`<useValues[${formNameInURL}].value>`, this.useValues[formNameInURL].value);
                                url = url.replace(name, $event[fetchDetails.value]);
                                rep = url;
                            }

                        } catch (error) {
                            url = url.replace(`<useValues[${formNameInURL}].id>`, null);
                            url = url.replace(`<useValues[${formNameInURL}].text>`, null);
                            url = url.replace(`<useValues[${formNameInURL}].value>`, null);
                            url = url.replace(name, $event[fetchDetails.value]);
                            rep = url;
                        }
                    });
                }
                else {
                    url = url.replace(name, $event[fetchDetails.value]);
                    rep = url;
                }
                if (this.formFields.inputs[index].isDynamic.method === 'POST') {
                    const postParams = this.formFields.inputs[index].isDynamic.params;
                    Object.keys(postParams).forEach(k => {
                        const val = postParams[k];
                        if (val === '$$control_' + formName + '$$') {
                            postParams[k] = val.replace(val, $event[fetchDetails.value]);
                            postData[k] = postParams[k];        // For posting to server
                        } else {
                            postData[k] = postParams[k];
                        }
                    });
                }
                this.childDataSet(rep, this.formFields.inputs[index].isDynamic.method, childFormName, postData, type, returnKey);
            } else {
                const rep = fetchDetails.url.replace(name, $event[fetchDetails.value]);
                this.childDataSet(rep, fetchDetails.method, childFormName, postData, type, returnKey);
            }
        }
    }

    /**
     * Child data set handel.
     * If field type not ng-select directly set form-value.
     * Otherwise they need to select data.
     * */
    childDataSet(url, method, formName, postData, type, returnKey) {
        this.loadingHandle[formName] = true;
        this.dynamicValue[formName] = [];
        postData = postData ? postData : {};
        this.pipelineService.getDynamicValues(url, method, postData).subscribe(
            (res: any) => {
                if (type === 'ng-select') {
                    // TODO UseParams {'id':'','credential'}
                    if (typeof(res) == "string") {
                        let obj={id: 1, text: res}
                        // this.dynamicActionForm[formName] = {id:1, text: res};
                        // this.dynamicActionForm.controls[formName].setValue(returnKey ? res.list[0][returnKey] : res.list[0]['id']);
                        this.selectedValues[formName] = obj;
                        this.dynamicActionForm.controls[formName].setValue(obj['text'])
                    }
                    else {
                        this.dynamicValue[formName] = res.list;
                    // if (res.list.length === 1) {
                    this.selectedValues[formName] = res.list[0];
                    this.dynamicActionForm.controls[formName].setValue(returnKey ? res.list[0][returnKey] : res.list[0]['id']);
                    // }
                    } 
                } else {
                    this.dynamicActionForm.controls[formName].setValue(res);
                }
                this.loadingHandle[formName] = false;
            },
            error => {
                this.loadingHandle[formName] = false;
                this.toasterHandle('error', error.detail);
            }
        );
    }

    /**
     * File select event handling.
     * */
    fileChangeListener($event: any, formName: string) {
        const files = $event.target.files;
        if (files.length > 0) {
            const temp_file = files[0];
            this.files[formName] = temp_file;
            this.fileNames[formName] = temp_file.name;
            this.dynamicActionForm.controls[formName].setValue(temp_file.name);
        } else {
            this.toasterHandle('info', 'File not selected');
        }
    }

    /**
     * This method used when use output valueType select time.
     * */
    useOutputSelect(event, formName) {
        this.handleSomeFunctions.getOutputList(event, this.environmentId).then(
            (res: OutputsFormat[]) => {
                const name = formName;
                this.parentSelectHandle[name] = event;
                this.useOutputs[name] = [];
                let globalPropertiesCount = 0;
                if (this.handleSomeFunctions.global_properties !== null) {
                    globalPropertiesCount = this.handleSomeFunctions.global_properties.length;
                    Object.keys(this.handleSomeFunctions.global_properties).forEach((x, idx) => {
                        this.useOutputs[name].push(
                            <UseOutput>{
                                id: idx.toString(),
                                text: 'Global - ' + x,
                                value: `$$GLOBAL$$GLOBAL$$${x}`
                            }
                        );
                    });
                }

                let counter = globalPropertiesCount;
                res.forEach((ele, index) => {
                    ele.outputs.forEach((value, idx) => {
                        counter = counter + 1;
                        const temp: UseOutput = {
                            id: counter.toString(),
                            text: `${ele.action_name} - ${value.name}`,
                            // tslint:disable-next-line:max-line-length
                            value: `$$${ele.action_name}$$${ele.internal_name}$$${value.name}`
                        };
                        this.useOutputs[name].push(temp);
                    });
                });
            }
        ).catch(Error => Error);
    }

    /**
     * Action output data storing.
     * */
    outputStoreHandle(): Promise<any> {
        // debugger
        return new Promise<any>((resolve, reject) => {
            try {
                if (this.formFields.hasOwnProperty('outputs')) {
                    const PD = this.project_details;
                    const data: OutputsFormat = {
                        'stage_name': this.stage_name,
                        'action_name': this.dynamicActionForm.controls['action_name'].value,
                        'environment_id': this.environmentId,
                        'environment_name': this.Environment && 'text' in this.Environment ? this.Environment.text : null,
                        'outputs': this.formFields.outputs,
                        'project_id': this.project_details && 'id' in this.project_details ? this.project_details.id : null,
                        'project_name': PD && 'project_name' in this.project_details ? this.project_details.project_name : null,
                        'index': this.task_count,
                        'internal_name': this.active_action.internalName
                    };
                    this.handleSomeFunctions.setOutputs(data);
                }
            } catch (e) {
                console.error(e);
                reject(false);
            }
        });
    }

    /**
     * Set value based on form name. This is only for use output (ng-select) method.
     * */
    selectOutputBasedOnAction(event, formName) {
        const data = this.useOutputs[formName].filter(x => x.id === event.id);
        this.outputsData[formName] = data[0];
        this.dynamicActionForm.controls[formName].setValue(data[0].value);
    }

    // project and branch section

    /**
     * Get environment list based on current application
     * */
    getEnvironmentList(formLoad = false, form_data = null) {
        if (form_data)
            this.app_id = this.route.snapshot.parent.params.id
        this.applicationService.getEnvironmentList(this.app_id).subscribe(
            (res: object[]) => {
                res.forEach(ele => {
                    ele['text'] = ele['steps'];
                });
                this.environmentList = res;
                if (formLoad) {
                    if (form_data)
                        this.getFormsDetails(form_data);
                    else
                        this.getFormsDetails();
                }
            },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error.detail
                });
            }
        );
    }

    /**
     * Select environment
     * After selection based on environment get deployment target list.
     * If deployment target length greater then zero means default first one select
     * */
    environmentSelect($event: any, key: any = null) {
        this.activeEnvironment = $event;
        this.Environment = $event;
        this.environmentId = $event.id;
        this.deploymentTargetList = [];
        this.activeDeployment = null;
        this.project_details = null;
        this.branch_details = null;
        this.showDeploymentTarget = false;
        this.dynamicActionForm.controls['projects'].setValue(null);
        this.applicationService.getDeploymentTargets($event.id).subscribe(
            (res: any[]) => {
                res.forEach(ele => {
                    ele['text'] = ele.project_name + ' - ' + ele.branch_name;
                });
                this.deploymentTargetList = res;
                if (key && this.deploymentTargetList.length > 0) {
                    const filter_value = this.deploymentTargetList.filter(x => x.id === key[0].id)[0];
                    this.dynamicActionForm.controls['projects'].setValue([filter_value]);
                    this.deploymentTargetSelect(filter_value, true);
                }
                setTimeout(() => {
                    this.showDeploymentTarget = true;
                }, 500);
            },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error.detail
                });
            }
        );
    }

    /**
     * Deployment target select
     * Based on this, project detail and branch detail wil get
     * */
    deploymentTargetSelect(data, disableLoader = false) {
        this.activeDeployment = data;
        this.project_details = null;
        this.branch_details = null;
        this.targetId = data.id;
        const active = this.deploymentTargetList.filter(x => x.id === data.id);
        this.branch_id = active[0].branch_id;
        this.projectBranchService.getBranch(active[0].branch_id).subscribe(
            (res: any) => {
                this.project_details = res.project;
                this.branch_details = res;
                this.branch_id = this.branch_details.id;
                this.dynamicUrlIndex.forEach(index_val => {
                    const ele = this.formFields.inputs[index_val];
                    ele.isDynamic.url = ele.isDynamic.url.replace('<branch_id>', this.branch_id.toString());
                    ele.isDynamic.url = ele.isDynamic.url.replace('<application_id>', this.app_id.toString());
                    ele.isDynamic.url = ele.isDynamic.url.replace('<project_id>', this.project_details['id'].toString());
                    if (ele.isDynamic.dynamic && (ele.hasOwnProperty('defaultFetch') ? ele.defaultFetch : true)) {
                        this.getDynamicValues(ele.isDynamic.url, ele.isDynamic.method, ele.formName);
                    }
                });
                if (disableLoader) {
                    this.disableLoader(true);
                }
            },
            error => {
                this.toasterService.pop(<Toast>{
                    type: 'error',
                    title: error.detail
                });
                if (disableLoader) {
                    this.disableLoader(true);
                }
            }
        );
    }

    /**
     * Checking name available
     * */
    checkingNameAvailable($event) {
        if ($event.target.value) {
            this.showMessage = false;
            this.enableLoader = true;
            this.nameCheck.emit($event.target.value);
        } else {
            this.showMessage = false;
            this.enableLoader = false;
        }
    }

    /**
     * Final method. Need to call formatData method to get formatted data.
     * */
    submit() {
        this.submitProcess = true;
        this.formatData().then(
            res => {
                const final = {
                    res: res,
                    value: this.deploymentTargetList.filter(x => x.id === this.activeDeployment.id),
                    activeEnvironment: this.activeEnvironment
                };
                this.finalData.emit(final);
                this.outputStoreHandle().then(outputs => outputs).catch(error => error);
            }
        ).catch(
            error => {
                this.submitProcess = false;
                this.toasterHandle('error', error);
            }
        );
    }

    /**
     * Need to format form data into required format.
     * First need to check any file are selected. If file's available need to store first all file's.
     * UploadFile method using for file upload and that will return location's. Need to update form data with response.
     * */
    formatData(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                const lengthNum = Object.keys(this.fileNames).length;
                const values = this.dynamicActionForm.getRawValue();
                const result: FinalFormat = {
                    inputs: [],
                    outputs: this.formFields.outputs,
                    usedOutput: this.outputsData,
                    ngSelect: this.selectedValues,
                    outputBasedOnName: this.useOutputs,
                    parentSelect: this.parentSelectHandle,
                    internalName: this.active_action.internalName,
                    task_index: this.task_count,
                    notifications: this.notificationCheckbox
                };
                if (lengthNum > 0) {
                    this.UploadFile().then(
                        res => {
                            Object.keys(res).forEach(ele => {
                                values[ele] = res[ele];
                            });
                            Object.keys(values).forEach(ele => {
                                const valueType = this.formFields.inputs.filter(x => x.formName === ele)[0];
                                /*if (valueType && valueType.hasOwnProperty('hidden')) {
                                    if (valueType.hidden === true) {
                                        return;
                                    }
                                }*/
                                const check = valueType ? valueType.hasOwnProperty('useOutput') : null;
                                const useOutput = check && valueType.useOutput ? valueType.useOutput : false;
                                const check_config = valueType ? valueType.hasOwnProperty('createConfigMap') : null;
                                const createConfigMap = check_config && valueType.createConfigMap ? valueType.createConfigMap : false;
                                result.inputs.push({
                                    name: ele,
                                    value: values[ele],
                                    valueType: valueType ? valueType.valueType : null,
                                    createConfigMap: createConfigMap,
                                    useOutput: useOutput
                                });
                            });
                            resolve(result);
                        }
                    ).catch(
                        error => {
                            this.toasterHandle('error', error);
                        }
                    );
                } else {
                    Object.keys(values).forEach(ele => {
                        const valueType = this.formFields.inputs.filter(x => x.formName === ele)[0];
                        /*if (valueType && valueType.hasOwnProperty('hidden')) {
                            if (valueType.hidden === true) {
                                return;
                            }
                        }*/
                        const check = valueType ? valueType.hasOwnProperty('useOutput') : null;
                        const check_config = valueType ? valueType.hasOwnProperty('createConfigMap') : null;
                        result.inputs.push({
                            name: ele,
                            value: values[ele],
                            valueType: valueType ? valueType.valueType : null,
                            createConfigMap: check && valueType.createConfigMap ? valueType.createConfigMap : false,
                            useOutput: check && valueType.useOutput ? valueType.useOutput : false
                        });
                    });
                    resolve(result);
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    /**
     * This method used to store file's. Post method.
     * This will send files, environment_id, stage_name, target_id.
     * */
    UploadFile(): Promise<any> {
        const url: any = GetApiurl(`pipeline/${this.pipelineId}/store-pipeline-file/`);
        const formData: FormData = new FormData();
        return new Promise((resolve, reject) => {
            Object.keys(this.files).forEach(ele => {
                formData.append(ele, this.files[ele], this.fileNames[ele]);
            });
            formData.append('environmentId', this.environmentId.toString());
            formData.append('stage_name', this.stage_name);
            formData.append('target_id', this.targetId.toString());
            const xhr = new XMLHttpRequest();
            xhr.open('post', url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem(authTokenId));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        resolve(JSON.parse(xhr.response));
                    } else {
                        reject(xhr.response);
                    }
                }
            };
        });
    }

    /**
     * Remove action from action details.
     * */
    removeDataAction() {
        this.removeData.emit(true);
    }

    /**
     * Cancel
     * */
    cancel() {
        this.cancelEverything.emit(true);
    }

    /**
     * Ng class handle for hidden fields.
     * */
    hiddenOrNot(index, field: Inputs) {
        if ('hiddenCondition' in field) {
            const formControl = this.dynamicActionForm.controls[field['formName']];
            const parenFormControl = this.dynamicActionForm.controls[field['hiddenCondition']['parentFormName']];
            let split_value = [];
            if (parenFormControl) {
                if (parenFormControl['status'] === 'VALID') {
                    if (field['hiddenCondition']['value']) {
                        const value = field['hiddenCondition']['value'] as string;
                        split_value = value.split('||');
                    }
                    if (field['hiddenCondition']['allowAny']) {
                        return true;
                    } else if (split_value.indexOf(parenFormControl['value']) !== -1) {
                        field['hidden'] = false;
                        formControl.setValidators([Validators.required]);
                        formControl.updateValueAndValidity();
                        return true;
                    } else {
                        field['hidden'] = true;
                        formControl.clearValidators();
                        formControl.updateValueAndValidity();
                        return false;
                    }
                } else {
                    return false;
                }
            }
        } else if (field.hasOwnProperty('hidden')) {
            return !field.hidden;
        } else {
            return true;
        }
    }
}