import { Injectable } from '@angular/core';
import { InterfaceService } from '@app/interfaces';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { Roles } from '@app/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RolesService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }
    getRolesList() {
        return this.http.get(this.getApiUrl(`roles/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    // Api call to create new role
    createRole(role: Roles) {
        let url = this.getApiUrl(`roles/`);
        return this.http.post(url, JSON.stringify(role), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    // Api call to update role
    updateRole(role: Roles) {
        let url = this.getApiUrl(`roles/${role.id}/`);

        return this.http.patch(url, JSON.stringify(role), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    roleNameCheck(data) {
        return this.http.post(this.getApiUrl(`roles/check-rolename/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Res => Res),
                catchError(this.handleError)
            );
    }

    user_details(id: number) {
        let url =this.getApiUrl(`roles/${id}/user_details/`);
        return this.http.get(url, this.getHttpOptions('json'))
        .pipe(
            tap(Response => Response),
            catchError(this.handleError)
        );
    }

    get_roles() {
        let url =this.getApiUrl(`roles/get-roles/`);
        return this.http.get(url, this.getHttpOptions('json'))
        .pipe(
            tap(Response => Response),
            catchError(this.handleError)
        );
    }

    // Get role detail
    getRole(id: number) {
        let url = this.getApiUrl(`roles/${id}`);

        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    // checkRoleNameExists (roleName: string) {
    //     console.log('service called')
    // }
}
