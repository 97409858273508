import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;
const dynamicActionBaseUrl = environment.dynamicActionBaseUrl
export const podLoggerBaseUrl = environment.podLoggerBaseUrl;
// const baseUrl = "http://devops.starsystems.in/backend/"
// const baseUrl = "https://redington.devops5d.com/backend/"

export const appDetails = {
    name: 'DEV-OPS',
    description: '',
    year: ((new Date()).getFullYear())
}

// get ApiURL
export function GetApiurl(path: string, params?: Object) {

    
    let url = baseUrl + path;

    if (!url.endsWith('/')){
        url += '/'
    }

    if (params instanceof Object) {
        url += '?';
        
        Object.entries(params).forEach(([param, value]) => {
            url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
        })
    }
    

    if(url.includes('?') && url.endsWith('/') ){
       url = url.slice(0, -1);
    }
    return url;
}

export function getDynamicActionCompleteApiUrl (path: string, params?: Object) {
    let url = dynamicActionBaseUrl + path;
    
    if (!url.endsWith('/')){
        url += '/'
    }

    if (params instanceof Object) {
        url += '?';

        Object.entries(params).forEach(([param, value]) => {
            url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
        })
    }
    

    if(url.includes('?') && url.endsWith('/') ){
        url = url.slice(0, -1);
    }
    return url;
}
export const authTokenId = 'id_token';

export const defaultAppPreferences = {
    applicationTheme: 'A',

    isFixed: true,
    isCollapsed: true,
    isBoxed: false,
    isRTL: false,
    horizontal: false,
    isFloat: false,
    asideHover: false,
    theme: null,
    asideScrollbar: false,
    isCollapsedText: false,
    useFullLayout: false,
    hiddenFooter: false,
    offsidebarOpen: false,
    asideToggled: false,
    viewAnimation: 'ng-fadeInUp',
};

// Image specifications
const validImageTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'];
const validImageSize = 1048576;

export function GetValidimageTypes() {
    return { 'validType': validImageTypes, 'validSize': validImageSize }
}
