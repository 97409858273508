import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { catchError, tap, map } from 'rxjs/operators';
// import { Subject } from 'rxjs';



interface PagenationState {
    pageNo: number;
    show: number;
}
@Injectable({
    providedIn: 'root'
})


export class PipelinePagenationService extends InterfaceService {
    private pageState: PagenationState;

    get currentPageState(): PagenationState {
        // console.log("getpageeeeeeeeeeee",this.pageState)
        return this.pageState;
    }

    set currentPageState(pageState: PagenationState) {
        // console.log("setpawwwwwwwwwwwww",this.pageState)
        this.pageState = pageState;
    }

}

@Injectable({
    providedIn: 'root'
})
export class PipelineService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();

    }
    // private _refreshNeeded$ =  new Subject<void>()

    // get refreshNeeded$() {
    //     return this._refreshNeeded$;
    // }

    createPipeline(data) {
        return this.http.post(this.getApiUrl('pipeline/'), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    editPipeline(data, id) {
        return this.http.post(this.getApiUrl(`pipeline/${id}/pipeline-edit/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }



    listPipeline(app_id, status, date) {
        return this.http.get(this.getApiUrl(`pipeline/`, { 'application_id': app_id, 'status': status, 'date': date }), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getPipeline(pipeline_id) {
        return this.http.get(this.getApiUrl(`pipeline/${pipeline_id}/get-pipeline/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getPipelineCount(domain_name) {
        return this.http.get(this.getApiUrl(`pipeline/get-pipeline-count/?domain_name=${domain_name}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }

    getStaticData(app_id) {
        return this.http.get(this.getApiUrl(`pipeline/${app_id}/get-static-data/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deletePipeline(pipeline_id) {

        const url = this.getApiUrl(`pipeline/${pipeline_id}/delete-pipeline/`);
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }
    deleteProperties(pipeline_id, name) {

        const url = this.getApiUrl(`pipeline/${pipeline_id}/delete-properties/`, { 'properties_name': name });
        return this.http.get(url, this.getHttpOptions())
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }

    pipelineclone(app_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${app_id}/pipeline-clone/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    createPriority(app_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${app_id}/create-pipeline-priority/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getPriority(app_id) {
        return this.http.get(this.getApiUrl(`pipeline/${app_id}/get-priority/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }
    deleteJob(job_array) {
        // const url = this.getApiUrl(`pipeline/${job_id}/delete-job`);
        const url = this.getApiUrl(`pipeline/delete-jobs/`);
        return this.http.post(url, job_array, this.getHttpOptions())
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }

    createProperties(pipeline_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${pipeline_id}/create-properties/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => {
                    Response;
                    // this._refreshNeeded$.next()
                }),
                catchError(this.handleError)
            );
    }
    getProperties(pipeline_id) {
        return this.http.get(this.getApiUrl(`pipeline/${pipeline_id}/get-properties/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }

    createWorkflow(pipeline_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${pipeline_id}/create-workflow/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    StartWorkflow(pipeline_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${pipeline_id}/start-workflows/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    // ****************get pipeline list**********************
    listPipelineruns(app_id, status, date, offset, limit, textToSearch) {
        return this.http.get(this.getApiUrl(`pipeline/${app_id}/pipeline-runs/?status=${status}&date=${date}&offset=${offset}&limit=${limit}&searchText=${textToSearch}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }


    hasPiplineNameExist(app_id: number, piplineName: string) {
        return this.http.get(this.getApiUrl(`pipeline/check-name/?application_id=${app_id}&name=${piplineName}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    createJobsPriority(app_id, data) {
        return this.http.post(this.getApiUrl(`pipeline/${app_id}/create-jobs-priority/`), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
    getJobsPriority(app_id) {
        return this.http.get(this.getApiUrl(`pipeline/${app_id}/get-jobs-priority/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    createWorkflowAction(pipeline_id, data) {
        const url = this.getApiUrl(`pipeline/${pipeline_id}/create-workflow-action/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getProjectImageList(project_id, branch_id) {
        return this.http.get(this.getApiUrl(`pipeline/${project_id}/get-image-list/${branch_id}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getDynamicValues(url, method, postData = null) {
        if (method === 'POST') {
            return this.http.post(this.getApiUrl(`dynamic-action/?url=${url}`), JSON.stringify(postData), this.getHttpOptions('json'))
                .pipe(
                    tap(Response => Response),
                    catchError(this.handleError)
                );
        } else {
            return this.http.get(this.getApiUrl(`dynamic-action/?url=${url}`), this.getHttpOptions('json'))
                .pipe(
                    tap(Response => Response),
                    catchError(this.handleError)
                );
        }
    }

    getPodLogParams(pipelineId: number) {
        let url = this.getApiUrl(`pipeline/${pipelineId}/get-pod-log-params/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }

    getPodStatus(pipelineId: number) {
        let url = this.getApiUrl(`pipeline/${pipelineId}/get-pod-status/`);
        return this.http.get(url, this.getHttpOptions('json')).pipe(
            map(Response => Response),
            catchError(this.handleError)
        )
    }


    getFormFields(project_id, postData) {
        const url = this.getApiUrl(`pipeline/${project_id}/get-form-values/`);
        return this.http.post(url, JSON.stringify(postData), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    storeTempWorkflow(pipeline_id, data) {
        const url = this.getApiUrl(`pipeline/${pipeline_id}/store-temp-workflow/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_pipeline_runs_details(pipeline_run_id) {
        const url = `pipeline/${pipeline_run_id}/get-pipeline-runs-details/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_pipeline_run_status(id, data) {
        const url = this.getApiUrl(`pipeline-runs/${id}/get-pipeline-status/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_action_details() {
        const url = `pipeline/get-actions-list/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_marketplace_action_details() {
        const url = `pipeline/get-marketplace-actions-list/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    get_approval_users_and_group(app_id) {
        const url = `pipeline/${app_id}/user-and-group-for-approval/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    createPipelineTrigger(data) {
        const url = this.getApiUrl(`pipeline-runs/create-pipeline-trigger/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getJobsLog(app_id) {
        return this.http.get(this.getApiUrl(`pipeline-job-output/${app_id}/get-job-logs/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getTaskLog(id, name) {
        return this.http.get(this.getApiUrl(`pipeline-job-output/${id}/get-task-log/${name}/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    generateSecret(pipeline_id: any, id) {
        const url = `pipeline/${pipeline_id}/webhook/`;
        return this.http.get(this.getApiUrl(url, { 'type': id }), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getWebhooks(pipeline_id) {
        return this.http.get(this.getApiUrl(`pipeline/${pipeline_id}/get-webhooks/`), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );

    }
    deleteWebhook(pipeline_id: any, id) {
        const url = `pipeline/${id}/delete-webhook/?pipeline_id=${pipeline_id}/`;
        return this.http.get(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    toggleActionToDashboard(pipelineId:any, payload:any) {
        const url = this.getApiUrl(`pipeline/${pipelineId}/dashboard-actions/`);
        return this.http.post(url, JSON.stringify(payload), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    verifySlackApi(pipelineId:any, payload:any) {
        const url = this.getApiUrl(`pipeline/${pipelineId}/verify-slack-api`);
        return this.http.post(url, JSON.stringify(payload), this.getHttpOptions('json'))
        .pipe(
            tap(Response => Response),
            catchError(this.handleError)
        )
    }

    stateMaintainPipeline(data) {
        const url = `pipeline-state-maintain/`;
        return this.http.post(this.getApiUrl(url), JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    deleteStateMaintainPipeline(pipeline_id) {
        const url = `pipeline-state-maintain/${pipeline_id}`;
        return this.http.delete(this.getApiUrl(url), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }
}
