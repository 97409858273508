import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigationControlService {

    private navigate = new BehaviorSubject<any>(null);
    defaultNavigate: Observable<any> = this.navigate.asObservable();

    private newApplicationAdded = new BehaviorSubject<boolean>(false);
    getApplicationList: Observable<boolean> = this.newApplicationAdded.asObservable();

    private activeApplicationId = new BehaviorSubject<any>(null);
    getApplicationId: Observable<any> = this.activeApplicationId.asObservable();

    private BranchIdStore = new BehaviorSubject<any>(null);
    getBranchId: Observable<any> = this.BranchIdStore.asObservable();

    private toggleToolTip = new BehaviorSubject<boolean>(false);
    getToolTip: Observable<boolean> = this.toggleToolTip.asObservable();

    constructor() {
    }

    navigateSet(data) {
        this.navigate.next(data);
    }

    applicationAdded(data) {
        this.newApplicationAdded.next(data);
    }

    setApplicationId(data) {
        this.activeApplicationId.next(data);
    }

    setBranchId(data) {
        this.BranchIdStore.next(data);
    }

    showToolTip(data: boolean) {
        this.toggleToolTip.next(data);
    }
}
