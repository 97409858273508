import { Component, HostBinding, OnInit, Inject, LOCALE_ID } from '@angular/core';
declare var $: any;

//--
import { InterfaceComponent } from '@app/interfaces';
import { SettingsService, GetApiurl } from '@app/core';
import { AppPreferences } from '@app/models';
import { Router, NavigationEnd, NavigationStart, RouterEvent } from '@angular/router';
// import { chatBot } from './shared/directives/chatBot/chat'


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends InterfaceComponent implements OnInit {
    appPreferences: AppPreferences = new AppPreferences();
    user;

    @HostBinding('class.layout-fixed') get isFixed() { return this.appPreferences.isFixed; };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.appPreferences.isCollapsed; };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.appPreferences.isBoxed; };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.appPreferences.useFullLayout; };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.appPreferences.hiddenFooter; };
    @HostBinding('class.layout-h') get horizontal() { return this.appPreferences.horizontal; };
    @HostBinding('class.aside-float') get isFloat() { return this.appPreferences.isFloat; };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.appPreferences.offsidebarOpen; };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.appPreferences.asideToggled; };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.appPreferences.isCollapsedText; };

    constructor(
        public settingsService: SettingsService,
        @Inject(LOCALE_ID) locale: string,
        private router: Router,
        // private chatBot: chatBot
    ) {
        super();
        console.log(locale);
    }

    ngOnInit() {
        $(document).on('click', '[href="#"]', e => e.preventDefault());

        this.subscriptions['appPreferences'] = this.settingsService.appPreferences$.subscribe(settings => {
            this.appPreferences = settings || new AppPreferences();
        });

        // this.router.events.subscribe(value => this.initializeRouting(value))
    }


    // initializeRouting(route: any): void {
    //     if (route instanceof NavigationEnd) {
    //         //getting username
    //         this.settingsService.user$.subscribe(settings => this.chatBot.updateUserName(settings.username))
    //         let url = GetApiurl("disable");
    //         if (url == "https://redington.devops5d.com/backend/disable") {
    //             document.getElementById("chatbot-btn").style.display = "none";
    //         } else if (url != "https://redington.devops5d.com/backend/disable") {
    //             //update url to chatbot
    //             this.chatBot.updateUrl(route.urlAfterRedirects);

    //             //remove chat history
    //             var chatNodes = document.getElementById("chat");
    //             $("#application-img-container").remove();
    //             while (chatNodes.hasChildNodes()) {
    //                 chatNodes.removeChild(chatNodes.lastChild);
    //             }

    //             //close chat
    //             this.chatBot.closeChatBot();

    //             //hiding chat for specific pages
    //             if (route.url == "/auth/login" || route.url == "/auth/password/reset" || route.url == "/auth/signup" || route.url == '/' || route.urlAfterRedirects == '/error/not-found') {
    //                 document.getElementById("chatbot-btn").style.display = "none";
    //             }
    //             else {
    //                 document.getElementById("chatbot-btn").style.display = "block";
    //             }
    //         }

    //     }
    // }

}
