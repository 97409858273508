import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterfaceService } from '@app/interfaces';
import { map, catchError, tap } from 'rxjs/operators';
import { log } from 'util';


@Injectable({
    providedIn: 'root'
})
export class NotificationService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    get_notification_list() {
        let url = this.getApiUrl(`notification/get-notifications/`)
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    notification_list() {
        let url = this.getApiUrl(`notification/notifications-list/`)
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }


    update_notification(data) {
        let url = this.getApiUrl(`notification/${data.id}/`)
        return this.http.put(url, JSON.stringify(data), this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }
    read_all_notification() {
        let url = this.getApiUrl(`notification/read-all/`)
        return this.http.get(url, this.getHttpOptions('json'))
            .pipe(
                map(Response => Response),
                catchError(this.handleError)
            );
    }


}
