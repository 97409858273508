import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, tap } from 'rxjs/operators';
import { InterfaceService } from '@app/interfaces';

@Injectable()
export class SvnService extends InterfaceService {

  constructor(private http: HttpClient) {
    super();
  }

  getSvnProjects(id:number) {
    let url = this.getApiUrl(`projects/${id}`);
    return this.http.get(url, this.getHttpOptions('json'))
    .pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

  verifyCredentials(value) {
    return this.http.post(this.getApiUrl(`svn/verify_credentials/`), 
    JSON.stringify(value), this.getHttpOptions('json'))
    .pipe(
      tap(Response => Response),
      catchError(this.handleError)
    );
  }

  getReports(id) {
    let url = this.getApiUrl(`svn/${id}/get_report/`);
    return this.http.get(url,this.getHttpOptions('json'))
    .pipe(
      map(Response => Response),
      catchError(this.handleError)
    );
  }

}
