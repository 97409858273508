import { Pipe, PipeTransform } from '@angular/core';
//--

// Custom datatable normal column ......................................................................
@Pipe({ name: 'normalColumn' })

export class NormalColumn implements PipeTransform {
    transform(value, args: string): any {
        try {
            let prop = args.split('.');
            if (value) {
                let temp = value;
                prop.forEach(element => {
                    temp = temp[element]
                });
                return temp;
            }
            else {
                return '------';
            }
        } catch{
            return '------';
        }
    }
}
