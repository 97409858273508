import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from "@angular/router";
import { Observable } from 'rxjs/Observable';

//--
import { GetApiurl } from '@app/core';
import { InterfaceComponent } from '@app/interfaces';
import { DatePipe, LocationStrategy } from '@angular/common';

@Component({
  selector: 'ng2-table-extended',
  templateUrl: './ng2-table-extended.component.html',
  styleUrls: ['./ng2-table-extended.component.scss'],
  providers: [DatePipe]
})
export class Ng2TableExtendedComponent extends InterfaceComponent implements OnInit {
  process: 'DATA_FETCHING';
  ng2Config: Object = {

  };
  _remoteUrl: string;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  ngOnInit() {

  }

  // Sets remote API url
  @Input('remoteUrl')
  set remoteUrl([url, params]) {
    if (url) {
      this._remoteUrl = GetApiurl(url, params);
    }
    else this._remoteUrl = null;
  }
}