import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { GitLab } from '@app/models';
import { InterfaceService } from '@app/interfaces';

@Injectable()
export class GitHubService extends InterfaceService {

    constructor(private http: HttpClient) {
        super();
    }

    verifyCredentials(value) {
        return this.http.post(this.getApiUrl(`github/verify_credentials/`), JSON.stringify(value), this.getHttpOptions('json'))
            .pipe(
                tap(Response => Response),
                catchError(this.handleError)
            );
    }

    getReports(id, data= null) {
        const url = this.getApiUrl(`github/${id}/get_report/`);
        return this.http.post(url, JSON.stringify(data), this.getHttpOptions('json'))
        .pipe(
          map(Response => Response),
          catchError(this.handleError)
        );
      }

}
