import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatatableService {
    private tempApp = new BehaviorSubject<any>(null);
    defaultOpenRowApp: Observable<any> = this.tempApp.asObservable();
    private tempproject = new BehaviorSubject<any>(null);
    defaultOpenRowProject: Observable<any> = this.tempproject.asObservable();

    constructor() { }
    assignValueApp(data) {
        this.tempApp.next(data);
    }
    assignValueProject(data) {
        this.tempproject.next(data);
    }
}
