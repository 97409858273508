
// This file can be replaced during build by using the  array.
//  replaces  with .
// The list of file replacements can be found in .

export const environment = {
  production: false,
    baseUrl : "https://starops.sangli.io/backend/",
    dynamicActionBaseUrl:"https://prodaction.sangli.io",
    podLoggerBaseUrl:'ws://169.38.98.199:32526/',
    monitoringStatusRefreshInSeconds: 1000 * 15,
    monitoringNodeExportBinaryURL:
        "sudo curl https://developer.sangli.io/infra-monitor/monitor | bash -"

};
  
export const RazorpayId = {
  razorpay_id: 'rzp_test_dn7jVSKeY9fyPM'
}

/*
 * In development mode, to ignore zone related error stack frames such as
 * ,  for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

